import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector, useDispatch } from "react-redux";
import { getCategoryPageData } from "../redux/actions/categoriesActions";
import { modalContext } from "../context/modalContext";
import { serverContext } from "../context/serverContext";
import { decrypt, isDecryptedDataValid } from "./functions/cipherFunctions";

const CategoryItem = ({ categories }) => {
    const { serverImagePath } = useContext(serverContext);
    const imagePath = serverImagePath + "/category/";
    let categoryList = null;

    //get image path as css background image
    const cssImageUrlPath = (url) => {
        const path = {
            backgroundImage: "url(" + url + ")",
        };

        return path;
    };

    if (Array.isArray(categories) && categories?.length > 0) {
        categoryList = categories.map((val) => {
            return (
                <Link
                    to={`/products/${val.CategoryId}`}
                    key={`category-${val.CategoryId}`}
                >
                    <div
                        className="card card-bg-img bg-img bg-overlay mt-1"
                        style={cssImageUrlPath(`${imagePath}${val.CategoryImage}`)}
                    >
                        <div className="card-body p-5 direction-rtl">
                            <h2 className="text-white display-3 font-weight-bold mb-4">
                                {val.CategoryName}
                            </h2>
                            <button className="btn btn-warning">Explore</button>
                        </div>
                    </div>
                </Link>
            );
        });
    }

    return categoryList !== null ? (
        categoryList
    ) : (
        <>
            <div className="text-center" style={{ marginTop: "30%" }}>
                <img
                    src={process.env.PUBLIC_URL + "/bg-img/emptyCart.png"}
                    alt=""
                />
                <h6 className="text-danger">No categories found</h6>
            </div>
        </>
    );
};

function Categories() {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.categoriesReducer.loading);

    //get and decrypt categories from reducer
    const categoriesFromReducer = useSelector((state) => state.categoriesReducer.categoryData);
    const { Categories } = categoriesFromReducer && isDecryptedDataValid(decrypt(categoriesFromReducer, true));

    const exception = useSelector((state) => state.categoriesReducer.exception);

    const { showErrorDialog } = useContext(modalContext);
    const { serverPath } = useContext(serverContext);

    if (exception) {
        showErrorDialog(
            exception.message,
            exception.description
        );
        dispatch({
            type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
        });
    }

    useEffect(() => {
        //scroll to top when component loads 
        window.scrollTo(0, 0);

        if (!Categories) dispatch(getCategoryPageData(serverPath));
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="page-content-wrapper py-1">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-6">
                        {loading ? (
                            <SkeletonTheme highlightColor="#bababa">
                                <Skeleton
                                    className="mt-2"
                                    height={150}
                                    width={"100%"}
                                    count={8}
                                />
                            </SkeletonTheme>
                        ) : (
                            <CategoryItem categories={Categories} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Categories;
