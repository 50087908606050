import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";

const inline_block_child = {
    display: "inline-block"
}

const addressIconStyle = {
    display: "inline-block",
    position: "relative",
    top: "15px"
}

const NoAddressSelected = () => {
    return (
        <>
            <div className="text-center">
                <p className="text custom-text-style">It seems like you didn't have selected any aaddress yet</p>
            </div>
            <div className="row">
                <div className="col-6 d-flex justify-content-around col- col-sm col-md col-lg col-xl">
                    <Link to={"/address/list"} className="btn btn-warning mt-1">Select address</Link>

                </div>
                <div className="col-6 d-flex justify-content-around col- col-sm col-md col-lg col-xl">
                    <Link to={"/address/new"} className="btn btn-primary mt-1">Add address</Link>
                </div>
            </div>
        </>
    )
}

function SelectedAddress({ address, showEstimateTime, shippingType }) {

    /*function to convert time in hours and minutes
     * @params duration in seconds
     * @return string : time in hour/minute
    */
    const toMMSS = (duration) => {

        let minutes = Math.ceil((duration / 60));

        let hours = 0;

        if (minutes >= 60) {
            hours = Math.floor(minutes / 60);
            minutes = minutes % 60;

            return hours.toFixed(0) + ' h. ' + (minutes) + ' min.';
        }

        if ((minutes + 10) >= 60)
            return Math.floor((minutes + 10) / 60) + ' h. to ' + Math.floor((minutes + 10) / 60) + ' h. ' + (minutes - 60 + 20) + 'min. ';
        else {
            if (minutes + 20 >= 60)
                return (minutes + 10) + ' min. to ' + Math.floor((minutes + 20) / 60).toFixed(0) + ' h. ' + (minutes + 20) % 60 + 'min.';
            else
                return (minutes + 10) + ' - ' + (minutes + 20) + ' min.';
        }
    }

    //set text width as 55% of screen size in show more text component
    const [textWidthInShowMoreText, setTextWidthInShowMoreText] = useState((window.innerWidth / 100) * 55);

    //update text width in ShowMoreText component when clients window resize
    useLayoutEffect(() => {
        function updateSize() {
            setTextWidthInShowMoreText((window.innerWidth / 100) * 55);
        }
        //event listnet for screen resize
        window.addEventListener('resize', updateSize);
        updateSize();

        //clear window resize event listner
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <>
            <div className="card mt-1">
                <div className='card-header'>
                    Selected address
                </div>
                <div className="card-body mb-0 pb-2">
                    {
                        !address ? (
                            <NoAddressSelected />
                        ) : (

                            <>
                                <Link to={"/address/list"} style={{ float: "right", fontWeight: 700 }} className="custom-text-style">Change</Link>
                                <>
                                    <h6>
                                        <i className='bi bi-geo-alt'></i>
                                        {
                                            address?.Type !== 'Other' ? `Deliver to ${address?.Type}` : address?.Type
                                        }
                                    </h6>
                                    <div style={{ display: "flex", alignItems: "flexStart" }}>
                                        <div style={addressIconStyle}>
                                            {
                                                address?.Type === 'Home' ?
                                                    <i className='bi bi-house border border-secondary ps-3 px-3 p-2' style={{ fontSize: "1.3rem" }}></i> :
                                                    address?.Type === 'Office' ?
                                                        <i className='bi bi-building border border-secondary ps-3 px-3 p-2' style={{ fontSize: "1.3rem" }}></i> :
                                                        <i className='bi bi-geo border border-secondary ps-3 px-3 p-2' style={{ fontSize: "1.3rem" }}></i>
                                            }
                                        </div>
                                        <div style={inline_block_child} className="mt-2 ms-3">

                                            <ShowMoreText
                                                lines={2}
                                                more={""}
                                                less={""}
                                                className=""
                                                anchorClass=""
                                                expanded={false}
                                                truncatedEndingComponent={"... "}
                                                width={textWidthInShowMoreText}
                                            >
                                                {address.FullAddress}
                                            </ShowMoreText>
                                            <ShowMoreText
                                                lines={2}
                                                more={""}
                                                less={""}
                                                className=""
                                                anchorClass=""
                                                expanded={false}
                                                truncatedEndingComponent={"... "}
                                                width={textWidthInShowMoreText}
                                            >
                                                {address.HouseFlatBlockNumber}
                                            </ShowMoreText>
                                            <ShowMoreText
                                                lines={2}
                                                more={""}
                                                less={""}
                                                className=""
                                                anchorClass=""
                                                expanded={false}
                                                truncatedEndingComponent={"... "}
                                                width={textWidthInShowMoreText}
                                            >
                                                {address.Landmark}
                                            </ShowMoreText>
                                            <span>{showEstimateTime && shippingType !== 2 && <><b>Estimate Time:</b> {toMMSS(address.TimeDuration)}</>}</span>
                                        </div>
                                    </div>
                                </>
                            </>
                        )
                    }
                </div>
            </div >
        </>
    );
}

export default SelectedAddress;
