import {
    ADD_CREATED_REFUND_COUNT,
    BEGIN_ACTION_IN_REFUND_REDUCER,
    CLEAR_DATA_IN_REFUND_REDUCER,
    EXCEPTION_GENERATED_IN_REFUND_REDUCER,
    SUCCESS_GET_LAST_REFUND_DATA,
    SUCCESS_GET_REDUNDS_DATA,
    SUCCESS_GET_REDUND_DETAILS_DATA
} from "../constants";

const initialState = {
    loading: false,
    exception: false,
    refundData: [],
    refundDetails: [],
    countCreatedRefunds: 0,
    countTotalRefunds: 0,
    pageNoOffetchedRedunds: 0
}

function refundReducer(state = initialState, action) {
    switch (action.type) {

        case BEGIN_ACTION_IN_REFUND_REDUCER:
            return {
                ...state,
                loading: true,
                exception: false
            };

        case SUCCESS_GET_REDUNDS_DATA:
            return {
                ...state,
                refundData: action.payload.refunds,
                countTotalRefunds: action.payload.countTotalRefunds,
                pageNoOffetchedRedunds: action.payload.fetchedPageNo,
                loading: false,
                exception: false
            }

        case SUCCESS_GET_LAST_REFUND_DATA:
            return {
                ...state,
                loading: false,
                exception: false,
                refundData: action.payload
            }

        case SUCCESS_GET_REDUND_DETAILS_DATA:
            return {
                ...state,
                loading: false,
                exception: false,
                refundDetails: action.payload.refundHistory
            }

        case ADD_CREATED_REFUND_COUNT:
            return {
                ...state,
                countCreatedRefunds: state.countCreatedRefunds++
            }

        case CLEAR_DATA_IN_REFUND_REDUCER:
            return {
                ...state,
                refundData: [],
                refundDetails: [],
                countCreatedRefunds: 0,
                countTotalRefunds: 0,
                pageNoOffetchedRedunds: 0
            }

        case EXCEPTION_GENERATED_IN_REFUND_REDUCER:
            return {
                ...state,
                loading: false,
                exception: action.payload
            }


        default:
            return state
    }
}

export default refundReducer;
