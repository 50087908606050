import {
    BEGIN_ACTION_IN_OFFER_REDUCER,
    SUCCESS_GET_OFFERS,
    APPLY_COUPON_CODE,
    REMOVE_APPLIED_COUPON_CODE,
    CLEAR_DATA_IN_OFFER_REDUCER,
    EXCEPTION_GENERATED_IN_OFFER_REDUCER,
    CLEAR_EXCEPTION_AND_LOADING_STATE,
} from "../constants";

const initialState = {
    loading: false,
    exception: false,
    offers: [],
    appliedCouponsData: false
};

const offerReducer = (state = initialState, action) => {
    switch (action.type) {
        case BEGIN_ACTION_IN_OFFER_REDUCER:
            return {
                ...state,
                loading: true,
                exception: false,
            };

        case SUCCESS_GET_OFFERS:
            return {
                ...state,
                loading: false,
                offers: action.payload
            }

        case APPLY_COUPON_CODE:
            return {
                ...state,
                loading: false,
                appliedCouponsData: action.payload
            }

        case REMOVE_APPLIED_COUPON_CODE:
            return {
                ...state,
                appliedCouponsData: false
            }

        case CLEAR_DATA_IN_OFFER_REDUCER:
            return {
                ...state,
                offers: [],
                appliedCouponsData: false
            }

        case EXCEPTION_GENERATED_IN_OFFER_REDUCER:
            return {
                ...state,
                loading: false,
                exception: action.payload,
            }

        case CLEAR_EXCEPTION_AND_LOADING_STATE:
            return {
                ...state,
                loading: false,
                exception: false,
            };

        default:
            return state;
    }
}

export default offerReducer;