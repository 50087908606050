import {
    BEGIN_ACTION_IN_PRODUCT_DETAILS_REDUCER,
    SUCCESS_GET_PRODUCTS_DETAILS,
    SUCCESS_GET_PRODUCTS_REVIEWS,
    UPDATE_LOADED_PAGE_NUMBER_OF_PRODUCT_REVIEWS,
    SUCCESS_POST_RATING,
    IS_USER_ALREADY_POSTED_REVIEW,
    EXCEPTION_GENERATED_IN_PRODUCT_DETAILS_REDUCER,
    CLEAR_EXCEPTION_AND_LOADING_STATE,
} from "../constants";

const initialState = {
    loading: false,
    exception: false,
    productData: [],
    ratings: [],
    reviews: [],
    loadedReviewsPageNumber: [],
    isUserAlreadyPostedReview: undefined,
};

const productDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case BEGIN_ACTION_IN_PRODUCT_DETAILS_REDUCER:
            return {
                ...state,
                loading: true,
                exception: false,
            };

        case SUCCESS_GET_PRODUCTS_DETAILS:
            return {
                ...state,
                loading: false,
                exception: false,
                loadedReviewsPageNumber: [
                    ...state.loadedReviewsPageNumber,
                    {
                        ProductId: action.payload.ProductDetails.ProductId,
                        LoadedPageNumber: 1,
                    },
                ],
                productData: [
                    ...state.productData,
                    {
                        ProductId: action.payload.ProductDetails.ProductId,
                        ProductName: action.payload.ProductDetails.ProductName,
                        ProductPrice: action.payload.ProductDetails.ProductPrice,
                        ProductImage: action.payload.ProductDetails.ProductImage,
                        OutOfStoke: action.payload.ProductDetails.OutOfStoke,
                        PackType: action.payload.ProductDetails.PackType,
                    },
                ],
                ratings: [
                    ...state.ratings,
                    {
                        ProductId: action.payload.ProductDetails.ProductId,
                        AverageRating: action.payload.ProductRatings.AverageRating,
                        FiveStar: action.payload.ProductRatings.FiveStar,
                        FourStar: action.payload.ProductRatings.FourStar,
                        ThreeStar: action.payload.ProductRatings.ThreeStar,
                        TwoStar: action.payload.ProductRatings.TwoStar,
                        OneStar: action.payload.ProductRatings.OneStar,
                    },
                ],
                reviews: [
                    ...state.reviews,
                    {
                        ProductId: action.payload.ProductDetails.ProductId,
                        Reviews: action.payload.ProductReviews,
                        //Temporarly we removed top reviews
                        //TopReviews: action.payload.TopReviews,
                        TotalReviews: action.payload.TotalReviews,
                    },
                ],
            };

        case SUCCESS_GET_PRODUCTS_REVIEWS:
            return {
                ...state,
                loading: false,
                exception: false,
                reviews: [
                    ...state.reviews.map((obj) => {
                        if (
                            parseInt(obj.ProductId) ===
                            parseInt(action.payload.ProductId)
                        )
                            return {
                                ...obj,
                                Reviews: [
                                    ...obj.Reviews,
                                    ...action.payload.Reviews,
                                ],
                                TotalReviews:
                                    action.payload.TotalNumberOfReviews,
                            };
                        else return obj;
                    }),
                ],
            };

        case UPDATE_LOADED_PAGE_NUMBER_OF_PRODUCT_REVIEWS:
            return {
                ...state,
                loadedReviewsPageNumber: [
                    ...state.loadedReviewsPageNumber.map((obj) => {
                        return parseInt(obj.ProductId) ===
                            parseInt(action.payload.ProductId)
                            ? action.payload
                            : obj;
                    }),
                ],
            };
        case IS_USER_ALREADY_POSTED_REVIEW:
            return {
                ...state,
                isUserAlreadyPostedReview: action.payload,
            };
        case SUCCESS_POST_RATING:
            //here we are removing all the data of current products from store
            //because we are re-fetching current products data after posting a users review and rating
            //to show updated reviews and ratings

            //function to remove current products data from given object
            const removeState = (object) => {
                return object.filter((obj) => {
                    return (
                        parseInt(obj.ProductId) !== parseInt(action.ProductId)
                    );
                });
            };

            return {
                ...state,
                loading: false,
                exception: false,
                productData: removeState(state.productData),
                ratings: removeState(state.ratings),
                reviews: removeState(state.reviews),
                loadedReviewsPageNumber: removeState(
                    state.loadedReviewsPageNumber
                ),
            };

        case EXCEPTION_GENERATED_IN_PRODUCT_DETAILS_REDUCER:
            return {
                ...state,
                loading: false,
                exception: action.payload,
            };

        case CLEAR_EXCEPTION_AND_LOADING_STATE:
            return {
                ...state,
                loading: false,
                exception: false,
            };

        default:
            return state;
    }
};

export default productDetailsReducer;
