import React from 'react'
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'

export default function NotFound() {

    const history = useHistory();
    const path = useLocation().pathname;

    const redirectAction = () => {
        if (path === "/not-found/home")
            history.push("/")
        else
            history.goBack()
    }

    //scroll to top when component loads 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="page-content-wrapper">
            <div className="row justify-content-center">
                <div className="col-md-6 col-lg-6">
                    <div className="card no-content">
                        <div className="card-body">
                            <div className="text-center no-content-body">

                                <img
                                    className="login-intro-img"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/bg-img/404.png"
                                    }
                                    style={{ width: "50vw" }}
                                    alt=""
                                />
                                <h4 className="mt-3">OOPS... <br /> Page not found!</h4>
                                <p >We couldn't find any results for your search.</p>
                                <button
                                    className="btn btn-creative btn-warning w-50 mt-3"
                                    onClick={redirectAction}
                                >
                                    <i className="fa fa-arrow-left"></i> {path === "/no-products" ? 'Back to home' : 'Take me back'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
