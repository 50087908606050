import React from 'react'

export default function PaymentProcessingDialog() {
    return (
        <div className="modal fade dialogbox" id="paymentProcessingDialog" data-bs-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className='kcs-text-color pt-5 mb-4 text-center'>
                        <b><small>Processing payment</small></b> <div className="spinner-border kcs-text-color spinner-border-sm text-primary" role="status"></div>
                    </div>
                    <div className="modal-header m-0 p-0">
                        <p className="modal-title">Notice!!</p>
                    </div>
                    <div className="modal-body mt-3">
                        <p>Please do not press the back button or refresh the page while processing the payment as it might cause a payment failure for your current order</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
