import React from "react";

//import css files
import "../../assets/css/bootstrap-icons.css";
import "../../assets/css/style.css";
import "../../assets/css/dialog.css";
import "../../assets/css/custom.css";


//Added in index.html
//import "../../assets/css/font-awesome.min.css";
//import "../../assets/css/bootstrap.min.css";

import "jquery";

//import components
import Loader from "../popupDialogs/Loader";
import ErrorDialog from "../popupDialogs/ErrorDialog";
import SuccessDialog from "../popupDialogs/SuccessDialog";
import DarkModeSwitching from "./DarkModeSwitching";
import HeaderArea from "./HeaderArea";
import SideNav from "./SideNav";

function Header() {
    return (
        <>
            <Loader />
            <ErrorDialog />
            <SuccessDialog />
            <HeaderArea />
            <DarkModeSwitching />
            <SideNav />
        </>
    );
}

export default Header;
