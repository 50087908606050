import React, { createContext } from "react";
import $ from "jquery";

export const modalContext = createContext('modalContext')

export const ModalProvider = (props) => {

    const showErrorDialog = (errorTitle, errorMessage) => {
        window.$("#errorDialogTitle").html(errorTitle);
        window.$("#errorDialogBodyText").html(errorMessage);
        window.$("#errorDialog").modal("show");
    };

    const showSuccessDialog = (title, message) => {
        window.$("#successTitle").html(title);
        window.$("#successMessage").html(message);
        window.$("#successDialog").modal("show");
    };

    const showLoading = () => $("#ajaxLoader").css("visibility", "visible");
    const hideLoading = () => $("#ajaxLoader").css("visibility", "hidden");

    return (
        <modalContext.Provider value={{ showErrorDialog, showSuccessDialog, showLoading, hideLoading }}>
            {props.children}
        </modalContext.Provider>
    )
}
