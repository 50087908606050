import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const AddressSkeleton = () => {
    return (
        <SkeletonTheme highlightColor="#bababa">
            <Skeleton height={'40vh'} />
            <div className='container'>
                <Skeleton height={120} className='mt-2' />
                <Skeleton height={25} count={3} className='mt-3' />
                <div className='row mt-3'>
                    <div className="d-flex justify-content-around col-4 col- col-sm col-md col-lg col-xl" >
                        <Skeleton height={100} width={85} />
                    </div>
                    <div className="d-flex justify-content-around col-4 col- col-sm col-md col-lg col-xl" >
                        <Skeleton height={100} width={85} />
                    </div>
                    <div className="d-flex justify-content-around col-4 col- col-sm col-md col-lg col-xl" >
                        <Skeleton height={100} width={85} />
                    </div>
                </div>
                <div className="container">
                    <Skeleton height={35} className="mt-4 mb-4" />
                </div>
            </div>
        </SkeletonTheme>
    )
}

export default AddressSkeleton;
