import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { authContext } from "../../context/authContext";
import { cartItemContext } from "../../context/cartItemContext";
import { clearAddressReducersData } from "../../redux/actions/addressActions";
import { clearOfferReducersData } from "../../redux/actions/offerActions";
import { clearOrderProcessReducersData } from "../../redux/actions/orderProcessActions";
import { clearOrderDataReducersData } from "../../redux/actions/orderDataActions";
import { clearRefundReducerData } from "../../redux/actions/refundActions";

function SideNav() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { isAuthenticate, logOut } = useContext(authContext);
    const { clearCartItems } = useContext(cartItemContext);

    const handleLogout = async () => {
        const isLogout = await logOut();

        if (isLogout === true) {

            //clear reducers data
            dispatch(clearOfferReducersData());
            dispatch(clearOrderProcessReducersData(true));
            dispatch(clearOrderDataReducersData());
            dispatch(clearAddressReducersData());
            dispatch(clearRefundReducerData());

            clearCartItems();

            closeSideNav();
            history.push("/");
        }
    };

    const closeSideNav = () => {
        window.$('#sidenavWrapper').removeClass('nav-active');
        window.$('#backOverlay').removeClass('active');
    }

    return (
        <>
            {/* Sidenav Black Overlay*/}
            <div className="sidenav-black-overlay" id="backOverlay"></div>

            {/* Side Nav Wrapper*/}
            <div className="sidenav-wrapper" id="sidenavWrapper">
                {/* Go Back Button*/}
                <div className="go-back-btn" id="goBack" onClick={closeSideNav}>
                    <svg
                        className="bi bi-x"
                        width="24"
                        height="24"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
                        ></path>
                        <path
                            fillRule="evenodd"
                            d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
                        ></path>
                    </svg>
                </div>

                {/* Sidenav Profile*/}
                <div className="sidenav-profile">
                    <div className="sidenav-style1"></div>
                    {isAuthenticate ? (
                        <>
                            {/* User Info*/}
                            <div className="user-info">
                                <h6 className="user-name mb-0">
                                    {isAuthenticate?.username}
                                </h6>
                                <span>
                                    {
                                        isAuthenticate?.eamil || isAuthenticate?.phoneNumber
                                    }
                                </span>
                            </div>
                        </>
                    ) : null}
                </div>

                {/* Sidenav Nav*/}
                {isAuthenticate ? (
                    <>
                        <ul className="sidenav-nav ps-0">
                            <li>
                                <Link to="/user/update" onClick={closeSideNav}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                    </svg>
                                    Update Profile
                                </Link>
                            </li>

                            <li>
                                <Link to="/user/address" onClick={closeSideNav}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16">
                                        <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
                                    </svg>
                                    Manage Address
                                </Link>
                            </li>
                            <li>
                                <Link to="/order/history" onClick={closeSideNav}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-bag-check" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                        <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                                    </svg>
                                    My Orders
                                </Link>
                            </li>
                            <li>
                                <Link to="/refund" onClick={closeSideNav}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-credit-card-2-back" viewBox="0 0 16 16">
                                        <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                                        <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                                    </svg>
                                    Refunds
                                </Link>
                            </li>
                            <li>
                                <Link to="#" onClick={handleLogout}>
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 16 16"
                                        className="bi bi-folder2-open"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z"
                                        />
                                    </svg>
                                    Log out
                                </Link>
                            </li>
                        </ul>
                    </>
                ) : (
                    <>
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <h6 className="text">
                                        You are not loged in
                                        <br />
                                        Please login to access profile
                                    </h6>

                                    <img
                                        className="login-intro-img "
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/bg-img/update.png"
                                        }
                                        alt=""
                                    />
                                    <Link
                                        className="btn btn-primary w-100"
                                        to="/login"
                                        onClick={closeSideNav}
                                    >
                                        <i className="fa fa-user"></i>{" "}
                                        <strong className="ms-2">Login</strong>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {/* Social Info
                <div className="social-info-wrap">
                    <Link to="#">
                        <i className="fa fa-twitter"></i>
                    </Link>
                    <Link to="#">
                        <i className="fa fa-facebook"></i>
                    </Link>
                    <Link to="#">
                        <i className="fa fa-dribbble"></i>
                    </Link>
                </div>

                {/* Copyright Info
                <div className="copyright-info">
                    <p>
                        &copy; {new Date().getFullYear()} All rights reserved by
                        <Link to="#">Chicken shop</Link>{" "}
                    </p>
                </div>*/}
            </div>
        </>
    );
}

export default SideNav;
