import { getAuth, RecaptchaVerifier } from "firebase/auth";
import sendOtp from "./sendOtp";

const recaptcha = (recaptchaContainerId) => {
    const auth = getAuth();
 
    window.recaptchaVerifier = new RecaptchaVerifier(
        recaptchaContainerId,
        {
            size: "invisible",
            callback: (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                sendOtp();
            },
        },
        auth
    );
};

export default recaptcha;
