import React from 'react'

export default function CancelationPoliciesDialog({ history, checkoutCallback, callBackParams }) {
    const redirectToReadPolicy = () => {
        window.$("#cancelationPolicyModal").modal("hide");
        history.push('/policy');
    }

    const fireCallBackFunction = () => {

        if (!localStorage.getItem("isPrivacyPopupShowedAlready"))
            localStorage.setItem("isPrivacyPopupShowedAlready", true);

        if (callBackParams)
            checkoutCallback(callBackParams)
        else
            checkoutCallback()
    }

    return (
        <>
            <div
                className="modal fade bottom-align-modal"
                id="cancelationPolicyModal"
                tabIndex="-1"
                aria-labelledby="cancleationPolicyLable"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-end">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='modal-header cancalation-notice-modal-header'>
                                <button
                                    className="btn btn-close p-1"
                                    type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <center>
                                <img className='' src={process.env.PUBLIC_URL + '/icons/policy.png'} width={50} alt="policy-icon" />

                                <h5 className='kcs-text-color border-danger w-75 border-bottom pt-3'>Cancellation Policy</h5>

                                <p className='lh-md p-1'>
                                    If you choose to cancle, you can do it within <b>60 secons</b> after placing the order.
                                    Post which you will be charged <b>100% cancellation fee.</b> However, in the event of an unusual delay of the order,
                                    you will not be charged a cancellation fee.
                                </p>

                                <hr />

                                <p>
                                    <small className='kcs-text-color'>
                                        this policy help us to avoid vastage and compensate shops / delivery partners for their efforts.
                                    </small>
                                </p>

                                <span className='custom-text-style border-bottom border-danger' onClick={() => redirectToReadPolicy()}>
                                    Read Policy
                                </span>
                                <div className='py-3 pb-3'>
                                    <button className='btn custom-btn-style w-100' onClick={() => fireCallBackFunction()}>I UNDERSTAND, PROCEED WITH ORDER</button>
                                </div>

                            </center>

                        </div>
                        {/* end modal body*/}
                    </div>
                </div>
            </div>
        </>
    );
}
