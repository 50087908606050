import { combineReducers } from "redux";

import homeReducer from "./reducer/homeReducer"
import productReducer from "./reducer/productReducer"
import categoriesReducer from "./reducer/categoriesReducer"
import productDetailsReducer from "./reducer/productDetailsReducer"
import addressReducer from "./reducer/addressReducer"
import offerReducer from "./reducer/offerReducer";
import orderProcessReducer from "./reducer/orderProcessReducer";
import orderDataReducer from "./reducer/orderDataReducer";
import refundReducer from './reducer/refundReducer';


const rootReducer  = {
    homeReducer,
    categoriesReducer,
    productReducer,
    productDetailsReducer,
    addressReducer,
    offerReducer,
    orderProcessReducer,
    orderDataReducer,
    refundReducer
};

export default combineReducers(rootReducer)


