import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authContext } from '../../context/authContext';
import { modalContext } from '../../context/modalContext';
import { serverContext } from '../../context/serverContext';
import { postUserAddress } from '../../redux/actions/addressActions';

//import here map api objects
import H from "@here/maps-api-for-javascript";
import onResize from 'simple-element-resize-detector';


export default function Address() {

  const dispatch = useDispatch();
  const history = useHistory();

  const { isAuthenticate } = useContext(authContext)
  const { showErrorDialog, hideLoading } = useContext(modalContext);
  const { serverPath } = useContext(serverContext);

  const mapRef = useRef(null);
  //const [routeInstructionsContainer, setRouteInstructionsContainer] = useState({})
  const [displayMap, setDisplayMap] = useState(null)

  //set users default lat,long as botad
  const usersCordinates = { lat: 22.17203, long: 71.66369 }

  //set users default address
  const [userAddress, setUserAddress] = useState({
    fullAddress: 'Haveli Chowk, Botad 364710, India',
    landmark: 'Haveli Chowk',
    lat: 22.17203,
    long: 71.66369
  });


  const [ui, setUi] = useState('');



  // Hold a reference to any infobubble opened
  var bubble;

  function addPolylineToMap(map, destination) {
    var lineString = new H.geo.LineString();

    lineString.pushPoint({ lat: 22.17203, lng: 71.66369 });
    lineString.pushPoint({ lat: destination.lat, lng: destination.long });

    map.addObject(new H.map.Polyline(
      lineString, { style: { lineWidth: 4 } }
    ));
  }

  function calculateRouteFromAtoB(lat, lng) {
    const platform = new H.service.Platform({
      apikey: 'XC743SQYeQ2zjjzzzhIOmGyVZrfxbUOgoWga0hC1TOA'
    });

    var router = platform.getRoutingService(null, 8),
      routeRequestParams = {
        routingMode: 'fast',
        transportMode: 'scooter',
        origin: '22.17203,71.66369',//store location
        destination: `${lat},${lng}`,//customers location
        return: 'polyline,turnByTurnActions,actions,instructions,travelSummary'
      };

    router.calculateRoute(
      routeRequestParams,
      onSuccess,
      onError
    );
  }

  function onSuccess(result) {
    var route = result.routes[0];

    /*
     * The styling of the route response on the map is entirely under the developer's control.
     * A representative styling can be found the full JS + HTML code of this example
     * in the functions below:
     */
    addRouteShapeToMap(route);
    addManueversToMap(route);
    addWaypointsToPanel(route);
    addManueversToPanel(route);
    addSummaryToPanel(route);
    // ... etc.
  }

  /**
   * This function will be called if a communication error occurs during the JSON-P request
   * @param {Object} error The error message received.
   */
  function onError(error) {
    alert('Can\'t reach the remote server');
  }



  /**
   * Creates a H.map.Polyline from the shape of the route and adds it to the map.
   * @param {Object} route A route as received from the H.service.RoutingService
   */
  function addRouteShapeToMap(route) {
    route.sections.forEach((section) => {
      // decode LineString from the flexible polyline
      let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);

      // Create a polyline to display the route:
      let polyline = new H.map.Polyline(linestring, {
        style: {
          lineWidth: 4,
          strokeColor: 'rgba(0, 128, 255, 0.7)'
        }
      });

      // Add the polyline to the map
      displayMap.addObject(polyline);
      // And zoom to its bounding rectangle
      displayMap.getViewModel().setLookAtData({
        bounds: polyline.getBoundingBox()
      });
    });
  }

  /**
   * Creates a series of H.map.Marker points from the route and adds them to the map.
   * @param {Object} route A route as received from the H.service.RoutingService
   */
  function addManueversToMap(route) {
    var svgMarkup = '<svg width="18" height="18" ' +
      'xmlns="http://www.w3.org/2000/svg">' +
      '<circle cx="8" cy="8" r="8" ' +
      'fill="#1b468d" stroke="white" stroke-width="1" />' +
      '</svg>',
      dotIcon = new H.map.Icon(svgMarkup, { anchor: { x: 8, y: 8 } }),
      group = new H.map.Group(), i;

    route.sections.forEach((section) => {
      let poly = H.geo.LineString.fromFlexiblePolyline(section.polyline).getLatLngAltArray();

      let actions = section.actions;
      // Add a marker for each maneuver
      for (i = 0; i < actions.length; i += 1) {
        let action = actions[i];
        var marker = new H.map.Marker({
          lat: poly[action.offset * 3],
          lng: poly[action.offset * 3 + 1]
        },
          { icon: dotIcon });
        marker.instruction = action.instruction;
        group.addObject(marker);
      }

      group.addEventListener('tap', function (evt) {
        displayMap.setCenter(evt.target.getGeometry());
        openBubble(evt.target.getGeometry(), evt.target.instruction);
      }, false);

      // Add the maneuvers group to the map
      displayMap.addObject(group);
    });
  }

  /**
   * Creates a series of H.map.Marker points from the route and adds them to the map.
   * @param {Object} route A route as received from the H.service.RoutingService
   */
  function addWaypointsToPanel(route) {
    var nodeH3 = document.createElement('h3'),
      labels = [];

    console.log('route: ', route);

    route.sections.forEach((section) => {

      labels.push(
        section?.turnByTurnActions[0]?.nextRoad?.name[0]?.value)
      /*labels.push(
        section?.turnByTurnActions[section.turnByTurnActions.length - 1]?.currentRoad?.name[0]?.value)*/
    });

    nodeH3.textContent = labels.join(' - ');
    //const waypoints = labels.join(' - ');
    //setRouteInstructionsContainer({ ...routeInstructionsContainer, waypoints: waypoints });
  }

  /**
   * Creates a series of H.map.Marker points from the route and adds them to the map.
   * @param {Object} route A route as received from the H.service.RoutingService
   */
  function addSummaryToPanel(route) {
    let duration = 0,
      distance = 0;

    route.sections.forEach((section) => {
      distance += section.travelSummary.length;
      duration += section.travelSummary.duration;
    });

    var summaryDiv = document.createElement('div'),
      content = '<b>Total distance</b>: ' + distance + 'm. <br />' +
        '<b>Travel Time</b>: ' + toMMSS(duration) + ' (in current traffic)';

    summaryDiv.style.fontSize = 'small';
    summaryDiv.style.marginLeft = '5%';
    summaryDiv.style.marginRight = '5%';
    summaryDiv.innerHTML = content;
    //routeInstructionsContainer.appendChild(summaryDiv);
    //setRouteInstructionsContainer({ ...routeInstructionsContainer, sumery: { distance: distance, duration: duration } });
  }

  /**
   * Creates a series of H.map.Marker points from the route and adds them to the map.
   * @param {Object} route A route as received from the H.service.RoutingService
   */
  function addManueversToPanel(route) {
    var nodeOL = document.createElement('ol');

    nodeOL.style.fontSize = 'small';
    nodeOL.style.marginLeft = '5%';
    nodeOL.style.marginRight = '5%';
    nodeOL.className = 'directions';

    const actions = [];

    route.sections.forEach((section) => {
      section.actions.forEach((action, idx) => {
        var li = document.createElement('li'),
          spanArrow = document.createElement('span'),
          spanInstruction = document.createElement('span');

        actions.push({
          spanArrow: `arrow ${action.direction} ${action.action}`,
          spanInstruction: section.actions[idx].instruction
        })

        spanArrow.className = 'arrow ' + (action.direction || '') + action.action;
        spanInstruction.innerHTML = section.actions[idx].instruction;
        li.appendChild(spanArrow);
        li.appendChild(spanInstruction);

        nodeOL.appendChild(li);
      });
    });


    //routeInstructionsContainer.appendChild(nodeOL)
    //setRouteInstructionsContainer({ ...routeInstructionsContainer, action: actions })
  }

  function toMMSS(duration) {
    return Math.floor(duration / 60) + ' minutes ' + (duration % 60) + ' seconds.';
  }


  /**
  * Opens/Closes a infobubble
  * @param {H.geo.Point} position The location on the map.
  * @param {String} text          The contents of the infobubble.
  */
  function openBubble(position, text) {
    if (!bubble) {
      bubble = new H.ui.InfoBubble(
        position,
        // The FO property holds the province name.
        { content: text });
      ui.addBubble(bubble);
    } else {
      bubble.setPosition(position);
      bubble.setContent(text);
      bubble.open();
    }
  }













  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [email, setEmail] = useState('')

  const [errorLabelForLandmark, setErrorLabelForLandmark] = useState('');
  const [errorLabelForMobile, setErrorLabelForMobile] = useState('');
  const [errorLabelForEmail, setErrorLabelForEmail] = useState('');

  const isAddressPosted = useRef(false);


  const isValidPhoneNumber = phoneNumber => {
    const isValid = /^[0-9]+$/.test(phoneNumber)

    if (phoneNumber.length === 10 && isValid)
      return true
    else
      return false
  }

  //get landmark from user input and set in userAdress
  const handleChangeLandmark = landmark => {

    if (landmark.length === 0)
      setErrorLabelForLandmark('This field is required');
    else if (landmark.length < 3)
      setErrorLabelForLandmark('it should be at least 3 characters long');
    else
      setErrorLabelForLandmark('');

    setUserAddress({ ...userAddress, landmark: landmark })
  }

  //get and set phone number from user input
  const handleChangePhoneNumber = phoneNumber => {

    //check is entered phonr number is valid or not  
    const isValid = /^[0-9]+$/.test(phoneNumber)

    //return if entered value is not a numbers
    if (phoneNumber.length > 0 && !isValid)
      return;

    if (phoneNumber.length > 10)
      return;

    if (phoneNumber.length === 0)
      setErrorLabelForMobile('Phone number is Required');
    else if (phoneNumber.length < 10 || !isValid)
      setErrorLabelForMobile('Enter valid phone number');
    else
      setErrorLabelForMobile('');

    setPhoneNumber(phoneNumber);
  }
  //get and set email from user input
  const handleChangeEmail = email => {

    const emailMask = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isValidEmail = emailMask.test(email);

    if (email.length === 0 || isValidEmail)
      setErrorLabelForEmail('')
    else
      setErrorLabelForEmail('Enter valid email address')

    setEmail(email);
  }

  const saveAddress = () => {

    //if user is offline then show error warning
    if (!window.navigator.onLine) {
      showErrorDialog(
        "You are offline",
        "Please check your internet connection and try again"
      );
      return false;
    }

    const { fullAddress, landmark, lat, long } = userAddress;

    if (fullAddress && landmark && lat && long && isValidPhoneNumber(phoneNumber)) {
      const addressParams = {
        userId: isAuthenticate?.userId,
        name: isAuthenticate?.username,
        email: email,
        mobile: phoneNumber,
        fullAddress: fullAddress,
        landmark: landmark,
        lat: lat,
        long: long
      };

      //showLoading();

      //dispatch action to post user address
      dispatch(postUserAddress(serverPath, addressParams, displayMap, addPolylineToMap, calculateRouteFromAtoB,));

      isAddressPosted.current = true;
    }
    else {
      showErrorDialog('Invalid address', 'It happens because of some missing address fields ,Please make sure you have filled all the required details then try again')
      isAddressPosted.current = false;
    }
  }

  const exception = useSelector((state) => state.addressReducer.exception);
  const loading = useSelector((state) => state.addressReducer.loading);

  if (exception) {
    showErrorDialog(exception.message, exception.description);
    hideLoading();

    dispatch({
      type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
    });
  }

  //redirect if address is saved successfully
  if (!loading && !exception && isAddressPosted.current === true) {
    hideLoading()
    history.push("/checkout");
  }

  //get users location access functionality is temporarly disabled
  /*
  //function to get lat & long from users location
  const position = (position) => {
    console.log(position.coords)
    setUsersCordinates({lat:position.coords.latitude,long:position.coords.longitude})
  }
  
  //function to request location access from user
  const getUserLocation = () => {
    //if user provide location access then get lat and long of users location
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition(position);
  }*/


  //scroll to top when component loads 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {

    /* 
     * redirect to login page if user is not logged in yet
    */
    //we are fetching logged in users data from authContext and it takes few seconds to authenticate user each time when page reloads
    //here we check "isAuthenticate === false" because the default state of isAuhtenticate is as null
    //if user is not logged in then state of isAuthenticate will be false else state will store users data
    //if (isAuthenticate === false && !isAuthenticate.email && !isAuthenticate.phoneNumber)
    //history.push('/login');

    //set users default phone and email when user data fetched from authContext
    setPhoneNumber(isAuthenticate?.phoneNumber);
    setEmail(isAuthenticate?.email);

    /*
    //temporarly disabled  
    //call funstion to get location access from users
    if (!usersCordinates) {
      getUserLocation();
    }*/

    if (usersCordinates && !displayMap) {
      // instantiate a platform, default layers and a map as usual
      const platform = new H.service.Platform({
        apikey: 'XC743SQYeQ2zjjzzzhIOmGyVZrfxbUOgoWga0hC1TOA'
      });

      const layers = platform.createDefaultLayers();

      // Get an instance of the search service:
      const service = platform.getSearchService();

      const map = new H.Map(
        mapRef.current,
        layers.raster.satellite.map,
        {
          pixelRatio: window.devicePixelRatio,
          center: { lat: usersCordinates.lat, lng: usersCordinates.long },
          zoom: 13,
        },
      );

      // Create the default UI components
      setUi(H.ui.UI.createDefault(map, layers));

      //adjust map size as per screen whenever the screen will resize
      onResize(mapRef.current, () => {
        map.getViewPort().resize();
      });

      //set image for custom map marker
      const mapMarker = new H.map.Icon(process.env.PUBLIC_URL + '/icons/map_marker.png');

      //set marker object
      const marker = new H.map.Marker({ lat: usersCordinates.lat, lng: usersCordinates.long }, {
        // mark the object as volatile for the smooth dragging
        volatility: true,
        icon: mapMarker,
      });

      // Ensure that the marker can receive drag events
      marker.draggable = true;
      map.addObject(marker);

      // MapEvents enables the event system
      // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
      const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

      // disable the default draggability of the underlying map
      // and calculate the offset between mouse and target's position
      // when starting to drag a marker object:
      map.addEventListener('dragstart', function (ev) {
        let target = ev.target,
          pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
          let targetPosition = map.geoToScreen(target.getGeometry());
          target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
          behavior.disable();
        }

      }, false);

      // re-enable the default draggability of the underlying map
      // when dragging has completed
      map.addEventListener('dragend', function (ev) {
        let target = ev.target;
        if (target instanceof H.map.Marker) {
          behavior.enable();

          service.reverseGeocode({
            at: `${target.a.lat},${target.a.lng},150`
          }, (result) => {

            //get address from lat, long
            if (result.items.length > 0) {
              const addressObj = result.items[0];
              const addressType = addressObj.resultType === 'place' ? '' : addressObj.resultType;

              setUserAddress({
                fullAddress: `${addressType} ${addressObj.address.label}`,
                landmark: '',
                lat: addressObj.position.lat,
                long: addressObj.position.lng
              })
            }
          })
        }
      }, false);

      // Listen to the drag event and move the position of the marker
      // as necessary
      map.addEventListener('drag', function (ev) {
        let target = ev.target,
          pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
          target.setGeometry(map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
        }
      }, false);

      //call function to draw polyline on map
      //addPolylineToMap(map);

      setDisplayMap(map)
    }
    //eslint-disable-next-line
  }, [isAuthenticate])


  return (
    <>
      <div className="page-content-wrapper">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-6">
            <div className="card">
              <div className="">
                {/*set display mode as none if user is not authenticate*/}
                <div
                  className="mt-1"
                  style={{ width: '100%', height: '40vh' }}
                  ref={mapRef}
                />


                <div className="container">
                  <div className="form-group mt-2">
                    <textarea className="form-control" name="fullAddress" disabled={true} value={userAddress.fullAddress} />
                  </div>
                  <div className="form-group">

                    <input type="text" className="form-control" name="landmark" value={userAddress.landmark} onChange={(e) => handleChangeLandmark(e.target.value)} placeholder="Enter landmark" />
                    {
                      errorLabelForLandmark &&
                      <label className='form-lable text-danger' htmlFor="landmark">
                        <small>{errorLabelForLandmark}</small>
                      </label>
                    }
                  </div>
                  <div className="form-group">
                    <input type="tel" className="form-control" name="mobile"
                      value={phoneNumber} maxLength={10}
                      onChange={(e) => handleChangePhoneNumber(e.target.value)}
                      placeholder="Enter Mobile Number" />
                    {
                      errorLabelForMobile &&
                      <label className='form-lable text-danger' htmlFor="mobile">
                        <small>{errorLabelForMobile}</small>
                      </label>
                    }
                  </div>
                  <div className="form-group">
                    <input type="email" className="form-control" name="email"
                      value={email} onChange={(e) => handleChangeEmail(e.target.value)}
                      placeholder="Enter email address (optional)" />
                    {
                      errorLabelForEmail &&
                      <label className='form-lable text-danger' htmlFor="email">
                        <small>{errorLabelForEmail}</small>
                      </label>
                    }
                  </div>
                  <div className="container">
                    <button className="btn custom-btn-style w-100 mt-4 mb-4" onClick={saveAddress}

                    >Save Address</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}


