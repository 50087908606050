import React, { createContext } from "react";

export const serverContext = createContext("serverContext");

export const ServerProvider = (props) => {
    const serverPath =   "https://kcsadmin.shop/v1"; //"http://localhost/kcs-admin/v1"; //
    const serverImagePath =  "https://kcsadmin.shop/assets/img"; //"http://localhost/kcs-admin/assets/img"; // 

    return (
        <serverContext.Provider value={{ serverPath, serverImagePath }}>
            {props.children}
        </serverContext.Provider>
    );
};
