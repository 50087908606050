import React from 'react'
import { Link } from 'react-router-dom'

export default function NoOrders() {
    return (
        <div className="card no-content">
            <div className="card-body">
                <div className="text-center no-content-body">
                    <h6 className='text-warning'>
                        Once you place order it will display here,<br />
                        It seems like you haven't purchase anything yet.
                    </h6>

                    <img
                        className="login-intro-img "
                        src={
                            process.env.PUBLIC_URL +
                            "/bg-img/emptyCart.png"
                        }
                        alt=""
                    />

                    <Link
                        className="btn btn-warning w-50"
                        to="/"
                    >
                        Order now <i className="fa fa-shopping-cart"></i>
                    </Link>
                </div>
            </div>
        </div>
    )
}
