import {
    BEGIN_ACTION_IN_OFFER_REDUCER,
    SUCCESS_GET_OFFERS,
    APPLY_COUPON_CODE,
    REMOVE_APPLIED_COUPON_CODE,
    CLEAR_DATA_IN_OFFER_REDUCER,
    EXCEPTION_GENERATED_IN_OFFER_REDUCER,
} from "../constants";
import axios from "axios";
import { encrypt, hasJsonStructure } from "../../components/functions/cipherFunctions";

export function getOffers(serverPath, userId) {
    return (dispatch) => {
        //begin fetch data from api
        dispatch({
            type: BEGIN_ACTION_IN_OFFER_REDUCER,
        });

        axios
            .get(serverPath + "/get-offers", {
                params: {
                    userId: userId
                }
            })
            .then((res) => {
                dispatch({
                    type: SUCCESS_GET_OFFERS,
                    payload: encrypt(res.data, true)
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error.message;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_OFFER_REDUCER,
                    payload: {
                        message: message,
                        description:
                            "Something went wrong, Please try again after some time",
                    }
                });
            });
    };
}

export function applyCouponCode(couponData) {
    return (dispatch) => {
        dispatch({
            type: APPLY_COUPON_CODE,
            payload: encrypt(couponData, true)
        })
    }
}

export function removeAppliedCouponCode(couponData) {
    return (dispatch) => {
        dispatch({
            type: REMOVE_APPLIED_COUPON_CODE
        })
    }
}

export function clearOfferReducersData() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_DATA_IN_OFFER_REDUCER
        })
    }
}