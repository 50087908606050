import React from 'react'

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { clearProcessedOrder } from '../redux/actions/orderProcessActions';



export default function OrderPlacedSuccess() {

    const displatch = useDispatch();
    const history = useHistory();
    const processedOrder = useSelector((state) => state.orderProcessReducer.processedOrder);


    useEffect(() => {

        if (!processedOrder?.orderId)
            history.push('/order/history')

        return () => {
            displatch(clearProcessedOrder());
        }
        //eslint-disable-next-line
    }, [processedOrder])


    return (
        <div className="row justify-content-center">
            <div className="col-md-6 col-lg-6 card">
                <div className="order-success-wrapper card-body">
                    <div className="order-done-content"><svg width="60" height="60" viewBox="0 0 16 16" className="bi bi-check-circle-fill text-success mb-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                        <h5>Your order is confirmed!</h5>
                        <p>Your order number is <strong>{processedOrder?.orderNumber}</strong></p>
                        <p>Thank you for choosing us, Your order is being prepared and will be delivered to you shortly.</p>
                        <Link to={`/order/details/${processedOrder?.orderId}`} className="btn btn-primary">Order Details</Link>
                        <p className='mt-5'>If you have any questions about your order, please contact our customer support team at support@kcs.in.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


