import {
    getAuth,
    signInWithPhoneNumber,
    setPersistence,
    browserLocalPersistence,
} from "firebase/auth";

const sendOtp = async (phoneNumber) => {
    const auth = getAuth();
    const appVerifier = await window.recaptchaVerifier;
    const mobile = "+91" + phoneNumber;

    //reset ReCaptch so we can  send otp again in case of any exception or failure
    await window.recaptchaVerifier.render().then(function (widgetId) {
        window.recaptchaVerifier.recaptcha.reset(widgetId);
    });

    const isOtpSent = await setPersistence(auth, browserLocalPersistence)
        .then(() => {
            return signInWithPhoneNumber(auth, mobile, appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;

                    return true;
                })
                .catch((error) => {
                    return false;
                });
        })
        .catch((error) => {
            return false;
        });

    return isOtpSent;
};

export default sendOtp;
