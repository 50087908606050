import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCRmUCU0-EZ3E2qrLpgMf2Z8TWKM3ydB7Q",
    authDomain: "userkcs.firebaseapp.com",
    projectId: "userkcs",
    storageBucket: "userkcs.appspot.com",
    messagingSenderId: "849071752037",
    appId: "1:849071752037:web:e5c77260e3c37d56a4d78c",
    measurementId: "G-69Y9W9Q8NP"
};

export const app = initializeApp(firebaseConfig);
