import React from 'react'

export default function OrderStatusDetails({ orderNumber, orderDate, status, shippingType, addressType, toggleShowStatusHIstory, isShowStatusHistory }) {

    //split date and time
    const datetime = new Date(orderDate).toLocaleString('as-IN', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }).split(',');

    const date = datetime[0]?.replace(/\//gi, function (x) {
        return '-'
    });
    const time = datetime[1]?.replace(/pm|am/gi, function (x) {
        return x.toUpperCase();
    });

    switch (status) {
        case '1':
            status = 'Pending';
            break;

        case '2':
            status = 'Processing';
            break;

        case '3':
            status = 'Ready';
            break;

        case '4':
            status = 'Completed';
            break;

        case '0':
            status = 'Canceled';
            break;

        case '-1':
            status = 'Canceled';
            break;

        default:
            status = 'Unknown';
    }

    return (
        <div className='card'>
            <div className='card-header'>
                Order Details
            </div>
            <div className='p-3 pb-0'>
                <div className='row'>
                    <div className='col-6'>
                        <p className='lh-base'>
                            <span className='fw-bold'>Order Number: </span><br />
                            <span>{orderNumber}</span>
                        </p>
                        <p className='lh-base'>
                            <span className='fw-bold'>Date: </span>
                            <span>{date},<br />{time}</span>
                        </p>
                    </div>
                    <div className='col-6'>
                        <p className='lh-base'>
                            <span className='fw-bold'>Status: </span><br />
                            <span>{status}</span>
                        </p>
                        <p className='lh-base'>
                            <span className='fw-bold'>Shipping Type: </span><br />
                            <span>{shippingType === 'Shipping' ? `Deliver to ${addressType}` : shippingType}</span>
                        </p>
                    </div>
                </div>
            </div>
            <small className='p-3 pb-2 py-0 fw-bold kcs-text-color'>
                <span style={{ cursor: 'pointer' }} onClick={toggleShowStatusHIstory}>
                    {isShowStatusHistory ? 'Hide' : 'Show'} order process details <i className='fa fa-info-circle'></i></span>
            </small>
        </div>
    )
}
