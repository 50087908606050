import React from 'react'
import PaymentProcessingSkeleton from './PaymentProcessingSkeleton';

export default function PaymentDetails({ paymentDetails, paymentType, paymentStatus, noDetails, initiatePayment, paymentProcessing, orderStatus, rzpRefundId }) {

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const toggleErrorDetails = () => {
        window.$(".payment_error_details").toggleClass('hide');
    }

    if (paymentProcessing) {
        return <PaymentProcessingSkeleton />
    }

    return (
        <div className='card mt-1'>
            <div className='card-header'>
                Payment Details
            </div>
            <div className='p-3 pb-0 row'>
                {
                    noDetails ? <div className='text-center p-3'><h6 className='text-warning'>Your payment is pending</h6><h6><small className=''>If you already completed the payment then details will be display here soon, Otherwise click on below button to process the payment for your order</small></h6></div> :
                        <>
                            <div className='col-3'>
                                <p style={{ lineHeight: 1.6 }}>
                                    <span className='fw-bold'>Status </span><br />
                                    {
                                        /** If payment made using Razorpay then display payment details else show Payment method only  */
                                        paymentDetails ? <>
                                            {
                                                /**If refund id found */
                                                rzpRefundId && <><span className='fw-bold'>Refund ID</span><br /></>
                                            }
                                            <span className='fw-bold'>Pay ID </span><br />
                                            <span className='fw-bold'>Method </span><br />


                                            {/** if payment made using Wallet */}
                                            {paymentDetails?.wallet && <><span className='fw-bold'>Provider </span><br /></>}

                                            {/** if payment made using UPI */}
                                            {paymentDetails?.vpa && <><span className='fw-bold'>UPI ID </span><br /></>}


                                            {/** if payment made using Net Banking */}
                                            {paymentDetails?.bank && <><span className='fw-bold'>Bank </span><br /></>}


                                            {/** if payment made using Credit/Debit card */}
                                            {paymentDetails?.card_id &&
                                                <>
                                                    <span className='fw-bold'>Type </span><br />
                                                    {paymentDetails?.card?.name && <><span className='fw-bold'>Name</span><br /></>}
                                                    <span className='fw-bold'>Number</span><br />
                                                </>
                                            }
                                        </> : <span className='fw-bold'>Method : </span>
                                    }
                                </p>
                            </div>
                            <div className='col-9'>
                                <p style={{ lineHeight: 1.6 }}>
                                    <span onClick={paymentStatus === "Failed" ? () => toggleErrorDetails() : null} className={paymentStatus === "Failed" ? 'text-danger fw-bold payment_error' : paymentStatus === "Pending" ? 'text-warning fw-bold' : paymentStatus === "Refunded" ? "text-primary fw-bold" : 'text-success fw-bold'}>{paymentStatus}</span>
                                    {paymentStatus === "Failed" &&
                                        <span className='payment_error' onClick={() => toggleErrorDetails()}>
                                            <i className='fa fa-info-circle ms-1'></i> </span>
                                    }
                                    <br />
                                    {
                                        /** If payment made using Razorpay then display payment details else show Payment method only  */
                                        paymentDetails ? <>

                                            {
                                                /**If refund id found */
                                                rzpRefundId && <><span>{rzpRefundId}</span><br /></>
                                            }
                                            <span>{paymentDetails?.id}</span><br />

                                            {/** if payment made using UPI */}
                                            {paymentDetails?.wallet &&
                                                <>
                                                    <span>{paymentDetails?.method}</span><br />
                                                    <span>{paymentDetails?.wallet}</span><br />
                                                </>
                                            }
                                            {/** if payment made using UPI */}
                                            {paymentDetails?.vpa &&
                                                <>
                                                    <span>{paymentDetails?.method.toString().toUpperCase()}</span><br />
                                                    <span>{paymentDetails?.vpa}</span><br />
                                                </>
                                            }

                                            {/** if payment made using Net Banking */}
                                            {paymentDetails?.bank &&
                                                <>
                                                    <span>Net Banking</span><br />
                                                    <span>{paymentDetails?.bank}</span><br />
                                                </>
                                            }

                                            {/** if payment made using Credit/Debit card */}
                                            {paymentDetails?.card_id &&
                                                <>
                                                    <span>{capitalizeFirstLetter(paymentDetails?.card?.type)} {capitalizeFirstLetter(paymentDetails?.method)}</span><br />
                                                    <span>{paymentDetails?.card?.network}</span><br />
                                                    {paymentDetails?.card?.name && <><span>{paymentDetails?.card?.name}</span><br /></>}
                                                    <span>xxxx xxxx xxxx {paymentDetails?.card?.last4}</span><br />
                                                </>
                                            }

                                            {/* if no payment type found */}
                                            {!paymentDetails?.wallet && !paymentDetails?.vpa && !paymentDetails?.bank && !paymentDetails?.card_id && <span>{paymentDetails?.method.toString().toUpperCase()}</span>}

                                        </> : <span>{paymentType}</span>
                                    }
                                </p>
                            </div>

                            {paymentDetails?.error_description &&
                                <p style={{ lineHeight: 1.6 }}>
                                    <span className='payment_error_details hide'> {paymentDetails?.error_description}</span>
                                </p>
                            }
                        </>
                }
            </div>

            {parseInt(orderStatus) === 1 && paymentType === "Razorpay" && paymentStatus === "Failed" && <button className='btn btn-danger m-3 mt-0' onClick={(e) => initiatePayment(e)}>Revise Payment</button>}
            {parseInt(orderStatus) === 1 && paymentType === "Razorpay" && paymentStatus === "Pending" && <button className='btn btn-primary m-3 mt-0' onClick={(e) => initiatePayment(e)}>Complete Payment</button>}
        </div>
    )
}
