import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function AddressListSkeleton() {
    const skeleton = [];

    for (let i = 0; i < 8; i++) {
        skeleton.push(
            <div className="row mb-2" key={`address-skeleton-${i}`}>
                <div className="col-3">
                    <Skeleton height={65} />
                </div>

                <div className="col-9">
                    <Skeleton count={3} />
                </div>

            </div>
        )
    }

    return (
        <div className='container'>
            <SkeletonTheme highlightColor="#bababa">
                {skeleton}
            </SkeletonTheme>
        </div>
    )
}
