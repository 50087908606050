import {
    BEGIN_ACTION_IN_HOME_REDUCER,
    SUCCESS_GET_HOMEPAGE_DATA,
    EXCEPTION_GENERATED_IN_HOME_REDUCER,
    CLEAR_EXCEPTION_AND_LOADING_STATE,
} from "../constants";

const initialState = {
    loading: false,
    homePageData: [],
    exception: false,
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case BEGIN_ACTION_IN_HOME_REDUCER:
            return {
                ...state,
                loading: true,
                exception: false,
            };

        case SUCCESS_GET_HOMEPAGE_DATA:
            return {
                ...state,
                loading: false,
                exception: false,
                homePageData: action.payload,
            };

        case EXCEPTION_GENERATED_IN_HOME_REDUCER:
            return {
                ...state,
                loading: false,
                exception: action.payload,
            };

        case CLEAR_EXCEPTION_AND_LOADING_STATE:
            return {
                ...state,
                loading: false,
                exception: false,
            };

        default:
            return state;
    }
};

export default homeReducer;
