import React from "react";
import RouterComponent from "./components/Router";

import { ModalProvider } from "./context/modalContext";
import { TopLoadingBarProvider } from "./context/topLoadingBarContext";
import { ServerProvider } from "./context/serverContext";
import { AuthProvider } from "./context/authContext";
import { CartItemProvider } from "./context/cartItemContext";

function App() {
    return (
        <>
            <ServerProvider>
                <ModalProvider>
                    <AuthProvider>
                        <TopLoadingBarProvider>
                            <CartItemProvider>
                                <RouterComponent />
                            </CartItemProvider>
                        </TopLoadingBarProvider>
                    </AuthProvider>
                </ModalProvider>
            </ServerProvider>
        </>
    );
}

export default App;
