import React from 'react'

export default function ShippingAddress({ address }) {
    return (
        <div className='card mt-1'>
            <div className='card-header'>
                Shipping Address
            </div>
            <div className='p-3 pb-0'>
                <p className="lh-base">
                    <span><b>{address?.Name}</b></span> <br />
                    <span>{address?.FullAddress}</span><br />
                    <span>{address?.Landmark}</span><br />
                    <span className="text-muted font-weight-bold">{address?.PhoneNumber}</span><br />
                    {address?.Email && <span className="text-muted font-weight-bold">{address?.Email}</span>}
                </p>
            </div>
        </div>
    )
}