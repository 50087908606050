import React, { useContext, useEffect, useRef, useState } from 'react'
import { authContext } from '../../context/authContext'
import { serverContext } from '../../context/serverContext';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { hasJsonStructure } from '../functions/cipherFunctions';
import { modalContext } from '../../context/modalContext';

//skeleton component
const ProfileSkeleton = () => {
    return (
        <SkeletonTheme highlightColor="#bababa">
            <div className='text-center mt-4 ps-3 px-3'>
                <Skeleton height={35} count={3} className='mt-3' />
                <Skeleton height={40} className='mt-5' />
            </div>
        </SkeletonTheme>
    )
}

export default function UpdateProfile() {

    const { isAuthenticate, setIsAuthenticate } = useContext(authContext);
    const { serverPath } = useContext(serverContext);
    const { showErrorDialog, showSuccessDialog, showLoading, hideLoading } = useContext(modalContext);

    const history = useHistory();


    const renderCount = useRef(1);
    const emailDebounce = useRef(false);
    const phoneNumberDebounce = useRef(false);

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [errorLabel, setErrorLabel] = useState({
        username: '',
        email: '',
        phoneNumber: ''
    });

    //scroll to top when component loads 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    //set initials states when user is authenticated
    useEffect(() => {

        if (isAuthenticate === false)
            history.push('/login')

        setUsername(isAuthenticate?.username);
        setEmail(isAuthenticate?.email);
        setPhoneNumber(isAuthenticate?.phoneNumber);

        //scroll to top when component loads 
        window.scrollTo(0, 0);

        if (renderCount.current < 3)
            renderCount.current++;

        //eslint-disable-next-line
    }, [isAuthenticate]);

    //handle username change
    const handleUserName = (username) => {

        const isValid = /^[a-zA-Z ]+$/.test(username);

        if (username.length < 3)
            setErrorLabel({
                ...errorLabel,
                username: 'Username should be at least 3 character long'
            })
        else if (username.length > 30) {
            return;
        }
        else if (!isValid)
            setErrorLabel({
                ...errorLabel,
                username: 'Only Alphabets and space are allowed EX:(Joe Moren)'
            })
        else
            setErrorLabel({
                ...errorLabel,
                username: ''
            })

        //replcae multiple space with one
        username = username.replace(/  +/g, ' ');

        setUsername(username);
    }

    //handle email change
    const handleEmail = (e, email) => {

        //prevent email value change if user has logged in using Google or Facebook
        if (isAuthenticate?.loginWith !== 'Phone')
            return;

        e.preventDefault();

        const emailMask = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const isValidEmail = emailMask.test(email);

        if (email?.length === 0 || isValidEmail)
            setErrorLabel({
                ...errorLabel,
                email: ''
            });
        else
            setErrorLabel({
                ...errorLabel,
                email: 'Enter valid email address'
            });

        setEmail(email);
    }

    //handle phone number change
    const handlePhoneNumber = (e, phoneNumber) => {

        //prevent phoneNumber value change if user has logged in using phone number
        if (isAuthenticate?.loginWith === 'Phone')
            return;

        //check is entered phonr number is valid or not  
        const isValid = /^[0-9]+$/.test(phoneNumber)

        //return if entered value is not a numbers
        if (phoneNumber.length > 0 && !isValid)
            return;

        if (phoneNumber.length > 10)
            return;

        if (phoneNumber.length === 0)
            setErrorLabel({
                ...errorLabel,
                phoneNumber: ''
            });
        else if (phoneNumber.length < 10 || !isValid)
            setErrorLabel({
                ...errorLabel,
                phoneNumber: 'Enter valid phone number'
            });
        else
            setErrorLabel({
                ...errorLabel,
                phoneNumber: ''
            });

        setPhoneNumber(phoneNumber);
    }

    //validate entered email from server to check if its not already used with another account
    const ValidateEmailFromServer = (email) => {

        clearTimeout(emailDebounce.current);

        if (errorLabel.email.length < 1) {
            emailDebounce.current = setTimeout(() => {
                axios
                    .get(serverPath + '/checkMailExists', { params: { userId: isAuthenticate?.userId, email: email } })
                    .then(res => {
                        if (res.data.Exists === true) {
                            if (errorLabel.email.length < 1)
                                setErrorLabel({
                                    ...errorLabel,
                                    email: 'Email is already used with another account'
                                });
                        }
                    })
                    .catch(error => console.log(error))
            }, 500);
        }
    }

    //validate entered phone number from server to check if its not already used with an0ther account
    const ValidatePhoneNumberFromServer = (phoneNumber) => {

        clearTimeout(phoneNumberDebounce.current);

        if (errorLabel.phoneNumber.length < 1) {
            emailDebounce.current = setTimeout(() => {
                axios
                    .get(serverPath + '/checkPhoneExists', { params: { userId: isAuthenticate?.userId, phoneNumber: phoneNumber } })
                    .then(res => {
                        if (res.data.Exists === true) {
                            if (errorLabel.phoneNumber.length < 1)
                                setErrorLabel({
                                    ...errorLabel,
                                    phoneNumber: 'Number is already used with another account'
                                });
                        }
                    })
                    .catch(error => console.log(error))
            }, 500);
        }
    }

    //use effect to call validate email function on email state changes
    //we have added renderCounter to skip first 2 render for calling the function
    useEffect(() => {

        if (renderCount.current >= 2 && email?.length > 5)
            ValidateEmailFromServer(email);

        //eslint-disable-next-line
    }, [email])



    //use effect to call validate phone function on phoneNumber state changes
    //we have added renderCounter to skip first 2 render for calling the function
    useEffect(() => {

        if (renderCount.current >= 2 && phoneNumber?.length === 10)
            ValidatePhoneNumberFromServer(phoneNumber);

        //eslint-disable-next-line
    }, [phoneNumber])


    //form submit handler
    const handleSubmit = () => {

        showLoading();

        //prepare parameters
        const params = {
            userId: isAuthenticate?.userId,
            username: username,
            loginWith: isAuthenticate?.loginWith
        }

        //we can't change the default email or phone number that users has created account with so we
        //assign phone number in parameters only if user has created account with either Google or Facebook
        if (isAuthenticate?.loginWith !== 'Phone')
            params.phoneNumber = phoneNumber

        //assign email in parameters only if user has created account using phone number 
        if (isAuthenticate?.loginWith === 'Phone')
            params.email = email;

        //call API to update users information
        axios
            .post(serverPath + '/update-users', params)
            .then((res) => {

                hideLoading();
                showSuccessDialog("Account details updated", "Your account details are updated.");

                //set updated users data in authContext
                setIsAuthenticate({
                    ...isAuthenticate,
                    username: res.data.Username,
                    email: res.data.Email ? res.data.Email : '',
                    phoneNumber: res.data.PhoneNumber ? res.data.PhoneNumber : '',
                });
            })
            .catch((error) => {
                hideLoading();

                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Error ? errorText.Error : error.message;
                    description = errorText.Description ? errorText.Description : error.Description;
                } else message = error;

                showErrorDialog(message, description);
            });
    }

    return (
        <div className="page-content-wrapper">
            <div className="row justify-content-center">
                <div className="col-md-6 col-lg-6">
                    <div className="card user-profile-edit">
                        {
                            !isAuthenticate?.userId ? <ProfileSkeleton /> : <div className="card-body">
                                <div className='text-center px-4'>
                                    <img
                                        className=" mb-4 px-4 d-block illustrate"
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/bg-img/otp-illustration.png"
                                        }
                                        alt=""
                                    />
                                    <h6>Update your profile information</h6>
                                </div>
                                <div className='form-group'>
                                    <input className='form-control' placeholder='Username' value={username ? username : ''} onChange={(e) => handleUserName(e.target.value)} />
                                    {
                                        errorLabel.username &&
                                        <label className='form-lable text-danger'>
                                            <small>{errorLabel.username}</small>
                                        </label>
                                    }
                                </div>
                                <div className='form-group'>
                                    <input className='form-control' placeholder='Email' value={email ? email : ''} disabled={isAuthenticate?.loginWith !== 'Phone'} onChange={(e) => handleEmail(e, e.target.value)} />
                                    {
                                        errorLabel.email &&
                                        <label className='form-lable text-danger'>
                                            <small>{errorLabel.email}</small>
                                        </label>
                                    }
                                </div>
                                <div className='form-group'>
                                    <input className='form-control' placeholder='Phone number' value={phoneNumber ? phoneNumber : ''} disabled={isAuthenticate?.loginWith === 'Phone'} onChange={(e) => handlePhoneNumber(e, e.target.value)} />
                                    {
                                        errorLabel.phoneNumber &&
                                        <label className='form-lable text-danger'>
                                            <small>{errorLabel.phoneNumber}</small>
                                        </label>
                                    }
                                </div>
                                <button
                                    className="btn custom-btn-style w-100 mt-2"
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={!errorLabel.username && !errorLabel.email && !errorLabel.phoneNumber ? false : true}
                                >
                                    Save
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
