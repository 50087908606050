import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ProductCountHeader from "./ProductCountHeader";
import Skeleton from "./Skeleton";
import { cartItemContext } from "../../context/cartItemContext";
import { serverContext } from "../../context/serverContext";

function ProductItem({
    productList,
    totalNumOfProducts,
    loadedPageNo,
    fetchMoreProducts,
    isLoading,
}) {
    const uuid = require("uuid");
    const { addProductsToCart } = useContext(cartItemContext);
    const { serverImagePath } = useContext(serverContext);
    const imagePath = serverImagePath + "/products/";

    let products = "";
    let numOfDisplyedProducts = 0;

    if (Array.isArray(productList)) {
        products = productList.map((item) => {
            numOfDisplyedProducts++;
            return (
                <div
                    className="col-6 col-sm-4 col-lg-3"
                    key={`products-${item.ProductId}`}
                >
                    <div className="card single-product-card">
                        <div className="card-body p-3">
                            <Link
                                className="product-thumbnail d-block"
                                to={`/product/view/${item.ProductId}`}
                            >
                                <img src={`${imagePath}${item.ProductImage}`} alt="" />
                            </Link>
                            <Link
                                className="product-title d-block text-truncate"
                                to={`/product/view/${item.ProductId}`}
                            >
                                {item.ProductName}
                            </Link>
                            <p className="sale-price">₹ {item.Price}</p>
                            {parseInt(item.OutOfStoke) === 1 ? (
                                <button className="btn btn-danger btn-sm disabled">
                                    Out of Stock
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                        addProductsToCart({
                                            ItemId: uuid.v4(),
                                            ProductId: item.ProductId,
                                            ProductImage: `${imagePath}${item.ProductImage}`,
                                            ProductName: item.ProductName,
                                            ProductPrice: item.Price,
                                            PackSize:
                                                parseInt(item.PackType) === 1
                                                    ? "1 Kg."
                                                    : null,
                                            Qty: 1,
                                        })
                                    }
                                >
                                    Add to Cart
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            );
        });
    }

    if (numOfDisplyedProducts === 0) {
        products = (
            <>
                <div className="text-center">
                    <img
                        className="login-intro-img"
                        src={process.env.PUBLIC_URL + "/bg-img/emptyCart.png"}
                        alt=""
                    />
                    <h6 className="text-danger">No products found</h6>
                </div>
            </>
        );
    }

    return (
        <>
            {/* Product count component*/}
            <ProductCountHeader
                displayedProducts={numOfDisplyedProducts}
                totalProducts={totalNumOfProducts}
            />

            <div
                className="top-products-area"
                style={{ height: "80vh", overflow: "auto" }}
            >
                <div className="card">
                    <div className="card-body p-3">
                        {/* product container */}
                        <div className="row g-3 " style={{ minHeight: "78vh" }}>
                            {products}

                            {
                                //show skeleton when more products are loading
                                isLoading ? (
                                    <Skeleton loadWithoutProductArea={true} />
                                ) : null
                            }
                        </div>
                        {numOfDisplyedProducts !== totalNumOfProducts ? (
                            <div className="d-flex justify-content-center">
                                <button
                                    className="btn btn-sm mt-3 btn-outline-primary"
                                    type="button"
                                    value={loadedPageNo}
                                    onClick={(e) => {
                                        fetchMoreProducts(e.target.value);
                                    }}
                                >
                                    Load more
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductItem;
