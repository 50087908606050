import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function ProductSkeleton(props) {
    const numOfSkeletonArray = [1, 2, 3, 4, 5, 6];

    const skeleton = numOfSkeletonArray.map((obj) => {
        return (
            <div
                className="col-6 col-sm-4 col-lg-3"
                key={`products-skeleton-${obj}`}
            >
                <div className="card single-product-card">
                    <div className="card-body p-3">
                        <SkeletonTheme highlightColor="#bababa">
                            <span className="product-thumbnail d-block">
                                <Skeleton height={100} width={120} />
                            </span>
                            <span className="product-title d-block text-truncate">
                                <Skeleton width={80} />
                            </span>

                            <Skeleton width={50} />
                            <br />
                            <Skeleton width={120} height={20} />
                        </SkeletonTheme>
                    </div>
                </div>
            </div>
        );
    });

    //return only skeleton items when fetching more products runtime
    if (props.loadWithoutProductArea) return skeleton;
    else {
        return (
            <>
                <div className="top-products-area py-1">
                    <div className="card">
                        <div className="card-body p-3">
                            {/* product container */}
                            <div
                                className="row g-3 "
                                id="productContainer"
                                style={{ minHeight: "60vh" }}
                            >
                                {skeleton}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductSkeleton;
