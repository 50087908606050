import React from 'react'

export default function ConfirmDeleteAddressDialog({ message, addressToBeDelete, handleDeleteAddress }) {
    return (
        <div className="modal fade dialogbox" id="confirmDeleteDialog" data-bs-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-icon">
                        <i className="fa fa-trash text-center text-danger"></i>
                    </div>
                    <div className="modal-header">
                        <p className="modal-title">{message}</p>
                    </div>
                    <div className="modal-body">
                        <p id="confirmMessage">{addressToBeDelete?.fullAddress}</p>
                    </div>
                    <div className="modal-footer">
                        <div className="btn-inline">
                            <button className="btn pt-3 text-primary" data-bs-dismiss="modal">Cancle</button>
                            <button className="btn pt-3 text-danger" data-bs-dismiss="modal" onClick={() => handleDeleteAddress(addressToBeDelete?.addressId)}>DELETE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
