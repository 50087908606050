import React from "react";
function Loader() {
    return (
        <div
            className="d-flex align-items-center justify-content-center"
            id="ajaxLoader"
        >
            <div className="circle-loader bg-secondary">
                <div className="circle-big"></div>
            </div>
        </div>
    );
}

export default Loader;
