import {
    ADD_CREATED_REFUND_COUNT,
    BEGIN_ACTION_IN_REFUND_REDUCER,
    EXCEPTION_GENERATED_IN_REFUND_REDUCER,
    SUCCESS_GET_REDUNDS_DATA,
    SUCCESS_GET_LAST_REFUND_DATA,
    SUCCESS_GET_REDUND_DETAILS_DATA,
    CLEAR_DATA_IN_REFUND_REDUCER
} from '../constants';
import axios from 'axios'
import { encrypt, hasJsonStructure } from "../../components/functions/cipherFunctions";

export function getRefunds(serverPath, userId) {
    return ((dispatch) => {
        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_REFUND_REDUCER
        });

        //set initial page number as 1 to fetch order history data
        const pageNo = 1;

        axios
            .get(serverPath + "/get-refunds", { params: { userId: userId, pageNo: pageNo } })
            .then((res) => {

                let refunds = null;
                let countTotalRefunds = 0;

                if (res.data?.NoRefunds !== true) {

                    countTotalRefunds = res.data.CountTotalRefunds;
                    refunds = encrypt(res.data.RefundsData, true);
                }

                dispatch({
                    type: SUCCESS_GET_REDUNDS_DATA,
                    payload: {
                        refunds: refunds,
                        countTotalRefunds: countTotalRefunds,
                        fetchedPageNo: pageNo
                    }
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description = "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_REFUND_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    });
}

export function loadMoreRefunds(serverPath, userId, previousFetchedRefunds, fetchedRefundsPageNo = 1, countCreatedRefunds = 0) {
    return (dispatch) => {

        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_REFUND_REDUCER
        });

        axios
            .get(serverPath + "/get-refunds", { params: { userId: userId, pageNo: fetchedRefundsPageNo, numberOfRefundCreated: countCreatedRefunds } })
            .then((res) => {

                let refunds = null;
                let countTotalrefunds = 0;

                if (res.data?.NoRefunds !== true) {
                    countTotalrefunds = res.data.CountTotalRefunds;

                    //add nenw fetched refunds in to the previousFetchedRefunds
                    refunds = [...previousFetchedRefunds, ...res.data.RefundsData];

                    //encrypt data
                    refunds = encrypt(refunds, true);
                }

                dispatch({
                    type: SUCCESS_GET_REDUNDS_DATA,
                    payload: {
                        refunds: refunds,
                        countTotalRefunds: countTotalrefunds,
                        fetchedPageNo: fetchedRefundsPageNo
                    }
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description = "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_REFUND_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });

    }
}

export function getNewCreatedRefund(serverPath, userId, refundList) {
    return (dispatch) => {

        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_REFUND_REDUCER
        });

        axios
            .get(serverPath + "/get-last-refund", { params: { userId: userId } })
            .then((res) => {
                //function to add new fetched data at first position
                const prepend = (value, array) => {
                    var newArray = array.slice();
                    newArray.unshift(value);
                    return newArray;
                }

                let refunds = null;

                if (res.data?.Norefund !== true) {
                    //Add fetched order history data in refundList
                    refunds = refundList?.length > 0 ? prepend(res.data, refundList) : [res.data];

                    //encrypt data
                    refunds = encrypt(refunds, true);
                }

                dispatch({
                    type: SUCCESS_GET_LAST_REFUND_DATA,
                    payload: refunds
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description = "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_REFUND_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function addCreatedRefundCount() {
    return dispatch => {
        dispatch({
            type: ADD_CREATED_REFUND_COUNT
        });
    }
}

export function getRefundDetails(serverPath, params, previousFetchedStatusHistory) {
    return (dispatch) => {

        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_REFUND_REDUCER
        });

        axios
            .get(serverPath + "/get-refund-details", { params: params })
            .then((res) => {

                let refundDetails = null;

                if (res.data?.NoRefunds !== true) {
                    //add nenw fetched refund status history in to the previousFetchedStatusHistory
                    if (previousFetchedStatusHistory?.length)
                        refundDetails = [...previousFetchedStatusHistory, res.data];
                    else
                        refundDetails = [res.data];
                    
                    //encrypt data
                    refundDetails = encrypt(refundDetails, true);
                }

                dispatch({
                    type: SUCCESS_GET_REDUND_DETAILS_DATA,
                    payload: {
                        refundHistory: refundDetails
                    }
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description = "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_REFUND_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });

    }
}

export function clearRefundReducerData(){
    return (dispatch) => {
        dispatch({
            type: CLEAR_DATA_IN_REFUND_REDUCER
        })
    }
}