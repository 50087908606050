import {
    BEGIN_ACTION_IN_ADDRESS_REDUCER,
    SUCCESS_POST_USER_ADDRESS,
    SUCCESS_GET_USER_ADDRESSES,
    REDIRECT_TO_UPDATE_ADDRESS,
    SUCCESS_UPDATE_USER_ADDRESSES,
    SUCCESS_DELETE_USER_ADDRESSES,
    SUCCESS_GET_MAP_API_KEY,
    SUCCESS_SET_DEFAULT_ADDRESS,
    EXCEPTION_GENERATED_IN_ADDRESS_REDUCER,
    CLEAR_ADDRESS_TO_BE_UPDATE,
    CLEAR_DATA_IN_ADDRESS_REDUCER,
    CLEAR_ADDED_UPDATED_ADDRESS
} from "../constants";
import axios from "axios";
import { encrypt, hasJsonStructure } from "../../components/functions/cipherFunctions";

export function getUserAddress(serverPath, userId) {

    return (dispatch) => {
        //begin fetch data from api
        dispatch({
            type: BEGIN_ACTION_IN_ADDRESS_REDUCER,
        });

        axios
            .get(serverPath + "/get-user-address", {
                params: {
                    userId: userId
                }
            })
            .then((res) => {

                let responceData = '';

                //true if no saved address found
                if (res.data.Message)
                    responceData = null;
                else
                    responceData = encrypt(res.data.AddressList, true);

                dispatch({
                    type: SUCCESS_GET_USER_ADDRESSES,
                    payload: responceData,
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ADDRESS_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    };
}

export function postUserAddress(serverPath, params, addressList) {

    return (dispatch) => {
        //begin fetch data from api
        dispatch({
            type: BEGIN_ACTION_IN_ADDRESS_REDUCER,
        });

        axios
            .post(serverPath + "/post-user-address", params)
            .then((res) => {

                //if users addressList fetched from server then add new posted address in list
                const addPostedAddressInList = addressList?.length > 0 ? [...addressList, res.data.AddressData] : [];

                dispatch({
                    type: SUCCESS_POST_USER_ADDRESS,
                    payload: {
                        addressList: encrypt(addPostedAddressInList, true),
                        newAddress: res.data.AddressData
                    },
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ADDRESS_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    };
}

export function redirectToUpdateAddress(addressId) {

    return (dispatch) => {

        dispatch({
            type: REDIRECT_TO_UPDATE_ADDRESS,
            payload: { addressId: addressId }
        });
    }
}

export function clearAddressToBeUpdate() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ADDRESS_TO_BE_UPDATE,
        });
    }
}

export function updateUserAddress(serverPath, params, addressList) {
    return (dispatch) => {
        //begin fetch data from api
        dispatch({
            type: BEGIN_ACTION_IN_ADDRESS_REDUCER,
        });

        axios
            .put(serverPath + '/update-user-address', params)
            .then((res) => {

                //Replace updated address in list
                const updateAddressInList = addressList?.map(item => {
                    return parseInt(item.AddressId) === parseInt(params.addressId) ?
                        res.data.UpdatedAddressData : item;
                });

                const payloads = {
                    addressList: encrypt(updateAddressInList, true),
                    updatedAddress: res.data.UpdatedAddressData
                }

                dispatch({
                    type: SUCCESS_UPDATE_USER_ADDRESSES,
                    payload: payloads,
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ADDRESS_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function deleteAddress(serverPath, addressId, addressList) {
    return (dispatch) => {
        //begin fetch data from api
        dispatch({
            type: BEGIN_ACTION_IN_ADDRESS_REDUCER,
        });

        axios
            .delete(serverPath + "/delete-user-address", {
                data: {
                    addressId: addressId
                }
            })
            .then((res) => {

                //filter/remove deleted address from addressList
                const filterAddress = addressList.filter((item) => {
                    return item.AddressId !== addressId
                });

                dispatch({
                    type: SUCCESS_DELETE_USER_ADDRESSES,
                    payload: { addressList: encrypt(filterAddress, true) },
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ADDRESS_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

//set default address and udapte it in DB
export function setDefaultAddress(serverPath, params, addressList) {
    return (dispatch) => {
        //begin fetch data from api
        dispatch({
            type: BEGIN_ACTION_IN_ADDRESS_REDUCER,
        });

        axios
            .put(serverPath + '/set-default-address', params)
            .then((res) => {

                if (res.data.Success === true) {

                    //set default address in list
                    const setDefaultAddressInList = addressList?.map(item => {
                        return item.AddressId === params.addressId ?
                            { ...item, IsDefault: 1 } : { ...item, IsDefault: 0 };
                    });

                    const payloads = {
                        addressList: encrypt(setDefaultAddressInList, true)
                    }

                    dispatch({
                        type: SUCCESS_SET_DEFAULT_ADDRESS,
                        payload: payloads,
                    });
                }
            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ADDRESS_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function getMapApiKey(serverPath, addressMapType) {
    return (dispatch) => {
        //begin fetch data from api
        dispatch({
            type: BEGIN_ACTION_IN_ADDRESS_REDUCER,
        });

        let apiUrl = '';

        if (addressMapType === "googleMap")
            apiUrl = serverPath + '/get-google-api-key';
        else if (addressMapType === "hereMap")
            apiUrl = serverPath + '/get-here-api-key';

        axios
            .get(apiUrl)
            .then((res) => {
                if (res.data.Success === true) {

                    dispatch({
                        type: SUCCESS_GET_MAP_API_KEY,
                        payload: encrypt(res.data.Key),
                    });
                }

            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ADDRESS_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function clearAddedUpdatedAddressFromReducer() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ADDED_UPDATED_ADDRESS
        })
    }
}

export function clearAddressReducersData() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_DATA_IN_ADDRESS_REDUCER
        })
    }
}