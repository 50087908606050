import React from "react";

function ProductCountHeader(props) {
    return (
        <div
            className="card mb-1"
            style={{
                position: "sticky",
                top: 0,
            }}
        >
            <div className="card-body p-2">
                <div className="d-flex align-items-center justify-content-between">
                    <small className="ms-1">
                        Showing <span>{props.displayedProducts}</span> of
                        <span> {props.totalProducts}</span>
                    </small>
                </div>
            </div>
        </div>
    );
}

export default ProductCountHeader;
