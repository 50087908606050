import React from 'react'

export default function OrderAmount({ subTotal, discount, deliveryCharge, taxAndCharges, totalPayableAmount, testOrderAmount }) {
    

    return (
        <div className='card mt-1'>
            <div className='card-header'>
                Bill Details
            </div>
            <ul className="listview flush transparent image-listview text">
                <li>
                    <div className="item">
                        <div className="in">
                            <div>Subtotal</div>
                            {parseInt(testOrderAmount) > 0 ? <strike>₹ {subTotal}</strike> :
                                <span>₹ {subTotal}</span>}
                        </div>
                    </div>
                    {
                        discount > 0 && <div className='m-0'>
                            <span className='ms-3 text-success'>Discount</span>
                            <span className='text-success' style={{ float: 'right', marginRight: '15px' }}>- ₹{discount}</span>
                        </div>
                    }
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div>Delivery fee</div>
                            {parseInt(testOrderAmount) > 0 ? <strike>₹ {deliveryCharge}</strike> :
                                <span>₹ {deliveryCharge ? deliveryCharge : 0}</span>}
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div>Taxes and Charges<b className="mx-2 self-pickup-tooltip border custom-border ps-2 px-2" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="top" title="&lt;em&gt;Shop packaging and GST charges&lt;/em&gt;">?</b></div>
                            {parseInt(testOrderAmount) > 0 ? <strike>₹ {taxAndCharges}</strike> :
                                <span>₹ {taxAndCharges}</span>}
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div><b>Grand Total</b></div>
                            {parseInt(testOrderAmount) > 0 ? <strike><b>₹ {totalPayableAmount}</b></strike> :
                                <span><b>₹ {totalPayableAmount}</b></span>}
                        </div>
                    </div>
                </li>
                {parseInt(testOrderAmount) > 0 &&
                    <li>
                        <div className="item">
                            <div className="in">
                                <div><b>Test Order Amount</b></div>
                                <span><b>₹ {testOrderAmount}</b></span>
                            </div>
                        </div>
                    </li>}
            </ul>
        </div>
    )
}

