import React from 'react'
import { Link } from 'react-router-dom';

export default function Details({ details }) {

    if (!details?.OrderAmount)
        return <h1>No data</h1>

    const RenderState = ({ refundState }) => {
        switch (parseInt(refundState)) {
            case 0:
                return <span className="badge bg-danger ">Rejected</span>;
            case 1:
                return <span className="badge bg-info ">Pending</span>;
            case 2:
                return <span className="badge bg-info ">Accepted</span>;
            case 3:
                return <span className="badge bg-info ">Created in Razorpay</span>;
            case 4:
                return <span className="badge bg-success ">Completed</span>;
            case 5:
                return <span className="badge bg-warning ">On Hold</span>;
            default:
                return <span className="badge bg-danger ">Unknown</span>;
        }
    }

    return (
        <div className='card'>
            <div className='card-header'>
                Refund Details
            </div>
            <div className='p-3 pb-0'>

                <div className="mb-2">
                    <RenderState refundState={details?.State} />
                </div>

                <div className='row text-start mb-2'>
                    <h6><small>Issued on : </small><span className='ms-1'>{details.CreatedAt}</span></h6>
                    <h6><small>Order Amount : </small><span className='ms-1'>₹ {details.OrderAmount}</span></h6>
                </div>
                <div className='row'>
                    <div className='col-5 col-md-6 col-lg-6'>
                        <h6>Refund ID</h6>
                        <h6>Order ID</h6>
                    </div>
                    <div className='col-7 col-md-6 col-lg-6' style={{ textAlign: 'end' }}>
                        <h6>{details.RefundNumber}</h6>
                        <Link to={`/order/details/${details.OrderId}`}><h6 className='text-primary'>{details.OrderNumber}</h6></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
