import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export default function OrderSkeleton({ loadMore }) {
    return (
        <SkeletonTheme highlightColor="#bababa">
            <div className='m-3'>
                {loadMore ?
                    <Skeleton count={2} width={'100%'} height={160} className="mb-3" /> :
                    <Skeleton count={8} width={'100%'} height={160} className="mb-3" />}
            </div>

        </SkeletonTheme>
    )
}
