import React from "react";

function ErrorDialog() {
    return (
        <div
            className="modal fade dialogbox "
            id="errorDialog"
            data-bs-backdrop="static"
            tabIndex="-1"
            role="dialog"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-icon text-secondary">
                        <i className="fa fa-exclamation-triangle"></i>
                    </div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="errorDialogTitle">
                            Following error occured
                        </h5>
                    </div>
                    <div className="modal-body" id="errorDialogBodyText">
                        No data Found
                    </div>
                    <div className="modal-footer">
                        <div className="btn-inline">
                            <button
                                className="btn pt-3 text-primary"
                                data-bs-dismiss="modal"
                            >
                                CLOSE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorDialog;
