import React from "react";

const OrderItems = (props) => {

    let orderItems = '';

    if (props.productsInCart.length !== 0) {
        orderItems = props.productsInCart.map((item) => {
            return (
                <tr key={"order-item-" + item.ProductId + item.PackSize}>
                    <th scope="row">
                        <img src={item.ProductImage} alt="" />
                    </th>
                    <td>
                        <h6 className="mb-1">{item.ProductName}</h6>
                        {item.PackSize ? (
                            <>
                                <span>{item.PackSize}</span>
                                <br />
                            </>
                        ) : null}

                    </td>

                    <td>
                        <div className="quantity">
                            <button
                                className="cart-decrement-button"
                                onClick={() => {
                                    props.updateProductQuantityInCart(
                                        item.ItemId,
                                        parseInt(item.Qty) - 1,
                                        item.PackSize
                                    );
                                }}
                            >
                                <i className="fa fa-minus"></i>
                            </button>

                            <input
                                className="qty-text"
                                type="text"
                                value={item.Qty}
                                disabled={true}
                            />
                            <button
                                className="cart-increment-button "
                                onClick={() => {
                                    props.updateProductQuantityInCart(
                                        item.ItemId,
                                        parseInt(item.Qty) + 1,
                                        item.PackSize
                                    );
                                }}
                                disabled={item.Qty === 10 ? true : false}
                            >
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                    </td>
                    <th>
                        <h6 className="mb-1">₹ {parseInt(item.ProductPrice * item.Qty)}</h6>
                    </th>
                </tr>
            );
        });
    }

    return (
        <div className="cart-wrapper-area">
            <div className="cart-table card">
                <div className='card-header'>
                    {`${props.productsInCart.length} Items`}
                </div>
                <table className="table mb-0 text-center">
                    <tbody>{orderItems}</tbody>
                </table>
            </div>
        </div>
    );
}

export default OrderItems;