import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function CancelOrder({ orderStatus, showCancelOrderPopup, refundData }) {
    let content = '';

    if (parseInt(orderStatus) === 0 || parseInt(orderStatus) === -1) {
        if (refundData !== null) {
            content = <div className='card mt-1'>
                <div className='card-header fw-bold text-danger'>
                    Order canceled
                </div>
                <div className='p-3 pb-0'>
                    <h6>This order has been canceled either by you or service provider</h6>
                    <p>
                        The refund process has been initiated and refund ID <b>#{refundData.RefundNumber}</b> has been assigned for this order.
                    </p>
                    <Link to={`/refund/status/${refundData.RefundId}`} className='w-100 btn btn-primary mb-4'>Track Refund Status</Link>
                </div>
            </div>
        }
        else {
            content = <div className='card mt-1'>
                <div className='card-header fw-bold text-danger'>
                    Order canceled
                </div>
                <div className='p-3 pb-0'>
                    <h6>This order has been canceled either by you or service provider</h6>
                    <p>
                        If the order is eligible for refund, Your payment will be refunded within 5-7 days in the same account you used to pay for this order.
                    </p>
                    <Link to="/refund" className='w-100 btn btn-primary mb-4'>Check Refunds</Link>
                </div>
            </div>
        }
    } else
        content = <div className='card mt-1'>
            <div className='card-header fw-bold'>
                Change of mind ?
            </div>
            <div className='p-3 pb-0'>
                <h6>Cancel order effortlessly before it get processed</h6>
                <p>
                    We understand that plans can change unexpectedly.
                    That's why we offer you the flexibility to cancel your orders effortlessly before they're processed
                </p>
                <p>Click the below button to process order cancelation.</p>
                <button type='button' className='w-100 btn btn-danger mb-3' onClick={() => showCancelOrderPopup()}>Cancel order</button>
            </div>
        </div>

    return content;
}
