import React, { useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import TopLoadingBar from "react-top-loading-bar";

//import all components
import NotFound from "./NotFound";
import Header from "./header/Header";
import Footer from "./Footer";
import Home from "./home/Home";
import Cart from "./cart/Cart";
import Settings from "./Settings";
import Categories from "./Categories";
import Products from "./products/Products";
import ProductDetails from "./productDetails/ProductDetails";
import Login from "./login/Login";
import ConfirmOtp from "./login/ConfirmOtp";
import AddAddress from "./address/AddAddress";
import AddressList from "./address/AddressList";
import Checkout from "./checkout/Checkout"
import UpdateProfile from "./user/UpdateProfile";
import UpdateAddress from "./address/UpdateAddress";
import OrderPlacedSuccess from "./OrderPlacedSuccess";
import OrderHistory from "./orderHistory/OrderHistory";
import OrderDetails from "./orderDetails/OrderDetails";
import ManageAddress from "./address/ManageAddress";
import Refunds from "./refunds/Refunds";
import RefundDetails from "./refundDetails/RefundDetails";

//test component for draw route between store and users location
import AddressRoute from "./address/AddressRoute";


import { topLoadingBarContext } from "../context/topLoadingBarContext";
import { useSelector } from "react-redux";








function Router() {
    const { loadingProgress } = useContext(topLoadingBarContext);

    //get id of, address to be update from reducer
    const idOfAddressToBeUpdate = useSelector(state => state.addressReducer.addressIdToUpdateAddress);

    return (
        <>
            <BrowserRouter>
                <TopLoadingBar
                    height={3}
                    color={
                        localStorage.getItem("theme") === "dark"
                            ? "#ea4c62"
                            : "#0134d4"
                    }
                    waitingTime={500}
                    progress={loadingProgress}
                />

                <Header />
                <Switch>
                    <Route exact path="/address-route">
                        <AddressRoute />
                    </Route>
                    <Route exact path="/address/new">
                        <AddAddress />
                    </Route>
                    {
                        //enable this route only when id of address to be update is set
                        //we set this id when user click on Edit button in address/list component to update address
                        idOfAddressToBeUpdate &&
                        <Route exact path="/address/update/:component">
                            <UpdateAddress />
                        </Route>
                    }


                    <Route exact path="/user/update">
                        <UpdateProfile />
                    </Route>
                    <Route exact path="/user/address/:isParamPassed?">
                        <ManageAddress />
                    </Route>
                    <Route exact path="/order/history">
                        <OrderHistory />
                    </Route>
                    <Route exact path="/refund">
                        <Refunds />
                    </Route>
                    <Route exact path="/refund/status/:refundId">
                        <RefundDetails />
                    </Route>
                    <Route exact path="/order/confirmed/">
                        <OrderPlacedSuccess />
                    </Route>
                    <Route exact path="/order/details/:orderId">
                        <OrderDetails />
                    </Route>
                    <Route exact path="/address/list/:isParamPassed?">
                        <AddressList />
                    </Route>
                    <Route exact path="/checkout">
                        <Checkout />
                    </Route>
                    <Route exact path="/settings">
                        <Settings />
                    </Route>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route exact path="/confirm-otp">
                        <ConfirmOtp />
                    </Route>
                    <Route exact path="/categories">
                        <Categories />
                    </Route>
                    <Route exact path="/cart">
                        <Cart />
                    </Route>
                    <Route exact path="/product/view/:productId">
                        <ProductDetails />
                    </Route>
                    <Route exact path="/products/:categoryId">
                        <Products />
                    </Route>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    {/*display 404 page if no routes found*/}
                    <Route exact path="*">
                        <NotFound />
                    </Route>
                </Switch>
                <Footer />
            </BrowserRouter>
        </>
    );
}

export default Router;
