import React, { useState, useEffect, createContext, useContext } from "react";
import { topLoadingBarContext } from "./topLoadingBarContext";
import { modalContext } from "./modalContext";
import { encrypt, decrypt, isDecryptedDataValid } from "../components/functions/cipherFunctions";

export const cartItemContext = createContext("cartItemContext");

export const CartItemProvider = (props) => {

    const { loadingProgress, setLoadingProgress } = useContext(topLoadingBarContext);
    const { showErrorDialog } = useContext(modalContext);

    //get and decript cart products from localstorage which added by user
    const cartItemFromLocalStorage = localStorage.getItem("cartItems") && isDecryptedDataValid(decrypt(localStorage.getItem("cartItems"), true));
    const [productsInCart, setProductsInCart] = useState(
        cartItemFromLocalStorage ? cartItemFromLocalStorage : []
    );

    //store cart items in localstorage on every product add/edit action in cart
    useEffect(() => {
        //encrypt cart products and store in localstorage
        localStorage.setItem("cartItems", encrypt(productsInCart, true));
        //eslint-disable-next-line
    }, [productsInCart]);

/**  
     * @desc if cart data is modified manually by user in localstorage then display error warning on page reload
     * @note we are removing these data to prevent processing of any false information for order
     * @param {cartItemFromLocalStorage} will become false if data is modified
    */
    useEffect(() => {
        if (cartItemFromLocalStorage === false) {
            //we used setTimeout function to trigger error warning after 2 seconds when page load
            //because jquery takes some times to prepare whenever page reloads
            setTimeout(() => {
                try {
                    showErrorDialog("Your cart is reseted", "We have removed your cart items as we detect some unusual activities from your browser  ")
                } catch (error) {
                    console.log(error)
                }
            }, 2000)
        }
        //eslint-disable-next-line
    }, [])


    //function to add products in carts
    const addProductsToCart = (product) => {

        //check is requested product is already added to cart or not
        const isProductAlreadyAdded = productsInCart.filter((obj) => {
            return (
                parseInt(obj.ProductId) === parseInt(product.ProductId) &&
                obj.PackSize === product.PackSize
            );
        });

        //if requested product not added already into the cart then add that product 
        if (isProductAlreadyAdded.length === 0 && product.Qty) {

            //set top loading bar
            setLoadingProgress(30);

            setProductsInCart([...productsInCart, product]);

            //complete top loading bar after 0.5 seconds
            setTimeout(() => setLoadingProgress(100), 500);

            window.cartProccessed = false;
        }
    };

    //function to update product quantity in cart
    const updateProductQuantityInCart = (ItemId, quantity, packSize) => {
        //don't update quantity if it is  greater than 10
        if (parseInt(quantity) > 10) return false;

        //remove product from cart if requested quantity is less than 1 
        if (parseInt(quantity) < 1)
            return removeProductFromCart(ItemId)

        //set top loading bar
        setLoadingProgress(50);

        //update product quantity
        setProductsInCart(
            productsInCart.map((obj) => {
                return obj.ItemId === ItemId &&
                    obj.PackSize === packSize
                    ? {
                        ...obj,
                        Qty: quantity,
                    }
                    : obj;
            })
        );

        //complete top loading bar after 0.5 seconds
        setTimeout(() => setLoadingProgress(100), 500);
    };

    //function to remove products from cart
    const removeProductFromCart = (ItemId) => {

        //set top loading bar
        setLoadingProgress(70);

        setProductsInCart(productsInCart.filter((obj) => ItemId !== obj.ItemId));

        //complete top loading bar after 0.5 seconds
        setTimeout(() => setLoadingProgress(100), 500);
    };

    //clear all products from cart
    const clearCartItems = () => {
        setProductsInCart([])
    }

    return (
        <cartItemContext.Provider
            value={{
                productsInCart,
                addProductsToCart,
                updateProductQuantityInCart,
                removeProductFromCart,
                clearCartItems,
                loadingProgress,
                setLoadingProgress,
            }}
        >
            {props.children}
        </cartItemContext.Provider>
    );
};
