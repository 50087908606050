import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { modalContext } from "../../context/modalContext";
import { authContext } from "../../context/authContext";
import { getAuth, getRedirectResult } from "firebase/auth";

//initilize firbase configs
import "../functions/firebase/firebaseConfig";

import googleLoginWithRedirect from "../functions/firebase/loginWithGoogle";
import facebookLoginWithRedirect from "../functions/firebase/loginWithFacebook";
import recaptcha from "../functions/firebase/recaptcha";
import sendOtp from "../functions/firebase/sendOtp";

function Login() {
    const validPhone = useRef(false);

    const [errorLabelForPhone, setErrorLabelForPhone] = useState("");

    const { showErrorDialog, showLoading, hideLoading } =
        useContext(modalContext);
    const { isAuthenticate, phoneNumber, setPhoneNumber } =
        useContext(authContext);

    const history = useHistory();

    //firebase auth
    const auth = getAuth();

    //function to wait until users are authenticating when redirected back afetr login from Facebook/Google
    const waitUntilAuthenticate = async () => {
        //complete sign up process / remove it from session storage
        sessionStorage.removeItem("signUpProcessedWith");

        showLoading();

        await getRedirectResult(auth)
            .then(() => {
                return true;
            })
            .catch((error) => {
                showErrorDialog(
                    "Internal server error",
                    error.message
                );
            });
        hideLoading();
    };

    //scroll to top when component loads 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        //if user already loged in then redirect users to home page
        if (isAuthenticate) history.push("/");

        //call firebase function to create ReCaptcha to send otp
        recaptcha("recaptcha-container");

        //call function to show loading until we get authenticated users data
        //when user redirected back after login from facebook/google
        if (sessionStorage.getItem("signUpProcessedWith"))
            waitUntilAuthenticate();

        //eslint-disable-next-line
    }, [isAuthenticate]);
    
    //function to check entered phone number is valid or not
    const isValidPhoneNumber = (phoneNumber) => {

        //check is entered phonr number is valid or not  
        const isValid = /^[0-9]+$/.test(phoneNumber)

        //return if entered value is not a numbers
        if (phoneNumber.length > 0 && !isValid)
            return;

        if (phoneNumber.length > 10)
            return;

        if (phoneNumber.length === 0)
            setErrorLabelForPhone('');
        else if (phoneNumber.length < 10 || !isValid)
            setErrorLabelForPhone('Enter valid phone number');
        else
            setErrorLabelForPhone('');

        setPhoneNumber(phoneNumber);
        validPhone.current = true;
    };

    //function to handle login from facebook/google
    const handleFacebookAndGoogleLogin = (loginProvider) => {
        //if user is offline then show warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return;
        }

        //call provider function to login
        loginProvider();
    };

    //function to handle submitiomn of phone number to send otp
    const submitHandler = async () => {
        //if user is offline then show warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return;
        }

        if (validPhone.current === true) {
            showLoading();

            //call firebase function to send otp
            const isOtpSent = await sendOtp(phoneNumber);

            hideLoading();

            if (isOtpSent === true) history.push("/confirm-otp");
            else {
                showErrorDialog(
                    "OTP does not sent",
                    "Something went wrong, <br />Please make sure you have entered valid phone number and then try again"
                );

                setPhoneNumber("");
            }
        }
        else {
            showErrorDialog(
                "Invalid phone number",
                "Please enter valid phone number and try again"
            );
        }
    };

    return (
        <div className="page-content-wrapper">
            <div className=" card" style={{ height: "90vh" }}>
                <div className="row justify-content-center card-body login-card">
                    <div className="col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5">
                        <div id="recaptcha-container"></div>

                        <div className="text-center px-4">
                            <button
                                className="btn google-login-btn w-100 py-2 mt-3"
                                onClick={() => {
                                    handleFacebookAndGoogleLogin(
                                        googleLoginWithRedirect
                                    );
                                }}
                            >
                                <svg
                                    style={{ marginRight: "5px", }} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                                    <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path>
                                    <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path>
                                    <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path>
                                    <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
                                </svg>
                                Login in with Google
                            </button>
                            <button
                                className="btn btn-outline-primary w-100 py-2 mt-3"
                                onClick={() => {
                                    handleFacebookAndGoogleLogin(
                                        facebookLoginWithRedirect
                                    );
                                }}
                            >
                                <svg
                                    style={{ marginRight: "5px", }} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                                    <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path>
                                    <path fill="#fff" d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"></path>
                                </svg>

                                Login in with Facebook
                            </button>
                            <div className=" d-block justify-content-center py-3">
                                <strong>or</strong>
                            </div>
                            <h3>Phone Verification</h3>
                            <p className="mb-2">
                                We will send you an OTP on this phone number.
                            </p>
                        </div>
                        <div className="otp-form mt-3 px-4">
                            <div className="form-group mb-2">
                                <input
                                    className="form-control"
                                    type="tel"
                                    value={phoneNumber}
                                    placeholder="9999999999"
                                    maxLength="10"
                                    minLength="10"
                                    onChange={(e) =>
                                        isValidPhoneNumber(e.target.value)
                                    }
                                />
                                <label className="text-danger form-text mt-1 ms-1">
                                    {errorLabelForPhone}
                                </label>
                            </div>

                            <button
                                className="btn custom-btn-style w-100"
                                type="button"
                                onClick={submitHandler}
                                disabled={
                                    !/^[0-9]+$/.test(phoneNumber) ||
                                        phoneNumber.length < 10
                                        ? true
                                        : false
                                }
                            >
                                Send Now
                            </button>
                        </div>
                        <div className="login-meta-data px-4 text-center">
                            <p className="mt-3 mb-2">
                                By providing my phone number, I hereby agree the
                                <Link
                                    className="mx-1 stretched-link"
                                    to="page-privacy-policy.html"
                                >
                                    Term of Services
                                </Link>
                                and{" "}
                                <Link
                                    className="mx-1 stretched-link"
                                    to="page-privacy-policy.html"
                                >
                                    Privacy Policy.
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
