import React from 'react'

export default function OrderNote({ orderNote }) {
    return (
        <div className='card mt-1'>
            <div className='card-header'>
                Order Note
            </div>
            <div className='p-3 pb-0'>
                <p className="lh-base">
                    {orderNote}
                </p>
            </div>
        </div>
    )
}