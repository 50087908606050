import React from "react";

function Carousel(props) {
    const imageUrl = (url) => {
        const path = process.env.PUBLIC_URL + url;

        return path;
    };
    return (
        <>
            <div
                className="carousel slide"
                id="bootstrapCarousel"
                data-bs-ride="carousel"
            >
                {/* Carousel Indicators*/}
                <ol className="carousel-indicators">
                    <li
                        className="active"
                        data-bs-target="#bootstrapCarousel"
                        data-bs-slide-to="0"
                    ></li>
                    <li
                        data-bs-target="#bootstrapCarousel"
                        data-bs-slide-to="1"
                    ></li>
                    <li
                        data-bs-target="#bootstrapCarousel"
                        data-bs-slide-to="2"
                    ></li>
                    <li
                        data-bs-target="#bootstrapCarousel"
                        data-bs-slide-to="3"
                    ></li>
                </ol>
                {/* Carousel Inner*/}
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img
                            className="d-block w-100"
                            src={imageUrl("bg-img/31.jpg")}
                            alt=""
                        />
                        <div className="carousel-caption">
                            <h3>first Slider</h3>
                            <p>Slider cation detaisl of First</p>
                            <button className="btn btn-warning" type="button">
                                Order now
                            </button>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img
                            className="d-block w-100"
                            src={imageUrl("bg-img/32.jpg")}
                            alt=""
                        />
                        <div className="carousel-caption">
                            <h3>Second Slider</h3>
                            <p>Slider cation detaisl of Seconds</p>
                            <button className="btn btn-warning" typr="button">
                                Order now
                            </button>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img
                            className="d-block w-100"
                            src={imageUrl("bg-img/33.jpg")}
                            alt=""
                        />
                        <div className="carousel-caption">
                            <h3>Third Slider</h3>
                            <p>Slider cation detaisl of Thirds</p>
                            <button className="btn btn-warning" typr="button">
                                Order now
                            </button>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img
                            className="d-block w-100"
                            src={imageUrl("bg-img/21.jpg")}
                            alt=""
                        />
                        <div className="carousel-caption">
                            <h3>Fourth Slider</h3>
                            <p>Slider cation detaisl of Fourth</p>
                            <button className="btn btn-warning" typr="button">
                                Order now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Carousel;
