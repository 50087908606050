import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { authContext } from '../../context/authContext';
import { cartItemContext } from '../../context/cartItemContext';
import { serverContext } from '../../context/serverContext';
import { modalContext } from '../../context/modalContext';
import { applyCouponCode, getOffers, removeAppliedCouponCode } from '../../redux/actions/offerActions';
import { calculateRouteDistance, setOrderData, setSelectedAddress } from '../../redux/actions/orderProcessActions';
import { decrypt, hasJsonStructure, isDecryptedDataValid } from '../functions/cipherFunctions';
import { getDeliveryMethods } from '../../redux/actions/orderDataActions';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

//import components
import OrderItems from './OrderItems';
import ApplyRemoveCoupon from './ApplyRemoveCoupon';
import OrderAmount from './OrderAmount';
import CouponModal from './CouponModal';
import SelectedAddress from './SelectedAddress';
import ShippingType from './ShippingType';
import GetPhoneNumber from './GetPhoneNumber';
import EmptyCart from './EmptyCart';
import { useRef } from 'react';



function Cart() {

    const dispatch = useDispatch();
    const history = useHistory();

    //get contexts 
    const { serverPath } = useContext(serverContext);
    const { isAuthenticate } = useContext(authContext);
    const {
        showLoading,
        hideLoading,
        showErrorDialog,
        showSuccessDialog
    } = useContext(modalContext);
    const {
        productsInCart,
        updateProductQuantityInCart,
    } = useContext(cartItemContext);

    //get and decrypt applied coupon from reducer if available
    const couponFromReducer = useSelector(state => state.offerReducer.appliedCouponsData);
    const [appliedCoupon, setAppliedCoupon] = useState(
        couponFromReducer && isDecryptedDataValid(decrypt(couponFromReducer, true))
    );

    //get and decrypt order data from reducer
    const getOrderData = useSelector(state => state.orderProcessReducer.orderData);
    const orderData = getOrderData && isDecryptedDataValid(decrypt(getOrderData, true));

    //get and decrypt offers from reducer
    const offersFromReducer = useSelector(state => state.offerReducer.offers);
    const offers = offersFromReducer && isDecryptedDataValid(decrypt(offersFromReducer, true));

    //get and decrypt selected address from reducer
    const getSelectedAddressFromReducer = useSelector(state => state.orderProcessReducer.selectedAddress);
    const selectedAddress = getSelectedAddressFromReducer && isDecryptedDataValid(decrypt(getSelectedAddressFromReducer, true));

    //get and decrypt available delivery methods from reducer
    const getdeliveryMethodsData = useSelector(state => state.orderDataReducer.deliveryMethods);
    const deliveryMethods = getdeliveryMethodsData && isDecryptedDataValid(decrypt(getdeliveryMethodsData, true));

    //get and decrypt shipping configurations from reducer
    const getShippingConfigsData = useSelector(state => state.orderDataReducer.shippingConfigs);
    const shippingConfigs = getShippingConfigsData && isDecryptedDataValid(decrypt(getShippingConfigsData, true));

    //store location
    const storeCordinates = shippingConfigs?.storeLocation ? JSON.parse(shippingConfigs?.storeLocation) : null;

    const [shippingType, setShippingType] = useState(orderData?.shippingType ? orderData?.shippingType : 0);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errorLabel, setErrorLabel] = useState('');

    //state for check all the required data is valid for order
    const [isValidOrderData, setIsValidOrderData] = useState(false);

    //diclare state for order summery
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [deliveryCharge, setDeliveryCharge] = useState('');
    const [taxAndCharge, setTaxAndCharge] = useState(0);
    const [totalPayableAmount, setTotalPayableAmount] = useState(0);
    const [isFreeShipping, setIsFreeShipping] = useState(false);

    const isQtyUpdated = useRef(false);
    const isCouponCodeStatusChnaged = useRef(false);

    const loading = useSelector(state => state.offerReducer.loading);
    const exception = useSelector(state => state.offerReducer.exception);

    //scroll to top when component loads 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    //set default selected address if its no any selected address found
    useEffect(() => {

        //cehck if address is not already selected and default address is found
        if (!selectedAddress?.AddressId && isAuthenticate?.defaultAddress) {

            //set selected address
            dispatch(setSelectedAddress(isAuthenticate.defaultAddress));

            //set origin as store locations
            const origin = {
                lat: storeCordinates.lat,
                lng: storeCordinates.lng
            }

            //set destination as customers address location
            const destination = {
                lat: isAuthenticate.defaultAddress.Latitude,
                lng: isAuthenticate.defaultAddress.Longitude
            }

            //prepare api params
            const params = {
                fromCords: origin,
                toCords: destination,
                addressId: selectedAddress?.AddressId
            };

            //get type of distance calculation API to be used
            const distanceCalculationApi = shippingConfigs?.distanceCalculationApi;

            //call action to calculate the route distance
            dispatch(calculateRouteDistance(serverPath, params, distanceCalculationApi, isAuthenticate.defaultAddress));

        }

        //eslint-disable-next-line
    }, [isAuthenticate]);



    //fetch available delivery methods from server if already not fetched
    useEffect(() => {
        if (!deliveryMethods) {
            dispatch(getDeliveryMethods(serverPath));
        }

        //eslint-disable-next-line
    }, [])

    //set users default phone number if found to use for store pickup order
    useEffect(() => {
        if (isAuthenticate?.phoneNumber)
            setPhoneNumber(isAuthenticate?.phoneNumber);
    }, [isAuthenticate])


    //calculate and update subTotal amount everytime when product quantity updates
    useEffect(() => {
        calculateSubTotal();
        //eslint-disable-next-line
    }, [productsInCart]);

    //update selected shipping method in orderData object
    useEffect(() => {
        saveOrderData();
        //eslint-disable-next-line
    }, [shippingType]);

    //calculate and update totalPayableAmount everytime when discount,deliveryCharge or subTotal states are updates
    useEffect(() => {
        setTotalPayableAmount(Math.ceil((subTotal - discount) + deliveryCharge));
        setTaxAndCharge(0);
    }, [discount, deliveryCharge, subTotal])

    //update discount amount when subTotal state is updates if the coupon is applied and discount amount
    //is not reached to max discount limit.
    //the effect will be triggered when customer will update the ordered products quantity.
    //EX: if coupon is applied and customer get discount of "₹19" and the max discount for applied coupon is "₹30"
    //so this will updates the discounted amount till "₹30".
    useEffect(() => {
        if (appliedCoupon) {
            calculateDiscount(appliedCoupon)
        }
        //eslint-disable-next-line
    }, [subTotal])


    //set/update order data in reducer when user update product quantities in cart or apply or remove coupon code
    useEffect(() => {
        if (isQtyUpdated.current || isCouponCodeStatusChnaged.current)
            saveOrderData();

        //eslint-disable-next-line
    }, [totalPayableAmount])


    //moniter shipping type, phoneNumber or deliveryCharge to check all required order data is valid
    useEffect(() => {
        if ((shippingType === 1 && deliveryCharge > 0) || isFreeShipping === true)
            setIsValidOrderData(true);
        else if (shippingType === 2 && selectedAddress.AddressId && !errorLabel && phoneNumber.length === 10)
            setIsValidOrderData(true);
        else
            setIsValidOrderData(false);

        //if delivery type is self pickup than make delivery charge 0
        if (shippingType === 2)
            setDeliveryCharge(0)

        //eslint-disable-next-line
    }, [shippingType, phoneNumber, deliveryCharge, isFreeShipping])


    //moniter selectedAddress to calculate delivery charge based on selected address
    //everytime when customer change selectedAddress for order
    useEffect(() => {
        selectedAddress && shippingType === 1 && calculateDeliveryCharge((selectedAddress?.RouteLength / 1000).toFixed(1));
        //eslint-disable-next-line
    }, [selectedAddress])



    //method to calculate sub total amount
    const calculateSubTotal = () => {
        //calculate sub total based on products in cart
        const total = productsInCart?.length !== 0
            ? productsInCart
                .map((item) => parseInt(item.ProductPrice) * item.Qty) //return array of product totals
                .reduce((a, b) => a + b) // return sum of all products total
            : 0;

        setSubTotal(total);
    }

    //method to get and set shipping type, we passed this method to ShippingType component
    const handleShippingTypeChange = (type) => {
        setShippingType(parseInt(type));
    }

    //get and set phone number from user input, we passed this method to GetPhoneNumber component
    //The phone number is required for self pickup order
    const handleChangePhoneNumber = phoneNumber => {

        //check is entered phonr number is valid or not  
        const isValid = /^[0-9]+$/.test(phoneNumber)

        //return if entered value is not a numbers
        if (phoneNumber.length > 0 && !isValid)
            return;

        if (phoneNumber.length > 10)
            return;

        if (phoneNumber.length === 0)
            setErrorLabel('Phone number is Required');
        else if (phoneNumber.length < 10 || !isValid)
            setErrorLabel('Enter valid phone number');
        else
            setErrorLabel('');

        setPhoneNumber(phoneNumber);
    }



    //method to calculate delivery charge based on distance
    const calculateDeliveryCharge = (distance) => {

        if (distance > parseInt(shippingConfigs?.deliveryRange)) {
            showErrorDialog('Address is out of delivery zone', 'The address you selected is out of our store delivery zone, please select another address');
            setIsValidOrderData(false);
        }

        setIsFreeShipping(false);

        //allow free shipping
        if (shippingConfigs?.allowFreeShipping === true) {
            if (subTotal > shippingConfigs?.freeShipping.freeShippingMinimumOrderAmount) {
                setIsFreeShipping(true);
                return setDeliveryCharge(0)
            }
        }

        //use fixed shipping cost for order having less order amount
        if (shippingConfigs?.shippingApplyFixedCost === true) {

            if (subTotal <= shippingConfigs?.fixedShipping.orderAmount && distance < shippingConfigs?.fixedShipping.addressDistance) {
                return setDeliveryCharge(parseInt(shippingConfigs?.fixedShipping.fixedShippingCost));
            }
        }

        //use km range for price
        if (shippingConfigs?.shippingUseKmRange === true) {
            for (let condition of shippingConfigs?.useKmRange.priceConditions) {
                if (distance >= condition.minKm && distance <= condition.maxKm) {
                    return setDeliveryCharge(parseInt(condition.price));
                }
            }
        }

        return setDeliveryCharge(Math.ceil(distance * shippingConfigs?.shippingCostPerKm))
    }

    //method to show coupon modal, we passed this function to ApplyRemoveCoupon component
    const showCouponModal = () => {

        //return if user is not logged in yet
        if (!isAuthenticate?.userId)
            return showErrorDialog("Can't apply coupon code", "You need to login first to apply coupon codes");

        //dispatch action to get offers from server if not already fetched
        if (isAuthenticate?.userId && !offers.length)
            dispatch(getOffers(serverPath, isAuthenticate.userId));

        window.$("#applyCouponModal").modal("show");
    }

    //method to calculate discount based on coupon
    const calculateDiscount = (coupon, showSuccessMessage = false) => {

        //calculate time difference between applied coupons and current time
        const isTimePassedMoreThan5Minutes = (timeOfCouponApplied) => {
            const currentTime = new Date().getTime();
            const timeDiff = currentTime - timeOfCouponApplied;

            //if time of coupon applied is greater than 5 minutes then remove applied coupon
            //EX: if coupon applied on 08:25:00 PM and current time is 08:32:00 
            return (timeDiff / 1000 / 60) > 5 ? true : false;
        }

        //remove applied coupon if user don't complete checkout process within 5 minutes
        if (isTimePassedMoreThan5Minutes(coupon.timeOfApplied)) {
            setDiscount(0);
            setAppliedCoupon(false);
            //dispatch action to remove applied coupons data from reducer
            return dispatch(removeAppliedCouponCode());
        }

        //calculate discount amount based on applied coupon code
        let discountedAmount = (subTotal / 100) * coupon.DiscountPer;

        //if calculated discountedAmount is greater than max allowed discount for applied coupon
        //then make discountedAmount as max allowed discount amount.
        //EX : if calculated discount is "₹45" and max allowed discount is "₹40" then make discount ammount as "₹40".
        if (discountedAmount > coupon.MaxDiscount)
            discountedAmount = coupon.MaxDiscount;

        //set amount round down, EX : if amount is 35.9,32.2, ETC. than amount will be 35, 32 ETC.
        if (discountedAmount % 2 !== 0)
            discountedAmount = Math.floor(discountedAmount);

        //show success dialog modal
        if (showSuccessMessage)
            showSuccessDialog(
                `${coupon.Code} <br />coupon is applied`,
                `Congratulation you have saved </br><b>₹  ${discountedAmount}</b> on this order`
            );

        //update states
        setDiscount(discountedAmount);
        setAppliedCoupon(coupon);

        //dispatch action to store applied coupons data in reducer
        dispatch(applyCouponCode(coupon))
    }

    //method to apply coupon code, we passed this function to CouponModal Component
    const applyCoupon = async (couponCode) => {

        //return if user is not logged in yet
        if (!isAuthenticate?.userId)
            return;

        window.$("#applyCouponModal").modal("hide");

        const params = {
            userId: isAuthenticate?.userId,
            couponCode: couponCode
        }

        //show loading
        showLoading()

        //fetch entered coupons data from server
        const response = await axios
            .post(serverPath + "/get-offers-data-by-coupon", params)
            .then((res) => res.data)
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error.message;

                return {
                    error: {
                        message: message,
                        discription: "Something went wrong, Please try again after some time"
                    }
                }
            });

        //hide loading
        hideLoading();

        //return if server returns errors
        if (response.error)
            return showErrorDialog(response.error.message, response.error.discription);

        //return if entered coupon code is not valid
        if (response.Message)
            return showErrorDialog(
                response.Message,
                "The coupon code that you have entered is either expired or you are not allowed to use this coupon anymore."
            );

        isCouponCodeStatusChnaged.current = true;

        //set current time to coupon
        response.timeOfApplied = new Date().getTime();

        const showSuccessMessage = true;
        return calculateDiscount(response, showSuccessMessage);
    }

    //method to remove applied coupon code,we passed this function to ApplyRemoveCoupon Component
    const removeAppliedCoupon = () => {
        //dispatch action to remove applied coupons data from reducer
        dispatch(removeAppliedCouponCode());

        //update states
        setDiscount(0);
        setAppliedCoupon(false);

        isCouponCodeStatusChnaged.current = true;
    }

    //method to save order data in reducer
    const saveOrderData = () => {
        const orderData = {
            //ordered products id,packSize and quantity
            orderProducts: productsInCart.map(item => {
                return {
                    ProductId: item.ProductId,
                    Quantity: item.Qty,
                    PackSize: item.PackSize
                }
            }),
            phoneNumber: phoneNumber,
            addressId: selectedAddress.AddressId,
            appliedCouponCode: appliedCoupon.Code ? appliedCoupon.Code : null,
            orderAmount: {
                subTotal: subTotal,
                discount: discount,
                deliveryCharge: deliveryCharge,
                taxAndCharge: taxAndCharge,
                total: totalPayableAmount
            },
            shippingType: shippingType,
            userId: isAuthenticate?.userId
        };

        dispatch(setOrderData(orderData));

        isQtyUpdated.current = false;
        isCouponCodeStatusChnaged.current = false;
    }

    //Save order data in reducer and redirect to checkout component for payment process
    const saveOrderDataAndRedirectToCheckout = () => {

        //if user is not logged in then redirect it to login page
        if (isAuthenticate === false)
            return history.push('/login');

        //call saveOrderData action to save data in observer
        saveOrderData();

        //set global variable to proceed to checkout steps, we used this variable 
        //in checkout comonent to redirect back to cart component if cart data is not processed
        window.cartProccessed = true;

        history.push('/checkout');
    }

    //update product quantity in cart
    const updateItemQuantityAndSaveOrderData = (itemId, qty, packSize) => {

        //update product qty
        updateProductQuantityInCart(itemId, qty, packSize);

        //set isQty update flag true to update order data in reducer
        isQtyUpdated.current = true;

    }

    //show exception if it generated in reducer
    if (exception) {
        showErrorDialog(exception.message, exception.description);

        dispatch({
            type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
        });
    }


    return productsInCart?.length === 0 ? <EmptyCart /> : (
        <>
            <div className="row justify-content-center">
                <div className="col-md-6 col-lg-6">
                    <CouponModal offers={offers} isLoading={loading} applyCouponCode={applyCoupon} />
                    <div className="page-content-wrapper">
                        <OrderItems
                            productsInCart={productsInCart}
                            updateProductQuantityInCart={
                                updateItemQuantityAndSaveOrderData
                            }
                        />
                        <ApplyRemoveCoupon appliedCoupon={appliedCoupon} showCouponModal={showCouponModal} removeAppliedCoupon={removeAppliedCoupon} />
                        <OrderAmount subTotal={subTotal} discount={discount} deliveryCharge={deliveryCharge} taxAndCharge={taxAndCharge} totalPayableAmount={totalPayableAmount} shippingType={shippingType} testOrderAmount={isAuthenticate?.isTestUser?.TestOrderAmount} showAddressKm={shippingConfigs?.showAddressKm} />
                        <ShippingType deliveryMethods={deliveryMethods?.methods} handleShippingTypeChange={handleShippingTypeChange} selectedShippingType={orderData?.shippingType} />
                        <SelectedAddress address={selectedAddress} showEstimateTime={shippingConfigs?.showEstimateTimeOfDelivery} shippingType={shippingType} />
                        {
                            shippingType === 2 && <GetPhoneNumber phoneNumber={phoneNumber} handleChangePhoneNumber={handleChangePhoneNumber} errorLabel={errorLabel} />
                        }

                        <div className='card'>
                            <div className='card-body'>
                                {isAuthenticate?.isTestUser && <p className='text-danger text-center fw-bold '>You are placing an test order with ₹{isAuthenticate?.isTestUser?.TestOrderAmount}</p>}
                                {(selectedAddress?.RouteLength / 1000).toFixed(1) > parseInt(shippingConfigs?.deliveryRange) && <p className='text-danger text-center fw-bold '>Address is out of delivery zone</p>}
                                <button className='btn custom-btn-style w-100' onClick={saveOrderDataAndRedirectToCheckout} disabled={isValidOrderData ? false : true}>Continue</button>
                            </div>
                        </div>
                    </div >
                </div>
            </div>
        </>
    )
}

export default Cart;