import CryptoJs from "crypto-js";

const passfers = 'uirafndsj*&%fs@f5na85dsjvbad534';

/**
* @desc function to check is the data in json format
* @param {str} any
* @return {bool} true on success
*/
export const hasJsonStructure = (str) => {
    if (typeof str !== 'string') return false;
    try {
        const result = JSON.parse(str);
        const type = Object.prototype.toString.call(result);
        return type === '[object Object]'
            || type === '[object Array]';
    } catch (err) {
        return false;
    }
}

/**
 * @desc function to check data is decrypted properly or not
 * @param {decryptedData} json
 * @returns {bool} true on succes
 */
export const isDecryptedDataValid = (decryptedData) => !decryptedData || decryptedData.invalid === true ? false : decryptedData;

/** 
 * function to encrypt data
 * @param {data} string
 * @param {isJson} bool default:false | true if data is JSON object
 * @return {encrypted data} string
*/
export const encrypt = (data, isJson = false) => {

    if (!passfers)
        return false;

    const dataToEncrypt = isJson ? JSON.stringify(data) : data;

    return CryptoJs.AES.encrypt(dataToEncrypt, passfers).toString();
}

/**
 * @desc function to decrypt data
 * @param {data} string
 * @param {isJson} bool default:false | true if data is JSON object
 * @return decrypted data on success else false
*/
export const decrypt = (data, isJson = false) => {

    if (!passfers)
        return false;

    //call AES.decrypt function to decrypt data
    const isDecrypted = CryptoJs.AES.decrypt(data, passfers);

    //check if words array has no nagative values (This code failed in some cases)
    //if data is not decrypted properly then the (isDecrypted.words) has nagative values in array elements
    // const hasNagativeValues = isDecrypted.words.filter((ele) => ele < 0).length;

    //check if words array has 5 or more nagative values
    //if data is not decrypted properly then the (isDecrypted.words) has multiple nagative values in array elements
    const hasFiveOrMoreNegativeValue = hasFiveOrMoreNegativeNumbers(isDecrypted.words);

    //return false if the encrypted string is modified by user
    //we dont have proper method to detect invalid encrypted string yet
    //if data is not decrypted properly then the (isDecrypted.sigBytes) most of times will return nagative value
    const isValid = isDecrypted.sigBytes < 0 || hasFiveOrMoreNegativeValue  ? false : true


    if (!isValid)
        return { invalid: true };

    //convert decrypted data into actual string data
    const decryptedData = isDecrypted.toString(CryptoJs.enc.Utf8);

    if (hasJsonStructure(decryptedData) && isJson)
        return JSON.parse(decryptedData)
    else
        return decryptedData;
}

const hasFiveOrMoreNegativeNumbers = (wordArray) => {
    const negativeCount = wordArray.filter(num => num < 0).length;
    return negativeCount >= 5;
};

