import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function PaymentProcessingSkeleton() {
    return (
        <div className='card-body'>
            <SkeletonTheme highlightColor="#bababa" >
                <Skeleton width={170} /><br />
                <Skeleton width={120} className='mt-3' /><Skeleton width={120} className='mt-3 ms-4' /><br />
                <Skeleton width={120} className='mt-3' /><Skeleton width={120} className='mt-3 ms-4' /><br />
                <Skeleton width={120} className='mt-3' /><Skeleton width={120} className='mt-3 ms-4' /><br />
                <Skeleton height={30} className='mt-3' />
            </SkeletonTheme>
        </div>
    )
}
