import React, { useState } from "react";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const CouponModalSkeleton = () => {
    let skeletonElement = [];

    for (let i = 0; i < 3; i++)
        skeletonElement.push(<div className='mb-3' key={`offer-skeleton-${i}`}>
            <div className='row'>
                <div className='col-8'>
                    <Skeleton className='mb-3' height={20} width={150} />
                </div>
                <div className='col-4'>
                    <Skeleton className='mb-3' height={20} />
                </div>
            </div>
            <Skeleton height={15} count={3} />
        </div>);


    return (
        <SkeletonTheme highlightColor="#bababa" >
            <Skeleton height={30} className='mb-3' />
            {skeletonElement}
        </SkeletonTheme>
    )
}

const CouponModal = ({ offers, isLoading, applyCouponCode }) => {

    const [enteredCouponCode, setEnteredCouponCode] = useState('');
    const [errorLabelForCouponCode, setErrorLabelForCouponCode] = useState('');

    const hanldeCouponCode = (code) => {

        const isValid = /^[0-9A-Z]+$/.test(code);

        if (!code?.length)
            setErrorLabelForCouponCode('');
        else if (!isValid || code?.length <= 3)
            setErrorLabelForCouponCode('Enter valid coupon code');
        else
            setErrorLabelForCouponCode('');


        setEnteredCouponCode(code);

    }

    //handle manually entered coupon submit
    const handleManuallyEnteredCouponSubmit = () => {
        //call function to apply manually entered coupon
        applyCouponCode(enteredCouponCode);

        setEnteredCouponCode('');
    }

    let offersList = "";

    //prepare list of available offers
    if (Array.isArray(offers)) {
        offersList = offers.map((item) => {
            return (
                <li key={`offer-${item.Code}`}>
                    <div className="item">
                        <div className="in">
                            <div>{item.Code}</div>
                            <Link to={{ javascript: void (0) }} onClick={() => applyCouponCode(item.Code)}><b className='custom-text-style'>Apply</b></Link>
                        </div>
                    </div>
                    <div className='container-fluid'>
                        <p>You can get maximun discount upto ₹ {item.MaxDiscount}</p>
                        <ShowMoreText
                            lines={3}
                            more={<b>More</b>}
                            less={<b>Less</b>}
                            className="tuncatedOfferDetails text-secondary"
                            anchorClass="text custom-anchor-text-style"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                        >
                            <p>
                                {item.OfferDetails}
                            </p>
                        </ShowMoreText>
                    </div>
                </li>
            )
        })
    }


    return (
        <>
            <div
                className="modal fade bottom-align-modal"
                id="applyCouponModal"
                tabIndex="-1"
                aria-labelledby="applyCouponModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-end">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="applyCouponModalLabel"
                            >
                                Apply coupon code
                            </h6>
                            <button
                                className="btn btn-close p-1 ms-auto"
                                type="button"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            {
                                isLoading ? (
                                    <CouponModalSkeleton />
                                ) : (
                                    <>
                                        <div className="form-group sticky">
                                            <div className="input-group">
                                                <input
                                                    className="form-control input-group-text text-start"
                                                    type="text"
                                                    placeholder='Enter coupon code here'
                                                    value={enteredCouponCode}
                                                    onChange={(e) => hanldeCouponCode(e.target.value.toLocaleUpperCase())}
                                                    maxLength={20}
                                                />

                                                <button className="btn custom-btn-style btn-sm" type="button" onClick={handleManuallyEnteredCouponSubmit} disabled={errorLabelForCouponCode?.length || enteredCouponCode?.length < 1  ? true : false}>Apply</button>
                                            </div>
                                            <small className="text-danger ms-1">{errorLabelForCouponCode}</small>
                                        </div>
                                        <div style={{ minHeight: "40vh", maxHeight: "60vh", overflow: "auto" }}>
                                            {
                                                offersList ? (
                                                    <ul className="listview flush transparent image-listview text">
                                                        {offersList}
                                                    </ul>
                                                ) : (
                                                    <h5 className='custom-text-style mt-5 text-center'>No offers avaialble right now</h5>
                                                )
                                            }
                                        </div>
                                    </>)
                            }
                        </div>
                        {/* end modal body*/}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CouponModal;
