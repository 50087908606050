import React from "react";
import { useSelector } from "react-redux";
import { decrypt, isDecryptedDataValid } from "../functions/cipherFunctions";
import ShowMoreText from "react-show-more-text";


const OrderAmount = ({ subTotal, discount, deliveryCharge, taxAndCharge, totalPayableAmount, shippingType, testOrderAmount,showAddressKm }) => {

    //get and decrypt selected address from reducer
    const getSelectedAddressFromReducer = useSelector(state => state.orderProcessReducer.selectedAddress);
    const isSelected = getSelectedAddressFromReducer && isDecryptedDataValid(decrypt(getSelectedAddressFromReducer, true));

    return (
        <>
            <div className="section mt-1">
                <div className="card">
                    <div className='card-header'>
                        Bill Details
                    </div>
                    <ul className="listview flush transparent image-listview text">
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>Item total</div>
                                    {parseInt(testOrderAmount) > 0 ? <strike>₹ {subTotal}</strike> :
                                        <span>₹ {subTotal}</span>}
                                </div>
                            </div>
                            {
                                discount > 0 && <div className='m-0'>
                                    <span className='ms-3 text-success'>Item Discount</span>
                                    <span className='text-success' style={{ float: 'right', marginRight: '15px' }}>- ₹{discount}</span>
                                </div>
                            }
                        </li>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>Delivery fee</div>
                                    {parseInt(testOrderAmount) > 0 ? <strike>₹ {deliveryCharge}</strike> :
                                        <span>₹ {deliveryCharge ? deliveryCharge : 0}</span>}
                                </div>
                            </div>
                            {
                                shippingType === 1 && deliveryCharge === 0 && <><span className='ms-3 text-success'>We got your shipping!</span><span className="ms-3 mx-3 float-end text-success">FREE</span></>
                            }
                            {
                                shippingType === 1 && <div className='m-0'>
                                    {
                                        !isSelected && !deliveryCharge ? <ShowMoreText
                                            lines={1}
                                            more={""}
                                            less={""}
                                            className="ms-3 tuncatedOfferDetails custom-text-style"
                                            anchorClass=""
                                            expanded={false}
                                            truncatedEndingComponent={"... "}
                                            width={250}
                                        >
                                            {shippingType !== 2 && 'Will apply after you select address'}
                                        </ShowMoreText> : showAddressKm && deliveryCharge !== 0? (
                                            <>
                                                <span className='ms-3 text-secondary'>Charge is applied for</span>
                                                <span className="ms-3 float-end mx-3 text-secondary">{(isSelected?.RouteLength / 1000).toFixed(1)} Km</span>
                                            </>
                                        ) : ''
                                    }
                                </div>
                            }
                        </li>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div>Taxes and Charges<b className="mx-2 self-pickup-tooltip border custom-border ps-2 px-2" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="top" title="&lt;em&gt;Shop packaging and GST charges&lt;/em&gt;">?</b></div>
                                    {parseInt(testOrderAmount) > 0 ? <strike>₹ {taxAndCharge}</strike> :
                                        <span>₹ {taxAndCharge}</span>}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="in">
                                    <div><b>Total Pay</b></div>
                                    {parseInt(testOrderAmount) > 0 ? <strike><b>₹ {totalPayableAmount}</b></strike> :
                                        <span><b>₹ {totalPayableAmount}</b></span>}
                                </div>
                            </div>
                        </li>
                        {parseInt(testOrderAmount) > 0 &&
                            <li>
                                <div className="item">
                                    <div className="in">
                                        <div><b>Test amount to Pay</b></div>
                                        <span><b>₹ {testOrderAmount}</b></span>
                                    </div>
                                </div>
                            </li>}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default OrderAmount;
