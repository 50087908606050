import React, { useState, useEffect,useContext } from "react";
import { Shake } from "reshake";

import {authContext} from "../../context/authContext";

function RatingModal({ submitUserRatingAndReview, selectedStarRating }) {
    useEffect(() => {
        setStarStatus(selectedStarRating.Status);
        selectStar(selectedStarRating.Star, selectedStarRating.Status);

        //eslint-disable-next-line
    }, [selectedStarRating]);

    const [selectedStarStatus, setSelectedStarStatus] = useState(
        selectedStarRating.Status
    );

    const {isAuthenticate} = useContext(authContext);

    const username = isAuthenticate?.username;

    //this is used to display star status when user hover on any star
    //(EX: when user hover on 5th star then status will be "Loved it", on 3rd star then it will be "Just Ok" etc.)
    //by default it will assigned as selected star by user in Get User Review component
    const [starStatus, setStarStatus] = useState(selectedStarStatus);

    const [rating, setRating] = useState(0);
    const [review, setReview] = useState("");

    //function to set ratig star and status when user click on star
    const selectStar = (ratingStar, message) => {
        setRating(ratingStar);
        setSelectedStarStatus(message);
    };

    const [statusElement, setStatusElement] = useState(false);
    const [reviewWarning, setReviewWarning] = useState(
        <p className="text-danger mt-1">
            Describe Review in al least 30 characters
        </p>
    );

    const handleSubmit = (rat, rev) => {
        //if rating star is not selected than set shake effect to rating status text
        if (rat === 0) {
            setStatusElement(
                <>
                    <Shake
                        h={10}
                        v={0}
                        r={0}
                        fixed={true}
                        dur={300}
                        init={1}
                        max={100}
                        fixedStop={true}
                        freez={true}
                    >
                        <p className="text-danger mt-2">{starStatus}</p>
                    </Shake>
                </>
            );

            //remove shake effect from rating status text after 0.5 second
            setTimeout(() => {
                setStatusElement(false);
            }, 500);

            return false;
        }

        //count review length without including spaces(EX: rev='hello world' then it will return as 10 characters)
        const reviewLength = rev
            .split(/\s+/)
            .filter((obj) => {
                return obj.length !== 0;
            })
            .join("").length;

        if (reviewLength === 0) rev = null;

        //if review length is less than 30 then set shake effect to Review warning text
        if (rev && reviewLength > 0 && reviewLength < 30) {
            setReviewWarning(
                <>
                    <Shake
                        h={10}
                        v={0}
                        r={0}
                        fixed={true}
                        dur={300}
                        init={1}
                        max={100}
                        fixedStop={true}
                        freez={true}
                    >
                        <p className="text-danger mt-1">
                            {" "}
                            Describe Review in at least 30 characters
                        </p>
                    </Shake>
                </>
            );

            //remove shake effect after 0.5 second
            setTimeout(() => {
                setReviewWarning(
                    <p className="text-danger mt-1">
                        {" "}
                        Describe Review in at least 30 characters
                    </p>
                );
            }, 500);
        } else {
            if (rev !== null) {
                //remove extra space from review text
                let cleanReviewText = rev.split(/[ ]+/).join(" ");

                //if review contains any html tag then replace it to string format
                cleanReviewText = cleanReviewText.replace(/</g, "&lt;");
                cleanReviewText = cleanReviewText.replace(/>/g, "&gt;");

                //submit user rating and review
                submitUserRatingAndReview(rat, cleanReviewText);
            } else submitUserRatingAndReview(rat, null);
        }
    };

    return (
        <>
            <div
                className="modal fade bottom-align-modal"
                id="postRatingModal"
                tabIndex="-1"
                aria-labelledby="postRatingModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-end">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6
                                className="modal-title"
                                id="postRatingModalLabel"
                            >
                                Rate this product
                            </h6>
                            <button
                                className="btn btn-close p-1 ms-auto"
                                type="button"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <span className="d-block mb-1">
                                    <b>{username}</b>
                                </span>
                            </div>
                            <div className="container">
                                <p className="mb-0 mt-2 ms-4">
                                    Everyone can see your review and account
                                    name.
                                </p>
                            </div>

                            <div className="rating-card-three text-center py-4">
                                <div className="stars">
                                    <input
                                        className="stars-checkbox"
                                        type="radio"
                                        id="first-star"
                                        name="star"
                                    />
                                    <label
                                        className="stars-star"
                                        htmlFor="first-star"
                                        onMouseEnter={() =>
                                            setStarStatus("Loved it")
                                        }
                                        onMouseLeave={() =>
                                            setStarStatus(selectedStarStatus)
                                        }
                                        onClick={() =>
                                            selectStar(5, "Loved it")
                                        }
                                    >
                                        <svg
                                            className="star-icon"
                                            version="1.1"
                                            id="star1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 53.867 53.867"
                                            style={{
                                                enableBackground:
                                                    "new 0 0 53.867 53.867",
                                            }}
                                            xmlSpace="preserve"
                                        >
                                            <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 " />
                                        </svg>
                                    </label>
                                    <input
                                        className="stars-checkbox"
                                        type="radio"
                                        id="second-star"
                                        name="star"
                                    />
                                    <label
                                        className="stars-star"
                                        htmlFor="second-star"
                                        onMouseEnter={() =>
                                            setStarStatus("Liked it")
                                        }
                                        onMouseLeave={() =>
                                            setStarStatus(selectedStarStatus)
                                        }
                                        onClick={() =>
                                            selectStar(4, "Liked it")
                                        }
                                    >
                                        <svg
                                            className="star-icon"
                                            version="1.1"
                                            id="star2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 53.867 53.867"
                                            style={{
                                                enableBackground:
                                                    "new 0 0 53.867 53.867",
                                            }}
                                            xmlSpace="preserve"
                                        >
                                            <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 " />
                                        </svg>
                                    </label>
                                    <input
                                        className="stars-checkbox"
                                        type="radio"
                                        id="third-star"
                                        name="star"
                                    />
                                    <label
                                        className="stars-star"
                                        htmlFor="third-star"
                                        onMouseEnter={() =>
                                            setStarStatus("Just OK")
                                        }
                                        onMouseLeave={() =>
                                            setStarStatus(selectedStarStatus)
                                        }
                                        onClick={() => selectStar(3, "Just OK")}
                                    >
                                        <svg
                                            className="star-icon"
                                            version="1.1"
                                            id="star3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 53.867 53.867"
                                            style={{
                                                enableBackground:
                                                    "new 0 0 53.867 53.867",
                                            }}
                                            xmlSpace="preserve"
                                        >
                                            <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 " />
                                        </svg>
                                    </label>
                                    <input
                                        className="stars-checkbox"
                                        type="radio"
                                        id="fourth-star"
                                        name="star"
                                    />
                                    <label
                                        className="stars-star"
                                        htmlFor="fourth-star"
                                        onMouseEnter={() =>
                                            setStarStatus("Don't liked it")
                                        }
                                        onMouseLeave={() =>
                                            setStarStatus(selectedStarStatus)
                                        }
                                        onClick={() =>
                                            selectStar(2, "Don't liked it")
                                        }
                                    >
                                        <svg
                                            className="star-icon"
                                            version="1.1"
                                            id="star4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 53.867 53.867"
                                            style={{
                                                enableBackground:
                                                    "new 0 0 53.867 53.867",
                                            }}
                                            xmlSpace="preserve"
                                        >
                                            <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 " />
                                        </svg>
                                    </label>
                                    <input
                                        className="stars-checkbox"
                                        type="radio"
                                        id="fifth-star"
                                        name="star"
                                    />
                                    <label
                                        className="stars-star"
                                        htmlFor="fifth-star"
                                        onMouseEnter={() =>
                                            setStarStatus("Hated it")
                                        }
                                        onMouseLeave={() =>
                                            setStarStatus(selectedStarStatus)
                                        }
                                        onClick={() =>
                                            selectStar(1, "Hated it")
                                        }
                                    >
                                        <svg
                                            className="star-icon"
                                            version="1.1"
                                            id="star5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 53.867 53.867"
                                            style={{
                                                enableBackground:
                                                    "new 0 0 53.867 53.867",
                                            }}
                                            xmlSpace="preserve"
                                        >
                                            <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 " />
                                        </svg>
                                    </label>
                                </div>
                                {statusElement ? (
                                    statusElement
                                ) : (
                                    <p className="text-warning mt-2">
                                        {starStatus}
                                    </p>
                                )}
                            </div>
                            {/*end stars*/}

                            <div className="form-group">
                                <textarea
                                    className="form-control"
                                    placeholder="Describe your experiance (optional)"
                                    maxLength={1500}
                                    onChange={(e) => setReview(e.target.value)}
                                ></textarea>
                                {review
                                    .split(/\s+/)
                                    .filter((obj) => {
                                        return obj.length !== 0;
                                    })
                                    .join("").length > 0 &&
                                review
                                    .split(/\s+/)
                                    .filter((obj) => {
                                        return obj.length !== 0;
                                    })
                                    .join("").length < 30
                                    ? reviewWarning
                                    : ""}
                            </div>

                            <button
                                type="button"
                                className="btn custom-btn-style  w-100"
                                onClick={() => handleSubmit(rating, review)}
                            >
                                Post
                            </button>
                        </div>
                        {/* end modal body*/}
                    </div>
                </div>
            </div>
        </>
    );
}

export default RatingModal;
