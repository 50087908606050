import React from 'react'

export default function ShippingType({ deliveryMethods, handleShippingTypeChange, selectedShippingType }) {


    let methodsElement = '';
    if (deliveryMethods?.length > 0) {
        methodsElement = deliveryMethods.map((items, index) => {
            return <div key={`shipping-${items.method}-${index}`} className="mb-3 border rounded p-3">
                <div className="form-check d-flex align-items-center"  >
                    <input
                        className="form-check-input custom-form-check "
                        type="radio"
                        name="shippingType"
                        id={items.method}
                        value={items.valueForCheckBox}
                        defaultChecked={(selectedShippingType === items.valueForCheckBox) ? true : false}
                        onChange={(e) => handleShippingTypeChange(e.target.value)}
                    />
                    <label className='form-check-label d-flex align-items-center w-100' htmlFor={items.method} style={{ cursor: 'pointer'}}>
                        <span className="ms-2">{items.frontLabel}</span>
                    </label>
                    {items?.frontTooltip && <b className="self-pickup-tooltip border custom-border ps-2 px-2" data-bs-toggle="tooltip" data-bs-html="false" data-bs-placement="top" title={items.frontTooltip}>?</b>}
                </ div>
            </div>
        })
    }

    //check if no payment element 
    if ((!methodsElement[0] && deliveryMethods?.length) || (deliveryMethods?.NoMethods === true)) {

        methodsElement = <h6 className="text-center text-danger">No delivery methods available</h6>;
    }

    return (
        <>
            <div className='card mt-1'>
                <div className='card-header'>
                    Delivery Type
                </div>
                <div className='card-body'>
                    {methodsElement}
                </div>
            </div>
        </>
    )
}