import React from "react";
import { Link, useLocation } from "react-router-dom";

function HeaderArea() {
    const currentPath = useLocation().pathname;

    return (
        <>
            <div className="header-area" id="headerArea">
                <div className="container">
                    {/* Header Content*/}
                    <div className="header-content header-style-five header-style-three position-relative d-flex align-items-center justify-content-between">
                        {/* Logo Wrapper*/}
                        <div className="logo-wrapper">
                            <Link to="/">
                                <img
                                    src={process.env.PUBLIC_URL + "/logos/icon-512x512.png"}
                                    alt=""
                                />
                            </Link>
                        </div>
                        {
                            currentPath === "/address/list" && <div className="user-profile-wrapper">
                                <Link to={'/address/new'} className="custom-span custom-text-style" >Add new</Link>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderArea;
