import React,{useState} from 'react'

export default function ProductImageLoader({serverImgPath , src}) {
    const [loaded, setLoaded] = useState(false);
    const loader = process.env.PUBLIC_URL + 'bg-img/chicken.gif';
    return (
        <>
            <img src={loader} alt="" style={loaded ? { display: 'none' } : {}} />
            <img
                style={loaded ? {} : { display: 'none' }}
                src={`${serverImgPath}${src}`}
                onLoad={() => setLoaded(true)}
                alt=""
            />
        </>
    );
}
