import React, { useState, useRef, useEffect } from 'react';

//import here map api objects
import H from "@here/maps-api-for-javascript";
import onResize from 'simple-element-resize-detector';

export default function HereMap({ setUserAddressAndLatLngFromMap, apikey, mapCordinates }) {

    const HmapApiKey = apikey;

    const mapRef = useRef(null);
    const [displayMap, setDisplayMap] = useState(null)

    //set users lat & lng if found else set default as botad
    const usersCordinates = mapCordinates || {
        lat: 22.17203,
        lng: 71.66369
    };


    useEffect(() => {
        let map;
        if (usersCordinates && !displayMap) {

            setTimeout(() => {

                // instantiate a platform, default layers and a map as usual
                const platform = new H.service.Platform({
                    apikey: HmapApiKey
                });

                const layers = platform.createDefaultLayers();

                // Get an instance of the search service:
                const service = platform.getSearchService();

                map = new H.Map(
                    mapRef.current,
                    layers.raster.normal.map,
                    {
                        pixelRatio: window.devicePixelRatio,
                        center: { lat: usersCordinates.lat, lng: usersCordinates.lng },
                        zoom: mapCordinates?.lat ? 17 : 14,
                    },
                );

                //adjust map size as per screen whenever the screen will resize
                onResize(mapRef.current, () => {
                    map.getViewPort().resize();
                });

                //set image for custom map marker
                const mapMarker = new H.map.Icon(process.env.PUBLIC_URL + '/icons/map_marker_128.png', { size: { w: 80, h: 80 } });

                //set marker object
                const marker = new H.map.Marker({ lat: usersCordinates.lat, lng: usersCordinates.lng }, {
                    // mark the object as volatile for the smooth dragging
                    volatility: true,
                    icon: mapMarker,
                });

                // Ensure that the marker can receive drag events
                marker.draggable = true;
                map.addObject(marker);

                //add default Ui to map (Zoom control,choose view control)
                H.ui.UI.createDefault(map, layers);

                // MapEvents enables the event system
                // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
                const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

                // disable the default draggability of the underlying map
                // and calculate the offset between mouse and target's position
                // when starting to drag a marker object:
                map.addEventListener('dragstart', function (ev) {
                    let target = ev.target,
                        pointer = ev.currentPointer;
                    if (target instanceof H.map.Marker) {
                        let targetPosition = map.geoToScreen(target.getGeometry());
                        target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
                        behavior.disable();
                    }

                }, false);

                // re-enable the default draggability of the underlying map
                // when dragging has completed
                map.addEventListener('dragend', function (ev) {
                    let target = ev.target;
                    if (target instanceof H.map.Marker) {
                        behavior.enable();

                        service.reverseGeocode({
                            at: `${target.a.lat},${target.a.lng},150`
                        }, (result) => {

                            //get address from lat, lng
                            if (result.items.length > 0) {
                                const addressObj = result.items[0];
                                const addressType = addressObj.resultType === 'place' ? '' : addressObj.resultType;

                                const data = {
                                    fullAddress: `${addressType} ${addressObj.address.label}`,
                                    lat: addressObj.position.lat,
                                    lng: addressObj.position.lng
                                }

                                setUserAddressAndLatLngFromMap(data);
                            }
                        })
                    }
                }, false);

                // Listen to the drag event and move the position of the marker
                // as necessary
                map.addEventListener('drag', function (ev) {
                    let target = ev.target,
                        pointer = ev.currentPointer;
                    if (target instanceof H.map.Marker) {
                        target.setGeometry(map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
                    }
                }, false);

                setDisplayMap(map)
            }, 100);
        }
        //eslint-disable-next-line
    }, [usersCordinates])


    return (
        <div
            className="mt-1"
            style={{ width: '100%', height: '50vh' }}
            ref={mapRef}
        />
    )

}