import React from 'react'

export default function PaymentDetails({ paymentDetails, paymentStatus, rzpRefundId, noDetails }) {

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    

    return (
        <div className='card mt-1'>
            <div className='card-header'>
                Payment Details
            </div>
            <div className='p-3 pb-0 row'>
                {
                    noDetails ? <div className='text-center p-3'><h6 className='text-warning'>Your payment is pending</h6><h6><small className=''>If you already completed the payment then details will be display here soon.</small></h6></div> :
                        <>
                            <div className='col-3'>
                                <p style={{ lineHeight: 1.6 }}>
                                    <span className='fw-bold'>Status </span><br />

                                    {
                                        /**If refund id found */
                                        rzpRefundId && <><span className='fw-bold'>Refund ID </span><br /></>
                                    }

                                    <span className='fw-bold'>Pay ID </span><br />
                                    <span className='fw-bold'>Method </span><br />


                                    {/** if payment made using Wallet */}
                                    {paymentDetails?.wallet && <><span className='fw-bold'>Provider </span><br /></>}

                                    {/** if payment made using UPI */}
                                    {paymentDetails?.vpa && <><span className='fw-bold'>UPI ID </span><br /></>}


                                    {/** if payment made using Net Banking */}
                                    {paymentDetails?.bank && <><span className='fw-bold'>Bank </span><br /></>}


                                    {/** if payment made using Credit/Debit card */}
                                    {paymentDetails?.card_id &&
                                        <>
                                            <span className='fw-bold'>Type </span><br />
                                            {paymentDetails?.card?.name && <><span className='fw-bold'>Name</span><br /></>}
                                            <span className='fw-bold'>Number</span><br />
                                        </>
                                    }


                                </p>
                            </div>
                            <div className='col-9'>
                                <p style={{ lineHeight: 1.6 }}>
                                    <span className={paymentStatus === "Refunded" ? 'text-primary fw-bold' : 'text-success fw-bold'}>{paymentStatus}</span>
                                    <br />

                                    {
                                        /**If refund id found */
                                        rzpRefundId && <><span>{rzpRefundId}</span>
                                        <span className='payment_error'>
                                            <i className='fa fa-info-circle ms-1' data-toggle="tooltip" data-placement="top" title="Razorpay refund id"></i> </span>
                                        <br /></>
                                    }

                                    <span>{paymentDetails?.id}</span><br />

                                    {/** if payment made using UPI */}
                                    {paymentDetails?.wallet &&
                                        <>
                                            <span>{paymentDetails?.method}</span><br />
                                            <span>{paymentDetails?.wallet}</span><br />
                                        </>
                                    }
                                    {/** if payment made using UPI */}
                                    {paymentDetails?.vpa &&
                                        <>
                                            <span>{paymentDetails?.method.toString().toUpperCase()}</span><br />
                                            <span>{paymentDetails?.vpa}</span><br />
                                        </>
                                    }

                                    {/** if payment made using Net Banking */}
                                    {paymentDetails?.bank &&
                                        <>
                                            <span>Net Banking</span><br />
                                            <span>{paymentDetails?.bank}</span><br />
                                        </>
                                    }

                                    {/** if payment made using Credit/Debit card */}
                                    {paymentDetails?.card_id &&
                                        <>
                                            <span>{capitalizeFirstLetter(paymentDetails?.card?.type)} {capitalizeFirstLetter(paymentDetails?.method)}</span><br />
                                            <span>{paymentDetails?.card?.network}</span><br />
                                            {paymentDetails?.card?.name && <><span>{paymentDetails?.card?.name}</span><br /></>}
                                            <span>xxxx xxxx xxxx {paymentDetails?.card?.last4}</span><br />
                                        </>
                                    }
                                </p>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}
