import React, { useState } from 'react'

export default function ConfirmOrderCancelation({ callbackHnadler }) {

    const [reason, setReason] = useState('');
    const [reasonComment, setReasonComment] = useState('');
    
    const handleReasonChange = (value) => {
        setReason(value);

        if(reason !== 'Other')
            setReasonComment('');
    }

    const handleSubmit = () => {
        const params = {
            reason:reason,
            comment:reasonComment
        }

        callbackHnadler(params);

        window.$('.form-check-input').each(function(){
            this.checked = false;
        })

        setReason('')
        setReasonComment('')
    }

    return (
        <div className="modal fade" id="confirmOrderCancelationDialog" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{ display: 'none' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title" id="staticBackdropLabel">We're always striving for improvement. Please select reason for canceling your order.</h6>
                        <button className="btn btn-close p-1 ms-auto" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="form-check mt-2">
                                <input className="form-check-input border-secondary" type="radio" name="cancelReason" id="reason1" value="Ordered by mistake" onChange={(e) => handleReasonChange(e.target.value)} />
                                <label className="radio-check-label mt-1" htmlFor="reason1">
                                    Ordered by mistake
                                </label>
                            </div>
                            <div className="form-check mt-2">
                                <input className="form-check-input border-secondary" type="radio" name="cancelReason" id="reason2" value="Delivery information needs to be updated" onChange={(e) => handleReasonChange(e.target.value)} />
                                <label className="radio-check-label mt-1" htmlFor="reason2">
                                    Delivery information needs to be updated
                                </label>
                            </div>
                            <div className="form-check mt-2">
                                <input className="form-check-input border-secondary" type="radio" name="cancelReason" id="reason3" value="Incorrect item or quantity selected" onChange={(e) => handleReasonChange(e.target.value)} />
                                <label className="radio-check-label mt-1" htmlFor="reason3">
                                    Incorrect item or quantity selected
                                </label>
                            </div>
                            <div className="form-check mt-2">
                                <input className="form-check-input border-secondary" type="radio" name="cancelReason" id="reason4" value="Long wait time for delivery" onChange={(e) => handleReasonChange(e.target.value)} />
                                <label className="radio-check-label mt-1" htmlFor="reason4">
                                    Long wait time for delivery
                                </label>
                            </div>
                            <div className="form-check mt-2">
                                <input className="form-check-input border-secondary" type="radio" name="cancelReason" id="reason5" value="Shipping cost is too high" onChange={(e) => handleReasonChange(e.target.value)} />
                                <label className="radio-check-label mt-1" htmlFor="reason5">
                                    Shipping cost is too high
                                </label>
                            </div>
                            <div className="form-check mt-2">
                                <input className="form-check-input border-secondary" type="radio" name="cancelReason" id="reason6" value="Other" onChange={(e) => handleReasonChange(e.target.value)} />
                                <label className="radio-check-label mt-1" htmlFor="reason6">
                                    Other(Please specify below)
                                </label>
                            </div>
                            {
                                reason === 'Other' &&
                                <div className="form-group mt-2">
                                    <textarea
                                        className="form-control"
                                        placeholder="Please specify the reason"
                                        maxLength={1000}
                                        onChange={(e) => setReasonComment(e.target.value)}
                                    ></textarea>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn w-100 btn-danger" type="button" disabled={!reason || (reason === "Other" && reasonComment.length < 5) } onClick={handleSubmit}>Submit & cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}