import React, { useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import $ from "jquery";
import { cartItemContext } from "../context/cartItemContext";
import { useDispatch, useSelector } from "react-redux";
import { calculateRouteDistance, setSelectedAddress } from "../redux/actions/orderProcessActions";
import { modalContext } from "../context/modalContext";
import { decrypt, isDecryptedDataValid } from "./functions/cipherFunctions";
import { serverContext } from "../context/serverContext";

export default function Footer() {
    const dispatch = useDispatch();
    const history = useHistory();

    const openSideNav = () => {
        window.$('#sidenavWrapper').addClass('nav-active');
        window.$('#backOverlay').addClass('active');
    }


    const { showErrorDialog } = useContext(modalContext);
    const { serverPath } = useContext(serverContext);

    //get current path name of url
    const location = useLocation().pathname;

    //get and decrypt shipping configurations from reducer
    const getShippingConfigsData = useSelector(state => state.orderDataReducer.shippingConfigs);
    const shippingConfigs = getShippingConfigsData && isDecryptedDataValid(decrypt(getShippingConfigsData, true));

    //variable for class name of page Link
    var home = "false";
    var cart = "false";
    var settings = "false";

    if (location === "/") home = "active";
    else if (location === "/cart") cart = "active";
    else if (location === "/settings") settings = "active";

    //change active page class
    const setActivePage = (home, cart, settings) => {
        $("#homeLink").attr("class", home);
        $("#cartLink").attr("class", cart);
        $("#settingsLink").attr("class", settings);
    };

    const { productsInCart } = useContext(cartItemContext)

    //function to set selected address when user click on continue button in address list page
    const setAddress = () => {
        //we used global variable that we set during select address in addressList component
        const selectedAddress = window.selectedAddress;

        if (!selectedAddress) {
            showErrorDialog("No address selected", "Please select address before continue.");
            return;
        }

        window.selectedAddress = false;

        //dispatch action to set selected address
        dispatch(setSelectedAddress(selectedAddress));

        const storeCordinates = JSON.parse(shippingConfigs?.storeLocation);

        const origin = {
            lat: storeCordinates.lat,
            lng: storeCordinates.lng
        }

        const destination = {
            lat: selectedAddress.Latitude,
            lng: selectedAddress.Longitude
        }

        const params = {
            fromCords: origin,
            toCords: destination,
            addressId: selectedAddress?.AddressId
        };

        //calculate route distance 
        dispatch(calculateRouteDistance(serverPath, params, shippingConfigs?.distanceCalculationApi, selectedAddress))

        //redirect to checkout page
        history.push("/cart");
    }

    return (
        <>
            {/* Footer Nav*/}
            <div className="footer-nav-area" id="footerNav">
                <div className="container px-0">
                    {/* Footer Content*/}
                    <div className="footer-nav position-relative">
                        <ul className="h-100 d-flex align-items-center justify-content-between ps-0">
                            {
                                location === "/address/list" ? (
                                    <div className="container">
                                        <button className="btn custom-btn-style w-100 mb-2" onClick={setAddress}>Continue</button>
                                    </div>
                                ) : (
                                    <>
                                        <li onClick={openSideNav}>
                                            <div
                                                className="navbar-toggler"
                                                id="myProfileSideView"
                                            >
                                                <Link to="#">
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 16 16"
                                                        className="bi bi-person"
                                                        fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M13 14s1 0 1-1-1-4-6-4-6 3-6 4 1 1 1 1h10zm-9.995-.944v-.002.002zM3.022 13h9.956a.274.274 0 0 0 .014-.002l.008-.002c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664a1.05 1.05 0 0 0 .022.004zm9.974.056v-.002.002zM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                                                        />
                                                    </svg>
                                                    <span>my profile</span>
                                                </Link>
                                            </div>
                                        </li>
                                        <li id="homeLink" className={home}>
                                            <Link
                                                to="/"
                                                onClick={() => {
                                                    setActivePage(
                                                        "active",
                                                        "false",
                                                        "false"
                                                    );
                                                }}
                                            >
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 16 16"
                                                    className="bi bi-house"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                                                    />
                                                </svg>
                                                <span>Home</span>
                                            </Link>
                                        </li>
                                        <li id="cartLink" className={cart}>
                                            <Link
                                                to="/cart"
                                                onClick={() => {
                                                    setActivePage(
                                                        "false",
                                                        "active",
                                                        "false"
                                                    );
                                                }}
                                            >
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 16 16"
                                                    className="bi bi-cart"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                                                    />
                                                </svg>
                                                <span>cart</span>
                                                <span className="cart-item-badge">
                                                    {productsInCart.length !== 0
                                                        ? productsInCart.length
                                                        : ''}
                                                </span>
                                            </Link>
                                        </li>
                                        <li id="settingsLink" className={settings}>
                                            <Link
                                                to="/settings"
                                                onClick={() => {
                                                    setActivePage(
                                                        "false",
                                                        "false",
                                                        "active"
                                                    );
                                                }}
                                            >
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 16 16"
                                                    className="bi bi-gear"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z"
                                                    />
                                                </svg>
                                                <span>Settings</span>
                                            </Link>
                                        </li>
                                    </>
                                )
                            }

                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
