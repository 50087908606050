import React from 'react'

export default function StatusHistory({ statusHistory }) {

    let statusHistoryList = <h5 className='p-4 text-center text-danger'>No status history found</h5>;


    if (statusHistory?.length) {
        statusHistoryList = statusHistory.map(item => {
            return (
                <li key={item.RowId}>
                    <div className='item'>
                        <div className='in'>
                            <div>{item.CreatedAt}</div>
                            <span><b>{item.Status}</b></span>
                        </div>
                    </div>
                    <div className='ps-3'>
                        <p className='pb-2' style={{ color: 'rgb(132, 128, 174)' }}>{item.Comment}</p>
                    </div>
                </li>
            )
        });
    }

    return (
        <div className='card mt-1'>
            <div className='card-header'>
                Status History
            </div>
            <ul className='listview flush transparent image-listview text'>
                {statusHistoryList}
            </ul>
        </div>
    )
}
