//not implimented yet
//we are ecrypting responce data in reducer instead in this file 
//because we are storing products data , rating , reviews etc sepratly 
//so we have requirement of access actual product data   
import {
    BEGIN_ACTION_IN_PRODUCT_DETAILS_REDUCER,
    SUCCESS_GET_PRODUCTS_DETAILS,
    SUCCESS_GET_PRODUCTS_REVIEWS,
    UPDATE_LOADED_PAGE_NUMBER_OF_PRODUCT_REVIEWS,
    IS_USER_ALREADY_POSTED_REVIEW,
    SUCCESS_POST_RATING,
    EXCEPTION_GENERATED_IN_PRODUCT_DETAILS_REDUCER,
} from "../constants";

import axios from "axios";
import { hasJsonStructure } from "../../components/functions/cipherFunctions";

export function getProductDetailsData({ serverPath, productId }) {
    return (dispatch) => {
        //begin fetch request
        dispatch({
            type: BEGIN_ACTION_IN_PRODUCT_DETAILS_REDUCER,
        });

        axios
            .get(serverPath + "/product-details", {
                params: {
                    productId: productId,
                }
            })
            .then((res) => {
                dispatch({
                    type: SUCCESS_GET_PRODUCTS_DETAILS,
                    payload: res.data,
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_PRODUCT_DETAILS_REDUCER,
                    payload: {
                        message: message,
                        description:
                            "Something went wrong, Please try again after some time",
                    },
                });
            });
    };
}

export function getMoreReviews({ serverPath, productId, pageNo }) {
    return (dispatch) => {
        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_PRODUCT_DETAILS_REDUCER,
        });

        axios
            .get(serverPath + "/products-more-reviews", {
                params: {
                    productId: productId,
                    pageNo: pageNo,
                }
            })
            .then((res) => {
                dispatch({
                    type: SUCCESS_GET_PRODUCTS_REVIEWS,
                    payload: {
                        Reviews: res.data.Reviews,
                        TotalNumberOfReviews: res.data.TotalNumberOfReviews,
                        ProductId: productId,
                    },
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_PRODUCT_DETAILS_REDUCER,
                    payload: {
                        message: message,
                        description:
                            "Something went wrong, Please try again after some time",
                    },
                });
            });
    };
}

export function updateLoadedReviewsPageNumber(props) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_LOADED_PAGE_NUMBER_OF_PRODUCT_REVIEWS,
            payload: {
                ProductId: props.ProductId,
                LoadedPageNumber: props.LoadedPageNumber,
            },
        });
    };
}

export function checkIsUserAlreadyPostedReview(serverPath, params) {
    return (dispatch) => {
        axios
            .get(serverPath + "/user-already-posted-review", { params: params })
            .then((res) => {
                dispatch({
                    type: IS_USER_ALREADY_POSTED_REVIEW,
                    payload: res.data.AlreadyPosted,
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_PRODUCT_DETAILS_REDUCER,
                    payload: {
                        message: message,
                        description:
                            "Something went wrong, Please try again after some time",
                    },
                });
            });
    };
}

export function postUsersRatingAndReview(serverPath, params) {
    return (dispatch) => {
        axios
            .post(serverPath + "/post-user-rating", params)
            .then((res) => {
                dispatch({
                    type: SUCCESS_POST_RATING,
                    ProductId: params.productId,
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;

                    if (error.request.status === 400)
                        description =
                            "You have already posted rating and review for this product";
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_PRODUCT_DETAILS_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    };
}
