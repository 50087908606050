import React from 'react'
import { Link } from 'react-router-dom'

export default function EmptyCart() {
    return (
        <div className="card no-content">
            <div className="card-body">
                <div className="text-center no-content-body">
                    <h6 className="text-warning ">
                        ahh! your cart is empty like your life.
                    </h6>

                    <img
                        className="login-intro-img "
                        src={
                            process.env.PUBLIC_URL +
                            "/bg-img/emptyCart.png"
                        }
                        alt=""
                    />
                    <Link
                        className="btn btn-warning w-50"
                        to="/"
                    >
                        Shop now <i className="fa fa-shopping-cart"></i>
                    </Link>
                </div>
            </div>
        </div>
    )
}
