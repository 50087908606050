import React from "react";

function GetUsersReview(props) {
    //function to select stars and display it in Rating Modal as selected number of stars
    const handleSelectStar = (star, status) => {
        props.setRatingStarAndStatus({
            Status: status,
            Star: star,
        });
    };

    return (
        <>
            <div className="card mt-1">
                <div className="card-body">
                    <h2>Rate this product</h2>
                    <div className="rating-card-three text-center py-5">
                        <h6 className="mb-3">
                            How was your experience with this product?
                        </h6>
                        <div className="stars">
                            <input
                                className="stars-checkbox"
                                type="radio"
                                id="first-star"
                                name="star"
                            />
                            <label
                                className="stars-star"
                                htmlFor="first-star"
                                data-bs-toggle="modal"
                                data-bs-target="#postRatingModal"
                                onClick={() => handleSelectStar(5, "Loved it")}
                            >
                                <svg
                                    className="star-icon"
                                    version="1.1"
                                    id="star1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 53.867 53.867"
                                    style={{
                                        enableBackground:
                                            "new 0 0 53.867 53.867",
                                    }}
                                    xmlSpace="preserve"
                                >
                                    <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 " />
                                </svg>
                            </label>
                            <input
                                className="stars-checkbox"
                                type="radio"
                                id="second-star"
                                name="star"
                            />
                            <label
                                className="stars-star"
                                htmlFor="second-star"
                                data-bs-toggle="modal"
                                data-bs-target="#postRatingModal"
                                onClick={() => handleSelectStar(4, "Liked it")}
                            >
                                <svg
                                    className="star-icon"
                                    version="1.1"
                                    id="star2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 53.867 53.867"
                                    style={{
                                        enableBackground:
                                            "new 0 0 53.867 53.867",
                                    }}
                                    xmlSpace="preserve"
                                >
                                    <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 " />
                                </svg>
                            </label>
                            <input
                                className="stars-checkbox"
                                type="radio"
                                id="third-star"
                                name="star"
                            />
                            <label
                                className="stars-star"
                                htmlFor="third-star"
                                data-bs-toggle="modal"
                                data-bs-target="#postRatingModal"
                                onClick={() => handleSelectStar(3, "Just OK")}
                            >
                                <svg
                                    className="star-icon"
                                    version="1.1"
                                    id="star3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 53.867 53.867"
                                    style={{
                                        enableBackground:
                                            "new 0 0 53.867 53.867",
                                    }}
                                    xmlSpace="preserve"
                                >
                                    <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 " />
                                </svg>
                            </label>
                            <input
                                className="stars-checkbox"
                                type="radio"
                                id="fourth-star"
                                name="star"
                            />
                            <label
                                className="stars-star"
                                htmlFor="fourth-star"
                                data-bs-toggle="modal"
                                data-bs-target="#postRatingModal"
                                onClick={() =>
                                    handleSelectStar(2, "Don't Like it")
                                }
                            >
                                <svg
                                    className="star-icon"
                                    version="1.1"
                                    id="star4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 53.867 53.867"
                                    style={{
                                        enableBackground:
                                            "new 0 0 53.867 53.867",
                                    }}
                                    xmlSpace="preserve"
                                >
                                    <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 " />
                                </svg>
                            </label>
                            <input
                                className="stars-checkbox"
                                type="radio"
                                id="fifth-star"
                                name="star"
                            />
                            <label
                                className="stars-star"
                                htmlFor="fifth-star"
                                data-bs-toggle="modal"
                                data-bs-target="#postRatingModal"
                                onClick={() => handleSelectStar(1, "Hated it")}
                            >
                                <svg
                                    className="star-icon"
                                    version="1.1"
                                    id="star5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 53.867 53.867"
                                    style={{
                                        enableBackground:
                                            "new 0 0 53.867 53.867",
                                    }}
                                    xmlSpace="preserve"
                                >
                                    <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 " />
                                </svg>
                            </label>
                        </div>
                    </div>
                    <strong
                        className="custom-text-style border border-secondary p-1"
                        data-bs-toggle="modal"
                        data-bs-target="#postRatingModal"
                        style={{ cursor: "pointer" }}
                    >
                        Write Review
                    </strong>
                </div>
            </div>
        </>
    );
}

export default GetUsersReview;
