import React from 'react'
import { Link } from 'react-router-dom';
import NoOrders from './NoOrders';

export default function OrderHistoryList({ orders, initiatePayment }) {

    //function to print order status badge
    const orderStatusBadge = (status) => {
        if (parseInt(status) === 1)
            return <span className="badge bg-warning mb-1">Pending</span>
        else if (parseInt(status) === 2)
            return <span className="badge bg-info mb-1">Processing</span>
        else if (parseInt(status) === 3)
            return <span className="badge bg-success mb-1">Ready</span>
        else if (parseInt(status) === 4)
            return <span className="badge bg-success mb-1">Completed</span>
        else
            return <span className="badge bg-danger mb-1">Canceled</span>
    }
    const orderList = orders?.length > 0 && orders.map(item => {
        return (
            <div className='card order-history-card m-3' key={item.OrderId}>
                <div className='card-body'>
                    <Link to={`/order/details/${item.OrderId}`} >
                        {orderStatusBadge(item.Status)}
                        <div className='row text-start mb-2'>
                            <h6><small>Placed on : </small>{item.CreatedAt}</h6>
                            <h6 className='fw-bold'>₹ {item.Total}</h6>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <h6>Order ID</h6>
                                <h6>Payment Type</h6>
                                {parseInt(item?.Status) === 1 && item?.PaymentType === "Razorpay" && item?.PaymentStatus !== "Paid" && <h6 className=''>Payment Status</h6>}
                            </div>
                            <div className='col-6' style={{ textAlign: 'end' }}>
                                <h6>{item.OrderNumber}</h6>
                                <h6>{item.PaymentType}</h6>
                                {parseInt(item?.Status) === 1 && item?.PaymentType === "Razorpay" && item?.PaymentStatus === "Failed" && <h6 className='text-danger fw-bold'>{item?.PaymentStatus}</h6>}
                                {parseInt(item?.Status) === 1 && item?.PaymentType === "Razorpay" && item?.PaymentStatus === "Pending" && <h6 className='text-warning fw-bold'>{item?.PaymentStatus}</h6>}
                            </div>
                        </div>
                    </Link>
                    {parseInt(item?.Status) === 1 && item?.PaymentType === "Razorpay" && item?.PaymentStatus === "Failed" && <button className='btn btn-outline-danger mt-3 w-100' onClick={(e) => initiatePayment(e, item?.OrderId)}>Revise payment</button>}
                    {parseInt(item?.Status) === 1 && item?.PaymentType === "Razorpay" && item?.PaymentStatus === "Pending" && <button className='btn btn-outline-warning mt-3 w-100' onClick={(e) => initiatePayment(e, item?.OrderId)}>Complete payment</button>}
                </div>
            </div>
        )
    });

    return orders?.length > 0 ? orderList : <NoOrders />;
}
