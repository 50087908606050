import React from 'react'
import { Link } from 'react-router-dom';

export default function NoAddress({ message, description }) {
    const illustration = localStorage.getItem("theme") === "dark" ? "address-illustration-dark.png" : "address-illustration-light.png";
    return (

        <div className="card no-content">
            <div className="card-body">
                <div className="text-center no-content-body">
                    <img
                        className="login-intro-img"
                        src={process.env.PUBLIC_URL + "/bg-img/" + illustration}
                        style={{ width: "50vw" }}
                        alt=""
                    />
                    <h4 className="mt-3">{message}</h4>
                    <p>{description}</p>
                    <Link to={"/address/new"} className="btn btn-warning w-50 mt-3">
                        <i className="fa fa-map-marker "></i> Add Address
                    </Link>
                </div>
            </div>
        </div>
    )
}
