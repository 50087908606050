import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { cartItemContext } from "../../context/cartItemContext";
import { serverContext } from "../../context/serverContext";
import ProductImageLoader from "../ProductImageLoader";



function TopRatedProducts(props) {

    const uuid = require("uuid");
    const { serverImagePath } = useContext(serverContext);

    const imagePath = serverImagePath + "/products/";
    let productList = null;

    const { addProductsToCart } = useContext(cartItemContext);
    if (Array.isArray(props.products)) {
        productList = props.products.map(function (val) {
            return (
                <div
                    className="col-12"
                    key={`topRatedProduct-${val.ProductId}`}
                >
                    <div className="card single-product-card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="card-side-img">
                                    {/* Product Thumbnail*/}
                                    <Link
                                        className="product-thumbnail d-block"
                                        to={`/product/view/${val.ProductId}`}
                                    >
                                        <ProductImageLoader serverImgPath={imagePath} src={val.ProductImage} />
                                        {/* Badge*/}
                                        <span className="badge bg-primary">
                                            Sale
                                        </span>
                                    </Link>
                                </div>
                                <div className="card-content ms-4 py-2">
                                    {/* Product Title*/}
                                    <Link
                                        className="product-title d-block text-truncate mt-0"
                                        to={`/product/view/${val.ProductId}`}
                                    >
                                        {val.ProductName}
                                    </Link>
                                    {/* Product Price*/}
                                    <p className="sale-price">₹ {val.Price}</p>
                                    {parseInt(val.OutOfStoke) === 1 ? (
                                        <button className="btn btn-danger btn-sm disabled">
                                            Out of Stock
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => {
                                                addProductsToCart({
                                                    ItemId: uuid.v4(),
                                                    ProductId: val.ProductId,
                                                    ProductImage: `${imagePath}${val.ProductImage}`,
                                                    ProductName:
                                                        val.ProductName,
                                                    ProductPrice: val.Price,
                                                    PackSize:
                                                        parseInt(
                                                            val.PackType
                                                        ) === 1
                                                            ? "1 Kg."
                                                            : null,
                                                    Qty: 1,
                                                });
                                            }}
                                        >
                                            Add to Cart
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    return (
        <>
            <div className="top-products-area product-list-wrap">
                <div className="container">
                    <strong className="text text-primary ">
                        Top Rated Products
                    </strong>
                    <div className="row g-1 mt-1">
                        {productList !== null ? (
                            productList
                        ) : (
                            <>
                                <div className="text-center">
                                    <img
                                        style={{
                                            position: "relative",
                                            top: "-30px",
                                        }}
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/bg-img/emptyCart.png"
                                        }
                                        alt=""
                                    />
                                    <h6
                                        className="text-danger"
                                        style={{
                                            position: "relative",
                                            top: "-30px",
                                        }}
                                    >
                                        No products found
                                    </h6>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default TopRatedProducts;
