import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { authContext } from "../../context/authContext";
import { modalContext } from "../../context/modalContext";

import recaptcha from "../functions/firebase/recaptcha";
import sendOtp from "../functions/firebase/sendOtp";
import confirmOtp from "../functions/firebase/confirmOtp";

function ConfirmOtp(props) {

    const [otp, setEnteredOtp] = useState("");

    //get phone number that user has entered in login page from authContext
    const { phoneNumber,setPhoneNumber } = useContext(authContext);

    const last2Digit = String(Number(phoneNumber)).slice(-2);

    const interval = useRef(null);
    const counter = useRef(30);

    const { showLoading, hideLoading, showErrorDialog, showSuccessDialog } =
        useContext(modalContext);
    const history = useHistory();

    useEffect(() => {

        //scroll to top when component loads 
        window.scrollTo(0, 0);

        //redirect user if confirmationResult object is not defibed
        //we defined this object in login page when otp was sent to users
        //users also will redirected if page reloads
        if (!window.confirmationResult) history.push("/login");

        //call firebase function to create ReCaptcha to Resend OTP
        recaptcha("recaptcha-container");

        //start interval to  wait for 1 minute to resend otp
        interval.current = setInterval(() => {
            if (counter.current === 1) {
                counter.current = 30;
                clearInterval(interval.current);
                window
                    .$("#resendOtpElement")
                    .html('<span style="cursor:pointer">Resend OTP</span>');
            } else {
                counter.current -= 1;
                window
                    .$("#resendOtpElement")
                    .html(
                        '<span class="text-danger">Wait ' +
                        counter.current +
                        " secs</span>"
                    );
            }
        }, 1000);

        //clear interval when component unmount
        return () => clearInterval(interval.current);

        //eslint-disable-next-line
    }, []);

    //handle change for otp input
    const handleOtpInputChange = (enteredOtp) => {

        //check is entered otp value is numbers or not
        const isValid = /^[0-9]+$/.test(enteredOtp)

        if (!isValid && enteredOtp?.length) return;

        //set otp value in state
        setEnteredOtp(enteredOtp);
    };

    //function to resend otp
    const resendOtp = async () => {

        //if user is offline then show warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return;
        }

        //return if counter is not finished
        if (counter.current !== 30)
            return;

        showLoading();

        //call firebase function to send otp
        const isOtpSent = await sendOtp(phoneNumber);
        hideLoading();

        if (isOtpSent === true) {
            //clear OTP inpits
            setEnteredOtp("");

            showSuccessDialog(
                "OTP Resended",
                "New OTP has been sent to phone number ending with " + last2Digit
            );

            //start interval again after resending an otp
            interval.current = setInterval(() => {
                if (counter.current === 1) {
                    counter.current = 30;
                    clearInterval(interval.current);
                    window
                        .$("#resendOtpElement")
                        .html('<span style="cursor:pointer">Resend OTP</span>');
                } else {
                    counter.current -= 1;
                    window
                        .$("#resendOtpElement")
                        .html(
                            '<span class="text-danger">Wait ' +
                            counter.current +
                            " secs</span>"
                        );
                }
            }, 1000);
        } else
            showErrorDialog(
                "OTP does not sent",
                "Something went wrong, Please try again"
            );
    };

    //function to confirm an otp
    const handleConfirmOtp = async () => {
        //if user is offline then show warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return;
        }

        //check is otp contains only numbers or not
        const isValidOtpFormat = /^[0-9]+$/.test(otp);

        //if otp format is valid then confirm the otp
        if (isValidOtpFormat && otp.length === 6) {
            showLoading();

            //call firebase function to confirm an otp
            const isOtpConfirmed = await confirmOtp(otp);

            hideLoading();

            if (isOtpConfirmed === true){
                setPhoneNumber("");
                history.push("/cart");  
            } 
            else
                showErrorDialog(
                    "Invalid OTP",
                    "The OTP You have entered is not valid, Please enter valid OTP and try again"
                );
        } else {
            showErrorDialog(
                "Invalid OTP format",
                "Please enter only numbers and try again"
            );
        }
    };

    return (
        <div className="page-content-wrapper">
            <div id="recaptcha-container"></div>
            <div className="login-wrapper d-flex align-items-center justify-content-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-9 col-md-7 col-lg-6 col-xl-5">
                            <div className="text-center px-4">
                                <img
                                    className="mx-auto mb-4 d-block"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/bg-img/otp-illustration.png"
                                    }
                                    alt=""
                                />
                                <h3>Verify Phone Number</h3>
                                <p className="mb-4">
                                    Enter the OTP code sent to
                                    <strong className="ms-1">
                                        ********{last2Digit}
                                    </strong>
                                </p>
                            </div>

                            <div className="otp-verify-form mt-5 px-4">
                                <div className="input-group mb-3">
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={otp}
                                        placeholder="1 2 3 4 5 6"
                                        id="otpInput"
                                        maxLength={6}
                                        onChange={(e) =>
                                            handleOtpInputChange(e.target.value)
                                        }
                                    />
                                </div>
                                <button
                                    className="btn custom-btn-style w-100"
                                    type="button"
                                    disabled={
                                        otp?.length !== 6
                                            ? true
                                            : false
                                    }
                                    onClick={handleConfirmOtp}
                                >
                                    Verify &amp; Proceed
                                </button>
                            </div>
                            <div className="login-meta-data px-4 text-center">
                                <p className="mt-3 mb-0">
                                    Don't received the OTP?
                                    <span
                                        className="otp-sec ms-1"
                                        onClick={resendOtp}
                                        id="resendOtpElement"
                                    ></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmOtp;
