import {
    BEGIN_ACTION_IN_CATEGORIES_REDUCER,
    SUCCESS_GET_CATEGORIES,
    EXCEPTION_GENERATED_IN_CATEGORIES_REDUCER,
    CLEAR_EXCEPTION_AND_LOADING_STATE
} from "../constants";

const initialState = {
    loading: false,
    categoryData: [],
    exception: false,
};

const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case BEGIN_ACTION_IN_CATEGORIES_REDUCER:
            return {
                ...state,
                loading: true,
                exception: false,
            };
        case SUCCESS_GET_CATEGORIES:
            return {
                ...state,
                loading: false,
                exception: false,
                categoryData: action.payload,
            };

        case EXCEPTION_GENERATED_IN_CATEGORIES_REDUCER:
            return {
                ...state,
                loading: false,
                exception: action.payload,
            };

        case CLEAR_EXCEPTION_AND_LOADING_STATE:
            return {
                ...state,
                loading: false,
                exception: false,
            };

        default:
            return state;
    }
};

export default categoriesReducer;
