import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export default function RefundDetailsSkeleton() {
    return (
        <SkeletonTheme highlightColor="#bababa">
            <div className='m-3'>
                
                    <Skeleton count={6} width={'100%'} height={200} className="mb-3" /> 
            </div>

        </SkeletonTheme>
    )
}