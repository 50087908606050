import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function HomeSkeleton(props) {
    const CarouselSkeleton = () => <Skeleton style={{ height: "40vh" }} />;

    const CategorySkeleton = () => {
        const categoryList = [1, 2, 3, 4];

        const skeleton = categoryList.map((obj) => {
            return (
                <div className="col-3" key={`featuredCategory-skeleton-${obj}`}>
                    <Skeleton circle={true} height={50} width={50} />
                </div>
            );
        });

        return (
            <>
                <div className="py-4">
                    <div className="container direction-rtl">
                        <span className="text text-primary">
                            <b>
                                <Skeleton width={100} />
                            </b>
                        </span>
                        <div className="row g-3">{skeleton}</div>
                    </div>
                </div>
            </>
        );
    };

    const ProductSkeleton = () => {
        const productList = [1, 2, 3, 4, 5, 6];

        const skeleton = productList.map((obj) => {
            return (
                <div
                    className="col-12"
                    key={`topRatedProducts-skeleton-${obj}`}
                >
                    <div className="card single-product-card">
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div className="card-side-img">
                                    <Skeleton height={100} width={100} />
                                </div>
                                <div className="card-content px-4 py-2">
                                    <Skeleton width={100} />
                                    <br />
                                    <Skeleton width={100} />
                                    <br />
                                    <Skeleton
                                        style={{ marginTop: "20px" }}
                                        height={30}
                                        width={150}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <>
                <div className="top-products-area product-list-wrap">
                    <div className="container">
                        <strong className="text text-primary ">
                            <Skeleton width={100} />
                        </strong>
                        <div className="row g-3 py-1">{skeleton}</div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <SkeletonTheme highlightColor="#bababa">
                <CarouselSkeleton />
                <CategorySkeleton />
                <ProductSkeleton />
            </SkeletonTheme>
        </>
    );
}

export default HomeSkeleton;
