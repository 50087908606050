import {
    BEGIN_ACTION_IN_ORDER_PROCESS_REDUCER,
    SET_ORDER_DATA,
    SET_SELECTED_ADDRESS,
    SUCCESS_CALCULATE_ADDRESS_ROUTE,
    ORDER_PROCESSED,
    ORDER_PLACED,
    ORDER_CENCELED,
    BEGIN_PAYMENT_PROCESS,
    ORDER_PAYMENT_FAILED,
    PAYMENT_CANCELED,
    EXCEPTION_GENERATED_IN_ORDER_PROCESS_REDUCER,
    CLEAR_DATA_IN_ORDER_PROCESS_REDUCER,
    CLEAR_PROCESSED_ORDER,
    BEGIN_PAYMENT_PROCESS_END
} from "../constants";
import axios from 'axios'
import { encrypt, hasJsonStructure } from "../../components/functions/cipherFunctions";

export function setSelectedAddress(address) {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_ADDRESS,
            payload: encrypt(address, true)
        })
    }
}

export function calculateRouteDistance(serverPath, params, distanceCalculationServiceType, currentSelectedAddress = null, returnResultData = false) {
    return (dispatch) => {

        //using promise in this action to return data when returnResultData argument is true
        return new Promise((resolve, reject) => {
            // Begin fetch data from API
            dispatch({
                type: BEGIN_ACTION_IN_ORDER_PROCESS_REDUCER,
            });

            let apiUrl = '';

            if (distanceCalculationServiceType === "googleMapApi")
                apiUrl = serverPath + '/gmap-calculate-distance';
            else if (distanceCalculationServiceType === "hereMapApi")
                apiUrl = serverPath + '/hmap-calculate-distance';

            axios
                .get(apiUrl, { params: params })
                .then((res) => {
                    if (res.data.Success === true) {
                        if (returnResultData) {
                            resolve(res.data.Data); // Resolve with data
                        }

                        // Set new calculated route length and time duration to current selected address
                        currentSelectedAddress.RouteLength = res.data.Data.RouteLength;
                        currentSelectedAddress.TimeDuration = res.data.Data.TimeDuration;

                        dispatch({
                            type: SUCCESS_CALCULATE_ADDRESS_ROUTE,
                            payload: encrypt(currentSelectedAddress, true)
                        });

                    } else {
                        reject("Calculation failed");
                    }
                })
                .catch((error) => {
                    let message = "Internal server error";
                    let description = "Something went wrong, Please try again after some time";
                    if (error.request) {
                        const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : '';
                        message = errorText.Message ? errorText.Message : error.message;
                    } else message = error;

                    dispatch({
                        type: EXCEPTION_GENERATED_IN_ORDER_PROCESS_REDUCER,
                        payload: {
                            message: message,
                            description: description,
                        },
                    });

                    reject(error); // Reject the promise with error
                });
        });
    }
}


export function setOrderData(orderData) {
    return (dispatch) => {
        dispatch({
            type: SET_ORDER_DATA,
            payload: encrypt(orderData, true)
        })
    }
}

export function checkoutOrder(serverPath, orderData) {
    return (dispatch) => {

        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_PROCESS_REDUCER
        });

        axios
            .post(serverPath + "/checkout", orderData)
            .then((res) => {
                dispatch({
                    type: ORDER_PROCESSED,
                    payload: res.data.OrderData
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;

                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_PROCESS_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function revisePayment(serverPath, params) {
    return (dispatch) => {

        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_PROCESS_REDUCER
        });

        axios
            .post(serverPath + "/revise-payment", params)
            .then((res) => {
                dispatch({
                    type: ORDER_PROCESSED,
                    payload: res.data
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description =
                    "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;

                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_PROCESS_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function validatePayment(serverPath, processedOrder) {
    return (dispatch) => {

        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_PROCESS_REDUCER
        });

        axios
            .post(serverPath + "/validate-payment-signature", processedOrder)
            .then((res) => {

                dispatch({
                    type: ORDER_PLACED,
                    payload: res.data
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description = "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_PROCESS_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function paymentProcess() {
    return dispatch => {
        //begin payment process
        dispatch({
            type: BEGIN_PAYMENT_PROCESS
        });
    }
}

export function paymentProcessEnd(isPaymentFailed) {
    return dispatch => {
        //begin payment process
        dispatch({
            type: BEGIN_PAYMENT_PROCESS_END,
            payload: { isPaymentFailed: isPaymentFailed }
        });
    }
}
export function paymentCanceled() {
    return dispatch => {
        //begin payment process
        dispatch({
            type: PAYMENT_CANCELED
        });
    }
}

export function updateFailedPaymentStatus(serverPath, paymentStatus) {
    return (dispatch) => {
        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_PROCESS_REDUCER
        });

        axios
            .post(serverPath + "/payment-failed", paymentStatus)
            .then((res) => {
                dispatch({
                    type: ORDER_PAYMENT_FAILED,
                    payload: res.data.Success
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description = "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_PROCESS_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function cancelOrder(serverPath, params) {
    return (dispatch) => {
        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_PROCESS_REDUCER
        });

        axios
            .post(serverPath + "/cancel-order", params)
            .then((res) => {
                dispatch({
                    type: ORDER_CENCELED,
                    payload: res.data
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description = "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_PROCESS_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function clearOrderProcessReducersData(clearSelectedAddress = false) {
    return (dispatch) => {
        dispatch({
            type: CLEAR_DATA_IN_ORDER_PROCESS_REDUCER,
            payload: { clearSelectedAddress: clearSelectedAddress }
        })
    }
}

export function clearProcessedOrder() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_PROCESSED_ORDER
        })
    }
}