import React, { useState, useContext } from "react";
import { cartItemContext } from "../../context/cartItemContext";
import { serverContext } from "../../context/serverContext";

function ProductData({ productDetails }) {

    const uuid = require("uuid");
    const { serverImagePath } = useContext(serverContext);
    const { addProductsToCart } = useContext(cartItemContext);

    const imagePath = serverImagePath + "/products/";

    //set default selected pack is 1Kg.
    const [selectedPack, setSelectedPack] = useState({
        Type: parseInt(productDetails.PackType) === 1 ? "1 Kg." : null,
        Price: parseInt(productDetails.ProductPrice),
    });

    const [dynamicPrice, setDynamicPrice] = useState(productDetails.ProductPrice);

    const handlePackSizeChange = (pack) => {
        setSelectedPack(JSON.parse(pack));
        setDynamicPrice(JSON.parse(pack).Price);
    };

    return (
        productDetails && (
            <>
                <div className="card product-details-card py-1">
                    <div className="card-body">
                        <div className="product-gallery owl-carousel">
                            <div className="single-product-image text-center">
                                <img
                                    className="rounded"
                                    src={`${imagePath}${productDetails.ProductImage}`}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card product-details-card direction-rtl">
                    <div className="card-body">
                        <h3>{productDetails.ProductName}</h3>
                        <h1>₹ {dynamicPrice}</h1>
                        {
                            parseInt(productDetails.PackType) === 1 &&
                            <select
                                className="form-select mb-3"
                                aria-label="Select pack size"
                                defaultValue={JSON.stringify({
                                    Type: "1 Kg.",
                                    Price: parseInt(
                                        productDetails.ProductPrice
                                    ),
                                })}
                                onChange={(e) =>
                                    handlePackSizeChange(e.target.value)
                                }
                            >
                                <option
                                    value={JSON.stringify({
                                        Type: "2 Kg.",
                                        Price: Math.ceil(
                                            2 * productDetails.ProductPrice
                                        ),
                                    })}
                                >
                                    2 Kg
                                </option>
                                <option
                                    value={JSON.stringify({
                                        Type: "1 Kg.",
                                        Price: parseInt(
                                            productDetails.ProductPrice
                                        ),
                                    })}
                                >
                                    1 Kg
                                </option>
                                <option
                                    value={JSON.stringify({
                                        Type: "750 gm.",
                                        Price: Math.ceil(
                                            0.75 * productDetails.ProductPrice
                                        ),
                                    })}
                                >
                                    750 grams
                                </option>
                                <option
                                    value={JSON.stringify({
                                        Type: "500 gm.",
                                        Price: Math.ceil(
                                            0.5 * productDetails.ProductPrice
                                        ),
                                    })}
                                >
                                    500 grams
                                </option>

                                <option
                                    value={JSON.stringify({
                                        Type: "250 gm.",
                                        Price: Math.ceil(
                                            0.25 * productDetails.ProductPrice
                                        ),
                                    })}
                                >
                                    250 grams
                                </option>
                            </select>
                        }
                        <div className="input-group">
                            {parseInt(productDetails.OutOfStoke) === 1 ? (
                                <button
                                    className="btn custom-btn-style disabled w-100"
                                    type="button"
                                >
                                    Out of Stoke{" "}
                                </button>
                            ) : (
                                <button
                                    className="btn custom-btn-style w-100"
                                    type="button"
                                    onClick={() =>
                                        addProductsToCart({
                                            ItemId: uuid.v4(),
                                            ProductId: productDetails.ProductId,
                                            ProductImage: `${imagePath}${productDetails.ProductImage}`,
                                            ProductName:
                                                productDetails.ProductName,
                                            ProductPrice: selectedPack.Price,
                                            PackSize: selectedPack.Type,
                                            Qty: 1,
                                        })
                                    }
                                >
                                    Add to Cart
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    );
}

export default ProductData;
