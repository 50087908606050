import {
    BEGIN_ACTION_IN_ORDER_DATA_REDUCER,
    SUCCESS_GET_PAYMENT_METHODS,
    SUCCESS_GET_DELIVERY_METHODS,
    SUCCESS_GET_SHIPPING_CONFIGS,
    SUCCESS_GET_ORDER_HISTORY,
    SUCCESS_GET_ORDER_HISTORY_BY_ORDER_ID,
    SUCCESS_GET_ORDER_DETAILS,
    SUCCESS_REFETCH_ORDER_DETAILS,
    ADD_PLACED_ORDERS_COUNT,
    EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER,
    CLEAR_IS_ORDER_DETAILS_FOUND,
    CLEAR_DATA_IN_ORDER_DATA_REDUCER
} from "../constants";
import axios from 'axios'
import { encrypt, hasJsonStructure } from "../../components/functions/cipherFunctions";

export function getDeliveryMethods(serverPath){
    return (dispatch) => {
        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_DATA_REDUCER
        });

        axios
            .get(serverPath + "/get-delivery-methods")
            .then((res) => {
                dispatch({
                    type: SUCCESS_GET_DELIVERY_METHODS,
                    payload: encrypt(res.data,true),
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER,
                    payload: {
                        message: message,
                        description:
                            "Something went wrong, Please try again after some time",
                    },
                });
            });
    };
}

export function getShipingConfigs(serverPath){
    return (dispatch) => {
        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_DATA_REDUCER
        });

        axios
            .get(serverPath + "/get-shipping-configs")
            .then((res) => {
                dispatch({
                    type: SUCCESS_GET_SHIPPING_CONFIGS,
                    payload: encrypt(res.data,true),
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER,
                    payload: {
                        message: message,
                        description:
                            "Something went wrong, Please try again after some time",
                    },
                });
            });
    };
}


export function getPaymentMethods(serverPath){
    return (dispatch) => {
        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_DATA_REDUCER
        });

        axios
            .get(serverPath + "/get-payment-methods")
            .then((res) => {
                dispatch({
                    type: SUCCESS_GET_PAYMENT_METHODS,
                    payload: encrypt(res.data,true),
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER,
                    payload: {
                        message: message,
                        description:
                            "Something went wrong, Please try again after some time",
                    },
                });
            });
    };
}

export function getOrderHistory(serverPath, userId) {
    return (dispatch) => {

        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_DATA_REDUCER
        });

        //set initial page number as 1 to fetch order history data
        const pageNo = 1;

        axios
            .get(serverPath + "/order-history", { params: { userId: userId, pageNo: pageNo } })
            .then((res) => {

                let orders = null;
                let countTotalOrders = 0;

                if (res.data?.NoOrders !== true) {
                    countTotalOrders = res.data.CountTotalOrders;
                    orders = encrypt(res.data.Orders, true);
                }

                dispatch({
                    type: SUCCESS_GET_ORDER_HISTORY,
                    payload: {
                        orders: orders,
                        countTotalOrders: countTotalOrders,
                        fetchedPageNo: pageNo
                    }
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description = "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function loadMoreOrderHistory(serverPath, userId, previousFetchedOrders, fetchedOrderHistoryPageNo = 1, countPlacedOrders = 0) {
    return (dispatch) => {

        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_DATA_REDUCER
        });

        axios
            .get(serverPath + "/order-history", { params: { userId: userId, pageNo: fetchedOrderHistoryPageNo, numberOfOrdersPlaced: countPlacedOrders } })
            .then((res) => {

                let orders = null;
                let countTotalOrders = 0;

                if (res.data?.NoOrders !== true) {
                    countTotalOrders = res.data.CountTotalOrders;

                    //add nenw fetched orders in to the previousFetchedOrders
                    orders = [...previousFetchedOrders, ...res.data.Orders];

                    //encrypt data
                    orders = encrypt(orders, true);
                }

                dispatch({
                    type: SUCCESS_GET_ORDER_HISTORY,
                    payload: {
                        orders: orders,
                        countTotalOrders: countTotalOrders,
                        fetchedPageNo: fetchedOrderHistoryPageNo
                    }
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description = "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });

    }
}

export function getOrderHistoryByOrderId(serverPath, userId, orderId, orderHistoryData, reFetch = false) {
    return (dispatch) => {

        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_DATA_REDUCER
        });

        axios
            .get(serverPath + "/order-history-by-id", { params: { userId: userId, orderId: orderId } })
            .then((res) => {
                const prepend = (value, array) => {
                    var newArray = array.slice();
                    newArray.unshift(value);
                    return newArray;
                }

                let orders = null;

                if (res.data?.NoOrders !== true) {

                    //if reFetch argument passed as true then replace else add current order data in orderHistoryData
                    if (reFetch) {
                        orders = orderHistoryData?.map((item) => {
                            return parseInt(item?.OrderId) === parseInt(orderId) ? res.data : item;
                        });
                    }
                    else
                        //Add fetched order history data in orderHistoryData
                        orders = orderHistoryData?.length > 0 ? prepend(res.data, orderHistoryData) : [res.data];

                    //encrypt data
                    orders = encrypt(orders, true);
                }

                dispatch({
                    type: SUCCESS_GET_ORDER_HISTORY_BY_ORDER_ID,
                    payload: orders
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description = "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function getOrderDetails(serverPath, userId, orderId, previousOrderDetailsDataList) {
    return (dispatch) => {

        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_DATA_REDUCER
        });

        axios
            .get(serverPath + "/order-details", { params: { orderId: orderId, userId: userId } })
            .then((res) => {

                let newOrderDetailsDataList = null;
                let isDetailsFound = false;

                //Add current order details in DataList if requested order detail found else return previous DataList
                if (res.data?.NoOrder !== true) {

                    isDetailsFound = true;

                    //create object for current order details
                    const currentOrderDetails = {
                        orderId: res.data.OrderDetails.OrderId,
                        details: res.data
                    }

                    //Add fetched order details data in newOrderDetailsDataList
                    newOrderDetailsDataList = previousOrderDetailsDataList?.length > 0 ? [...previousOrderDetailsDataList, currentOrderDetails] : [currentOrderDetails];

                    //encrypt data
                    newOrderDetailsDataList = encrypt(newOrderDetailsDataList, true);
                } else {
                    newOrderDetailsDataList = previousOrderDetailsDataList?.length > 0 ? encrypt(previousOrderDetailsDataList, true) : [];

                }

                dispatch({
                    type: SUCCESS_GET_ORDER_DETAILS,
                    payload: {
                        orderDetailsList: newOrderDetailsDataList,
                        isOrderDetailsFound: isDetailsFound
                    }
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description = "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function reFetchOrderDetails(serverPath, userId, orderId, previousOrderDetailsDataList) {
    return (dispatch) => {

        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_ORDER_DATA_REDUCER
        });

        axios
            .get(serverPath + "/order-details", { params: { orderId: orderId, userId: userId } })
            .then((res) => {

                let newOrderDetailsDataList = null;

                //Add current order details in DataList if requested order detail found else return previous DataList
                if (res.data?.NoOrder !== true) {

                    //create object for current order details
                    const currentOrderDetails = {
                        orderId: res.data.OrderDetails.OrderId,
                        details: res.data
                    }

                    //clear current order data from the previousOrderDetailsDataList
                    previousOrderDetailsDataList = previousOrderDetailsDataList?.filter((item) => {
                        return item?.orderId !== orderId
                    })

                    //Add fetched order details data in newOrderDetailsDataList
                    newOrderDetailsDataList = previousOrderDetailsDataList?.length > 0 ? [...previousOrderDetailsDataList, currentOrderDetails] : [currentOrderDetails];

                    //encrypt data
                    newOrderDetailsDataList = encrypt(newOrderDetailsDataList, true);
                } else
                    newOrderDetailsDataList = previousOrderDetailsDataList?.length > 0 ? encrypt(previousOrderDetailsDataList, true) : null

                dispatch({
                    type: SUCCESS_REFETCH_ORDER_DETAILS,
                    payload: newOrderDetailsDataList
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                let description = "Something went wrong, Please try again after some time";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER,
                    payload: {
                        message: message,
                        description: description,
                    },
                });
            });
    }
}

export function addPlacedOrdersCount(numberOfPlacedOrders){
    return dispatch => {
        dispatch({
            type: ADD_PLACED_ORDERS_COUNT,
            payload: numberOfPlacedOrders
        });
    }
}

export function clearIsOrderDetailsFound() {
    return dispatch => {
        dispatch({
            type: CLEAR_IS_ORDER_DETAILS_FOUND,
            payload: null
        })
    }
}

export function clearOrderDataReducersData() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_DATA_IN_ORDER_DATA_REDUCER
        })
    }
}