import React from 'react'

export default function GetPhoneNumber({ phoneNumber, handleChangePhoneNumber, errorLabel}) {
    return (
        <div className='card mt-1'>
            <div className='card-header'>
                Phone number
            </div>
            <div className='card-body'>
                <div className="form-group m-0">
                    <input type="tel" className="form-control" name="phone"
                        value={phoneNumber} maxLength={10}
                        onChange={(e) => handleChangePhoneNumber(e.target.value)}
                        placeholder="Enter Phone Number" />
                    {
                        errorLabel &&
                        <label className='form-lable text-danger' htmlFor="phone">
                            <small>{errorLabel}</small>
                        </label>
                    }
                </div>
            </div>
        </div>
    )
}
