import React from "react";
import { Link } from "react-router-dom";

function RatingStar({ Ratings }) {
    let stars = [];

    //loop to print filled starts as per ratings (EX: If rating = 3 then it will print 3 filled stars and 2 empty stars)
    for (let i = 1; i <= 5; i++) {
        if (i <= Ratings)
            stars.push(
                <Link to="#" key={`ratingOfReviewInStar-${i}`}>
                    <i className="fa fa-star text-warning"></i>
                </Link>
            );
        else
            stars.push(
                <Link to="#" key={`ratingOfReviewInStar-${i}`}>
                    <i className="fa fa-star-o text-warning"></i>
                </Link>
            );
    }

    return stars;
}

export default RatingStar;
