import {
    BEGIN_ACTION_IN_PRODUCT_REDUCER,
    SUCCESS_GET_PRODUCTS,
    SET_FETCHED_PRODUCTS_CATEGORY_IDS,
    SET_LOADED_PAGENO_OF_PRODUCTS,
    UPDATE_LOADED_PAGENO_OF_PRODUCTS,
    EXCEPTION_GENERATED_IN_PRODUCT_REDUCER
} from "../constants";
import axios from "axios";
import { hasJsonStructure } from "../../components/functions/cipherFunctions";

export function getProductPageData({ serverPath, pageNo, categoryId }) {
    return (dispatch) => {
        //begin get products
        dispatch({
            type: BEGIN_ACTION_IN_PRODUCT_REDUCER,
        });

        axios
            .get(serverPath + "/products", {
                params: {
                    pageNo: pageNo,
                    categoryId: categoryId,
                }
            })
            .then((res) => {
                dispatch({
                    type: SUCCESS_GET_PRODUCTS,
                    payload: res.data,
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_PRODUCT_REDUCER,
                    payload: {
                        message: message,
                        description:
                            "Something went wrong, Please try again after some time",
                    }
                });
            });
    };
}

export function setFetchedProductsCategory(categoryId) {
    return (dispatch) => {
        dispatch({
            type: SET_FETCHED_PRODUCTS_CATEGORY_IDS,
            payload: categoryId,
        });
    };
}

export function setLoadedPageNo(categoryId, pageNo) {
    return (dispatch) => {
        dispatch({
            type: SET_LOADED_PAGENO_OF_PRODUCTS,
            payload: {
                categoryId: categoryId,
                loadedPage: pageNo,
            },
        });
    };
}

export function updateLoadedPageNo(categoryId, pageNo) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_LOADED_PAGENO_OF_PRODUCTS,
            payload: {
                categoryId: categoryId,
                loadedPage: pageNo,
            },
        });
    };
}
