import React, { createContext, useState } from "react";

export const topLoadingBarContext = createContext("topLoadingBarContext");

export const TopLoadingBarProvider = (props) => {
    const [loadingProgress, setLoadingProgress] = useState(0);

    return (
        <topLoadingBarContext.Provider
            value={{ loadingProgress, setLoadingProgress }}
        >
            {props.children}
        </topLoadingBarContext.Provider>
    );
};
