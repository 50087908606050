import React from 'react'

export default function OrderProcess({ paymentProcessing, paymentFailed, paymentCanceled, revisePayment }) {

    return (
        <>
            <div className="card">
                <div className="card-header">
                    {
                        paymentProcessing ? <h3 className="text-center">Order processing</h3> :
                            <h3 className="text-center">Awaitng for payment</h3>
                    }
                </div>
                <div className="card-body position-relative overflow-hidden">
                    <div className='container'>
                        {/**
                         * Pending:
                         * Vertical loader for showing order process steps
                         *  
                            <div className="order-progress-bar"></div>
                            <div className={'order-progress-bar2'}></div>
                        */}
                        {
                            paymentProcessing && <><p className="text-center mt-3">Your order is created and we are waiting for the payment to be captured.</p>
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border kcs-text-color" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <p className="text-center mt-4">Please do not refresh or close the page.</p></>
                        }

                        {
                            !paymentProcessing && paymentCanceled && <><h6 className="text-center text-warning">Payment is pending</h6>
                                <div className="d-flex justify-content-center">
                                    <i className='fa fa-exclamation-circle fa-4x text-warning'></i>
                                </div>
                                <p className="text-center mt-3">We are waiting for the payment to process your order. Unpaid order will be automatically canceled within 1 hour.</p>
                                <button className='btn btn-outline-warning mt-1 w-100' onClick={() => revisePayment(true)} >Complete Payment</button></>
                        }

                        {
                            !paymentProcessing && paymentFailed && <><h6 className="text-center text-danger">Payment Failed</h6>
                                <div className="d-flex justify-content-center">
                                    <i className='fa fa-exclamation-triangle fa-4x text-danger'></i>
                                </div>
                                <p className="text-center mt-4">The payment is failed due to a temporary issue. Please revise the payment to process your order.</p>
                                <p className='text-center mb-0'>If amount got deducted, It will be refunded within 5-7 working days.</p>
                                <button className='btn btn-outline-danger mt-4 w-100' onClick={() => revisePayment(true)} >Revise Payment</button></>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
