import React from 'react'

export default function RefundTotals({ refundTotals }) {

    if (!refundTotals?.OrderAmount)
        return <h1>No data</h1>

    return (
        <div className='card mt-1'>
            <div className='card-header'>
                Refund Totals
            </div>
            <ul className="listview flush transparent image-listview text">
                <li>
                    <div className="item">
                        <div className="in">
                            <div>Subtotal</div>
                            {parseInt(refundTotals.IsTestOrder) === 1 ? <strike>₹ {refundTotals.SubTotal}</strike> :
                                <span>₹ {refundTotals.SubTotal}</span>}
                        </div>
                    </div>
                    {
                        refundTotals.Discount > 0 && <div className='m-0'>
                            <span className='ms-3 text-success'>Discount</span>
                            <span className='text-success' style={{ float: 'right', marginRight: '15px' }}>- ₹{refundTotals.Discount}</span>
                        </div>
                    }
                </li>
                <li>
                    <div className="item">
                    <div className="in">
                            <div>Delivery fee</div>
                            {parseInt(refundTotals.IsTestOrder) === 1 ? <strike>₹ {refundTotals.ShippingCharge}</strike> :
                                <span>₹ {refundTotals.ShippingCharge}</span>}
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div>Taxes and Charges<b className="mx-2 self-pickup-tooltip border custom-border ps-2 px-2" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="top" title="&lt;em&gt;Shop packaging and GST charges&lt;/em&gt;">?</b></div>
                            {parseInt(refundTotals.IsTestOrder) === 1 ? <strike>₹ {refundTotals.TaxAndCharges}</strike> :
                                <span>₹ {refundTotals.TaxAndCharges}</span>}
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div><b>Grand Total</b></div>
                            <b>{parseInt(refundTotals.IsTestOrder) === 1 ? <strike>₹ {refundTotals.Total}</strike> :
                                <span>₹ {refundTotals.Total}</span>}</b>
                            
                        </div>
                    </div>
                </li>
                {parseInt(refundTotals.IsTestOrder) === 1  &&
                    <li>
                        <div className="item">
                            <div className="in">
                                <div><b>Test Order Amount</b></div>
                                <span><b>₹ {refundTotals.OrderAmount}</b></span>
                            </div>
                        </div>
                    </li>}
                <li>
                    <div className="item">
                        <div className="in">
                            <div><b>Refund Handling Charge</b></div>
                            <span><b>₹ {refundTotals.HandlingFee ? refundTotals.HandlingFee : 0}</b></span>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="item">
                        <div className="in">
                            <div><b>Total Refunded</b></div>
                            <span><b>₹ {refundTotals.RefundedAmount ? refundTotals.RefundedAmount : 0}</b></span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

