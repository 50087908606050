

import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authContext } from '../../context/authContext';
import { modalContext } from '../../context/modalContext';
import { serverContext } from '../../context/serverContext';
import { getMapApiKey, postUserAddress } from '../../redux/actions/addressActions';


import AddressSkeleton from './Skeleton';
import { decrypt, isDecryptedDataValid } from '../functions/cipherFunctions';
import GoogleMap from './GoogleMap';
import HereMap from './HereMap';
import { getShipingConfigs } from '../../redux/actions/orderDataActions';
import { calculateRouteDistance } from '../../redux/actions/orderProcessActions';
import AddressFields from './AddressFields';

export default function GoogleAddress() {

  const dispatch = useDispatch();
  const history = useHistory();

  const { isAuthenticate } = useContext(authContext)
  const { showErrorDialog, showSuccessDialog, showLoading, hideLoading } = useContext(modalContext);
  const { serverPath } = useContext(serverContext);

  //get and decrypt address list from reducer that we passed to postAddress and updateAddress action to add new posted/updated address in the list
  const addressListFromReducer = useSelector(state => state.addressReducer.addressList);
  const addressList = addressListFromReducer && isDecryptedDataValid(decrypt(addressListFromReducer, true));

  //get and decrypt shipping configurations from reducer
  const getShippingConfigsData = useSelector(state => state.orderDataReducer.shippingConfigs);
  const shippingConfigs = getShippingConfigsData && isDecryptedDataValid(decrypt(getShippingConfigsData, true));

  //get and decrypt map api key from reducer
  const getMapApiKeyFromReduser = useSelector(state => state.addressReducer.mapApiKey);
  const mapApiKey = getMapApiKeyFromReduser && isDecryptedDataValid(decrypt(getMapApiKeyFromReduser));

  //set default store lat & lng as botad
  const defaultStoreCordinates = {
    lat: 22.17203,
    lng: 71.66369
  };

  //store location
  const storeCordinates = shippingConfigs?.storeLocation ? JSON.parse(shippingConfigs?.storeLocation) : defaultStoreCordinates;

  //set default address as Haveli Chowk
  const [userAddress, setUserAddress] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('')
  const [addressType, setAddressType] = useState('');
  const [mapType, setmapType] = useState('');

  const [errorLabelForLandmark, setErrorLabelForLandmark] = useState('');
  const [errorLabelForHouseFlatBlock, setErrorLabelForHouseFlatBlock] = useState('');
  const [errorLabelForPhoneNumber, setErrorLabelForPhoneNumber] = useState('');
  const [errorLabelForEmail, setErrorLabelForEmail] = useState('');

  //these below states are used to set active class to highlight selected address type when user select any address type
  const [addressCardHome, setAddressCardHome] = useState('');
  const [addressCardOffice, setAddressCardOffice] = useState('');
  const [addressCardOther, setAddressCardOther] = useState('');

  const isAddressPosted = useRef(false);

  //function to validate phone number
  const isValidPhoneNumber = phoneNumber => {
    const isValid = /^[0-9]+$/.test(phoneNumber)

    if (phoneNumber?.length === 10 && isValid)
      return true
    else
      return false
  }

  //get house/flat/block information from user and set in userAdress
  const handleChangeHouseFlatBlock = houseFlatBlock => {

    if (houseFlatBlock.length === 0)
      setErrorLabelForHouseFlatBlock('This field is required');
    else
      setErrorLabelForHouseFlatBlock('');

    setUserAddress({ ...userAddress, houseFlatBlock: houseFlatBlock })
  }

  //get landmark from user and set in userAdress
  const handleChangeLandmark = landmark => {

    if (landmark.length === 0)
      setErrorLabelForLandmark('This field is required');
    else if (landmark.length < 3)
      setErrorLabelForLandmark('It should be at least 3 characters long');
    else
      setErrorLabelForLandmark('');

    setUserAddress({ ...userAddress, landmark: landmark })
  }

  //get and set phone number from user input
  const handleChangePhoneNumber = phoneNumber => {

    //check is entered phonr number is valid or not  
    const isValid = /^[0-9]+$/.test(phoneNumber)

    //return if entered value is not a numbers
    if (phoneNumber.length > 0 && !isValid)
      return;

    if (phoneNumber.length > 10)
      return;

    if (phoneNumber.length === 0)
      setErrorLabelForPhoneNumber('Phone number is Required');
    else if (phoneNumber.length < 10 || !isValid)
      setErrorLabelForPhoneNumber('Enter valid phone number');
    else
      setErrorLabelForPhoneNumber('');

    setPhoneNumber(phoneNumber);
  }

  //get and set email from user input
  const handleChangeEmail = email => {

    const emailMask = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isValidEmail = emailMask.test(email);

    if (email?.length === 0 || isValidEmail)
      setErrorLabelForEmail('')
    else
      setErrorLabelForEmail('Enter valid email address')

    setEmail(email);
  }

  //get and set address type from user
  const handleAddressType = (type) => {

    //set state to highlight selected address type
    switch (type) {
      case 'Home':
        setAddressCardHome('active');
        setAddressCardOffice('');
        setAddressCardOther('');
        break;

      case 'Office':
        setAddressCardHome('');
        setAddressCardOffice('active')
        setAddressCardOther('')
        break;

      case 'Other':
        setAddressCardHome('');
        setAddressCardOffice('')
        setAddressCardOther('active')
        break;

      default:
        break;
    }

    setAddressType(type);
  }


  const setUserAddressAndLatLngFromMap = (data) => {
    setUserAddress({
      ...userAddress,
      fullAddress: data.fullAddress,
      lat: data.lat,
      lng: data.lng
    });
  }

  //method to calculate the route distance from store to customers selected address and save it
  const calculateRouteAndSaveAddress = () => {

    //if user is offline then show error warning
    if (!window.navigator.onLine) {
      showErrorDialog(
        "You are offline",
        "Please check your internet connection and try again"
      );
      return false;
    }

    const { fullAddress, landmark, houseFlatBlock, lat, lng } = userAddress;

    //check if all required data for address are valid
    if (fullAddress && landmark && houseFlatBlock && lat && lng && addressType && isValidPhoneNumber(phoneNumber)) {

      showLoading();

      const origin = {
        lat: storeCordinates.lat,
        lng: storeCordinates.lng
      }

      const destination = {
        lat: userAddress.lat,
        lng: userAddress.lng
      }

      const params = {
        fromCords: origin,
        toCords: destination
      };

      const currentSelectedAddress = false;
      const returnResultData = true;

      //calculate the route distance
      dispatch(calculateRouteDistance(serverPath, params, shippingConfigs?.distanceCalculationApi, currentSelectedAddress, returnResultData))
        .then((result) => {
          //save address
          saveAddress(result);
        })
        .catch((error) => {
          showErrorDialog('Error calculating distance:', error);
        });
    } else {
      showErrorDialog('Invalid address', 'It happens because of some missing address fields ,Please make sure you have filled all the required details then try again')
    }
  }

  //method to save address in server
  const saveAddress = (routeData) => {

    //get time duration and travel length for customers address
    const routeLength = routeData.RouteLength;
    const timeDuration = routeData.TimeDuration;

    const { fullAddress, landmark, houseFlatBlock, lat, lng } = userAddress;

    const addressParams = {
      userId: isAuthenticate?.userId,
      name: isAuthenticate?.username,
      email: email,
      phoneNumber: phoneNumber,
      fullAddress: fullAddress,
      landmark: landmark,
      houseFlatBlock: houseFlatBlock,
      addressType: addressType,
      lat: lat,
      lng: lng,
      timeDuration: timeDuration || '1', //total expected time(seconds) to reach customers location from store : default 1
      routeLength: routeLength || '1'//total tavel length(miters) from store to customers location : default 1
    };

    //dispatch action to post user address
    dispatch(postUserAddress(serverPath, addressParams, addressList));
    isAddressPosted.current = true;
  }

  const exception = useSelector((state) => state.addressReducer.exception);
  const loading = useSelector((state) => state.addressReducer.loading);


  //redirect if address is posted successfully
  if (!loading && !exception && isAddressPosted.current === true) {
    hideLoading();
    showSuccessDialog("Address added successfully", "New address has been added to your address book");
    history.push("/address/list/newAddressAdded");
  }

  if (exception) {
    isAddressPosted.current = false;
    showErrorDialog(exception.message, exception.description);
    hideLoading();

    dispatch({
      type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
    });
  }


  //scroll to top when component loads 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {

    /* 
     * redirect to login page if user is not logged in yet
    */
    //we are fetching logged in users data from authContext and it takes few seconds to authenticate user each time when page reloads
    //here we check "isAuthenticate === false" because the default state of isAuhtenticate is as null
    //if user is not logged in then state of isAuthenticate will be false else state will store users data
    if (isAuthenticate === false && !isAuthenticate.email && !isAuthenticate.phoneNumber)
      history.push('/login');

    //set users default phone and email when user data fetched from authContext if not already set
    if (!email)
      setEmail(isAuthenticate?.email);

    if (!phoneNumber)
      setPhoneNumber(isAuthenticate?.phoneNumber);

    //eslint-disable-next-line
  }, [isAuthenticate])

  //fetch shipping configurations from server if already not fetched
  useEffect(() => {

    if (!shippingConfigs) {
      dispatch(getShipingConfigs(serverPath));
    }
    //eslint-disable-next-line
  }, []);

  //fetch map api key from server if its not already fetched
  useEffect(() => {

    if (!mapApiKey && shippingConfigs?.addressMapApi)
      dispatch(getMapApiKey(serverPath, shippingConfigs?.addressMapApi));

    //eslint-disable-next-line
  }, [shippingConfigs?.addressMapApi])

  //load map based on selected config
  useEffect(() => {

    if (!mapType && mapApiKey)
      loadMapBasedOnConfig();

    //eslint-disable-next-line
  }, [mapApiKey])


  //method to load map
  const loadMapBasedOnConfig = () => {
    if (shippingConfigs && !mapType && mapApiKey) {
      const map = shippingConfigs?.addressMapApi === "googleMap" ?
        <GoogleMap setUserAddressAndLatLngFromMap={setUserAddressAndLatLngFromMap} apikey={mapApiKey} /> :
        <HereMap setUserAddressAndLatLngFromMap={setUserAddressAndLatLngFromMap} apikey={mapApiKey} />;

      setmapType(map)
    }

    return false;
  }

  return (
    <>
      <div className="page-content-wrapper">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-6">
            <div className="card">
              <div className="">

                {/*set display mode as none if user is not authenticate*/}
                <div className={!isAuthenticate ? 'd-none' : ''}>
                  {mapType}
                </div>
                {
                  /* show address only when user is logged in and set phone number in state 
                  to avoid uncontrolled input tag warning in console because we are assigning 
                  phone and email state in useEffect that make these value uncontrolled.
                  */
                  isAuthenticate && phoneNumber !== undefined ? (
                    <>
                      <AddressFields userAddress={userAddress}
                        handleChangeHouseFlatBlock={handleChangeHouseFlatBlock} errorLabelForHouseFlatBlock={errorLabelForHouseFlatBlock}
                        handleChangeLandmark={handleChangeLandmark} errorLabelForLandmark={errorLabelForLandmark}
                        handleChangePhoneNumber={handleChangePhoneNumber} phoneNumber={phoneNumber} errorLabelForPhoneNumber={errorLabelForPhoneNumber}
                        handleChangeEmail={handleChangeEmail} email={email} errorLabelForEmail={errorLabelForEmail}
                        handleAddressType={handleAddressType} addressCardHome={addressCardHome} addressCardOffice={addressCardOffice} addressCardOther={addressCardOther}
                      />
                      <div className="container">
                        <button className="btn custom-btn-style w-100 mt-4 mb-4" onClick={calculateRouteAndSaveAddress}
                          disabled={
                            userAddress.fullAddress &&
                              userAddress.landmark &&
                              userAddress.houseFlatBlock &&
                              addressType &&
                              !errorLabelForLandmark &&
                              !errorLabelForHouseFlatBlock &&
                              !errorLabelForEmail &&
                              isValidPhoneNumber(phoneNumber)
                              ? false : true}
                        >Save Address</button>
                      </div>
                    </>
                  ) : (
                    <AddressSkeleton />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}


