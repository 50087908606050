import React, { useEffect } from "react";

export default function Settings() {

	//scroll to top when component loads 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
	
	useEffect(() => {
		var toggleSwitch = document.getElementById('darkSwitch');
		var currentTheme = localStorage.getItem('theme');

		if (currentTheme) {
			document.documentElement.setAttribute('data-theme', currentTheme);
			if (currentTheme === 'dark') {
				if (toggleSwitch) {
					toggleSwitch.checked = true;
				}
			}
		}

		function switchTheme(e) {
			if (e.target.checked) {
				document.documentElement.setAttribute('data-theme', 'dark');
				localStorage.setItem('theme', 'dark');
			} else {
				document.documentElement.setAttribute('data-theme', 'light');
				localStorage.setItem('theme', 'light');
			}
		}

		if (toggleSwitch) {
			toggleSwitch.addEventListener('change', switchTheme, false);
		}

		window.$('#darkSwitch').on('click', function () {
			window.$('.dark-mode-switching').fadeIn(50).delay(1000).fadeOut(250);
		});
	}, [])






	return (
		<div className="page-content-wrapper py-3">
			<div className="container">

				<div className="card mb-3 shadow-sm">
					<div className="card-body directionc-rtl">
						<p>Settings</p>
						<div className="single-setting-panel">
							<div className="form-check form-switch mb-2">
								<input className="form-check-input" type="checkbox" id="darkSwitch" />
								<label className="form-check-label" htmlFor="darkSwitch">Dark mode</label>
							</div>
						</div>
						<div className="single-setting-panel">
							<div className="form-check form-switch">
								<input className="form-check-input" type="checkbox" id="rtlSwitch" />
								<label className="form-check-label" htmlFor="rtlSwitch">RTL mode</label>
							</div>
						</div>
					</div>
				</div>

				<div className="card mb-3 shadow-sm">
					<div className="card-body direction-rtl">
						<p>Account</p>
						<div className="single-setting-panel"><a href="page-user-profile.html">
							<div className="icon-wrapper"><i className="bi bi-person"></i></div>Update Profile</a></div>
						<div className="single-setting-panel"><a href="page-user-profile.html">
							<div className="icon-wrapper bg-warning"><i className="bi bi-pencil"></i></div>Update Bio</a></div>
						<div className="single-setting-panel"><a href="page-change-password.html">
							<div className="icon-wrapper bg-info"><i className="bi bi-lock"></i></div>Change Password</a></div>
						<div className="single-setting-panel"><a href="page-language.html">
							<div className="icon-wrapper bg-success"><i className="bi bi-globe2"></i></div>Language</a></div>
						<div className="single-setting-panel"><a href="page-privacy-policy.html">
							<div className="icon-wrapper bg-danger"><i className="bi bi-shield-lock"></i></div>Privacy Policy</a></div>
					</div>
				</div>

				<div className="card shadow-sm">
					<div className="card-body direction-rtl">
						<p>Register &amp; Logout</p>
						<div className="single-setting-panel"><a href="page-register.html">
							<div className="icon-wrapper bg-primary"><i className="bi bi-person"></i></div>Create new account</a></div>
						<div className="single-setting-panel"><a href="page-login.html">
							<div className="icon-wrapper bg-danger"><i className="bi bi-box-arrow-right"></i></div>Logout</a></div>
					</div>
				</div>
			</div>
		</div>
	)
}
