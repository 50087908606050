import React from 'react'

export default function OrderItems({ items, serverImagePath }) {

    let orderItems = '';
    const imagePath = serverImagePath + '/products/';

    if (items?.length > 0) {
        orderItems = items?.map((item) => {
            let productPrice = '';
            //true if product size in grams(EX: 750 = 750Gm , 500 = 500gm , 250 = 250gm)
            if (item.sizeOfProductInNumber > 2)
                productPrice = Math.ceil(item.sizeOfProductInNumber * item.Price / 1000);
            //If product size in KG(EX: 1Kg and 2Kg)
            else if (item.sizeOfProductInNumber)
                productPrice = Math.ceil(item.Price * item.sizeOfProductInNumber);
            else
                productPrice = item.Price;

            //product total
            const productTotal = Math.ceil(productPrice * item.Quantity);

            return (
                <tr key={"order-item-" + item.ProductId + item.PackSize}>
                    <th scope="row">
                        <img src={`${imagePath}${item.ProductImage}`} alt="" />
                    </th>
                    <td>
                        <h6 className="mb-1">{item.ProductName}</h6>
                        {item.PackSize ? (
                            <>
                                <span>{item.PackSize}</span>
                                <br />
                            </>
                        ) : null}

                    </td>

                    <td><h6 className="mb-1">₹ {productPrice}</h6></td>

                    <td>
                        <div className="quantity">
                            <input
                                className="qty-text"
                                type="text"
                                value={item.Quantity}
                                disabled={true}
                            />
                        </div>
                    </td>
                    <th>
                        <h6 className="mb-1">₹ {productTotal}</h6>
                    </th>
                </tr>
            );
        });
    }

    return (
        <div className='card mt-1'>
            <div className='card-header'>
                Ordered Items:<small className='ms-2'>{items?.length}</small>
            </div>

            <div className="cart-table">
                <table className="table mb-0 text-center">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th width={"20%"}>Price</th>
                            <th>Qty</th>
                            <th>Item Total</th>
                        </tr>
                    </thead>
                    <tbody>{orderItems}</tbody>
                </table>
            </div>
        </div>
    )
}
