import React from "react";
import { Link } from "react-router-dom";

const ApplyRemoveCoupon = (props) => {

    return (
        <div className="card mt-1">
            <div className="card-body">
                {
                    props.appliedCoupon ? (
                        <>
                            <h6 className='mb-0'>
                                <img src={process.env.PUBLIC_URL + localStorage.getItem('theme') === 'dark' ? '/icons/discount-light.png' : '/icons/discount.png'} alt='' width={25} />
                                <b className='ms-3 '>{props.appliedCoupon.Code}</b>
                                <button className='remove-coupon-button'
                                    style={{ float: 'right' }}
                                    onClick={props.removeAppliedCoupon}
                                >
                                    <i className='fa fa-close' ></i>
                                </button>
                                <p className='mb-0' style={{ marginLeft: '2.5rem' }}>Offer is applied to the bill</p>
                            </h6>
                        </>
                    ) : (
                        <>
                            <Link to={'#'} onClick={props.showCouponModal} >
                                <h6 className="mb-0">
                                    <img src={process.env.PUBLIC_URL + localStorage.getItem('theme') === 'dark' ? '/icons/discount-light.png' : '/icons/discount.png'} alt='' width={25} />
                                    <b className='ms-3'>APPLY COUPON</b>
                                    <i className='fa fa-angle-right' style={{ float: 'right' }}></i>
                                </h6>
                            </Link>
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default ApplyRemoveCoupon;