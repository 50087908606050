import React, { useState } from 'react'
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import {
    setKey,
    setLanguage,
    setRegion,
    fromLatLng
} from "react-geocode";




function Gaddress({ setUserAddressAndLatLngFromMap, apikey, mapCordinates }) {

    //set users lat & lng if found else set default as botad
    const [currentPosition, setCurrentPosition] = useState(mapCordinates || {
        lat: 22.17203,
        lng: 71.66369
    });

    // Set Google Maps Geocoding API key for quota management (optional but recommended).
    // Use this if you want to set the API key independently.
    setKey(apikey); // Your API key here.

    // Set default response language (optional).
    // This sets the default language for geocoding responses.
    setLanguage("en"); // Default language for responses.

    // Set default response region (optional).
    // This sets the default region for geocoding responses.
    setRegion("in"); // Default region for responses.

    const containerStyle = {
        width: '100%',
        height: '50vh'
    };

    // As of now not using this feature

    //set users current location
    // const successGetUserLocation = position => {
    //     const currentPosition = {
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude
    //     }
    //     setCurrentPosition(currentPosition);
    // };

    //get users current location
    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition(successGetUserLocation);
    // })


    const getAddressFromLatLong = (lat, lng) => {

        // Get address from latitude & longitude.
        fromLatLng(lat, lng)
            .then(({ results }) => {
                const address = results[0].formatted_address;
                const latitude = results[0].geometry.location.lat;
                const longitude = results[0].geometry.location.lng;

                const data = {
                    fullAddress: address,
                    lat: latitude,
                    lng: longitude
                }
                setUserAddressAndLatLngFromMap(data);

                return address;
            })
            .catch(console.error);
    }


    const onMarkerDragEnd = (e) => {

        const lat = e.latLng.lat();
        const lng = e.latLng.lng();

        getAddressFromLatLong(lat, lng);
        setCurrentPosition({ lat, lng })
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apikey
    })


    const onLoad = React.useCallback(function callback(map) {
        //set map zoom on load
        map.setZoom(mapCordinates?.lat ? 17 : 14);
    }, [mapCordinates])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={currentPosition}
            zoom={mapCordinates?.lat ? 17 : 14}
            onLoad={onLoad}
            onClick={(e) => onMarkerDragEnd(e)}
            options={{
                mapTypeControl: false,
                // mapTypeId:'satellite',
                // mapTypeId: 'terrain',
                // mapTypeId:'hybrid',
                fullscreenControl: false,
                streetViewControl: false,
                // zoomControl:false,
                disableDefaultUI: false,
                draggingCursor: true
            }}
        >
            { /* Child components, such as Markers, info windows, etc. */}
            <>
                <MarkerF
                    position={currentPosition}
                    onDragEnd={(e) => onMarkerDragEnd(e)}
                    draggable={true}
                    options={{
                        icon: {
                            url: process.env.PUBLIC_URL + '/icons/map_marker_128.png',
                            scaledSize: new window.google.maps.Size(80, 80) // Set the size of the icon
                        }
                    }}
                />
            </>
        </GoogleMap>

    ) : <></>
}

export default React.memo(Gaddress)
